import React from 'react'
import styled from 'styled-components'

import {textLight} from '../theme'

const Wrapper = styled.div`
  display: block;
  color: ${textLight};
  text-align: right;
  width: 100%;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  justify-content: end;
  letter-spacing: 0.1em;
  color: #cdcab9;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

interface IndicatorProps {
  figure: number
  maximum?: number
  label: any
}
export const Indicator = ({figure, maximum, label}: IndicatorProps) => (
  <Wrapper>
    {label}&nbsp;
    {maximum ? `${figure}/${maximum}` : figure}
  </Wrapper>
)
