import React, {useContext} from 'react'
import styled from 'styled-components'
import {ToolTipContext} from '../contexts/ToolTipContext'

interface ToolTipProps {
  children: React.ReactNode[] | React.ReactNode
}

export const ToolTip = ({children}: ToolTipProps) => {
  const {setOpenToolTipKey} = useContext(ToolTipContext)

  return (
    <ToolTipWrap role="button" onClick={() => setOpenToolTipKey('')}>
      {children}
    </ToolTipWrap>
  )
}

const ToolTipWrap = styled.div`
  position: absolute;
  background-color: #fff;
  top: 60px;
  border: 1px solid #f5f4ee;
  padding: 5px 11px;
  max-width: 250px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 3px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  text-align: center;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: 100%;
    width: 0;
    height: 0;
  }
  &:before {
    margin-left: -10px;
    border: 10px solid transparent;
    border-bottom-color: #f5f4ee;
  }
  &:after {
    margin-left: -9px;
    border: 9px solid transparent;
    border-bottom-color: #fff;
  }
`
