import React from 'react'

export const JSLogo = () => (
  <svg
    version="1.1"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 98.9 101"
    enableBackground="new 0 0 98.9 101"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#E11A27"
      d="M41.9,75.5h6.8c0.3,9.8-5.5,20-14.9,23.7c-8.5,3.7-19.3,1.7-26-4.7C3.1,89.8,0,83.9,0,77.7c0-5.9,0-19.5,0-19.5
	h41.9V75.5z M48.9,1.2l-0.1,24.7H42v0.1c-0.1,6.1,0.1,11.4,0,17.4h-18V1.1L48.9,1.2L48.9,1.2z M96.7,14.9c2.3,5.6,2.1,10.4,2.1,16.6
	l0,11.8H84.5c7.5,3.4,13.1,11.2,14.1,19c0.4,5.3,0.3,9.9,0.2,15.3c-0.6,8.7-6,17.3-14.2,20.9c-5.3,2.4-11.9,3-17.6,1.1
	c-6.4-1.9-12.5-7.4-15-13.7c-1.3-3.2-1.9-10.5-1.9-10.5l6.5,0V58.2h17.6V43.3H56.7V25.8H50c-0.3-0.2-0.2-0.8-0.2-1.2
	c0.1-10.3,6.8-19.8,16.5-23.2c7.6-2.8,17-1.2,23.2,4C92.6,7.8,95.2,11.4,96.7,14.9L96.7,14.9z"
    />
  </svg>
)
