import {Trans} from '@lingui/macro'
import React from 'react'
import styled from 'styled-components'
import {Indicator} from './Indicator'

interface ProgressIndicatorProps {
  value: number
  dayCount?: number
  day?: number
}

const Bar = styled.div`
  margin-top: 16px;
  padding: 8px 12px;
  align-items: center;
  border-radius: 11px;
  background-color: #fff;
  display: flex;

  .first {
    flex-grow: 25;
    display: flex;
  }

  .second {
    display: flex;
    justify-content: end;
    top: 6px;
    justify-content: end;
    flex-grow: 1;
  }
`
const Segment = styled.div<ProgressIndicatorProps>`
  height: 6px;
  min-width: 6px;
  width: ${(props) => Math.round(props.value * 100)}%;
  border-radius: 3px;
  background-color: #dfddce;
  transition: width 1s ease;
`

export const ProgressIndicator = ({value, dayCount, day}: ProgressIndicatorProps) => {
  return (
    <Bar>
      <div className="first">
        <Segment value={value}></Segment>
      </div>
      <div className="second">
        <Indicator
          figure={day ? day : 0}
          maximum={dayCount}
          label={<Trans id="progress.indicator.day">Tag</Trans>}
        />
      </div>
    </Bar>
  )
}
