import React from 'react'
import {useState} from 'react'
import styled from 'styled-components'
import {Item} from './Item'
import {Row} from './Row'

const ItemBoxWrapper = styled.div`
  margin-top: 5px;
`

const ItemBoxParent = styled.div`
  flex-grow: 1;
`
export const ItemBoxHeading = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`

export interface Item {
  name: string
  imagePath?: string
  description?: string
  data: any
}

interface ItemBoxProps {
  items: Item[]
  amountOfSelectableItems: number
  selectedItems?: number[]
  select?: Function
  coloringCondition?: {field: string}
  margins?: number
}

export const ItemBox = (props: ItemBoxProps) => {
  const [items, setItems] = useState(props.selectedItems)

  const childActivityChanged = (item: number) => {
    let tmpItems: number[] = items ? [...items] : []
    if (tmpItems.indexOf(item) !== -1) {
      tmpItems.splice(tmpItems.indexOf(item), 1)
    } else {
      tmpItems.push(item)
    }
    if (tmpItems.length >= props.amountOfSelectableItems + 1 && !!items) {
      tmpItems = tmpItems.slice(tmpItems.length - props.amountOfSelectableItems, tmpItems.length)
    }
    setItems(tmpItems)
    if (props.select) {
      props.select(
        props.items
          ?.filter((i, index) => tmpItems && tmpItems.indexOf(index) !== -1)
          .map((i) => i.data)
      )
    }
  }
  return (
    <ItemBoxParent>
      <ItemBoxWrapper>
        {props.items.map((item: Item, index: number) => {
          return (
            <Row marginTop={props.margins ? props.margins + 'px' : undefined} key={index}>
              <Item
                isActive={!!items && items.indexOf(index) !== -1}
                id={index}
                key={index}
                activeChanged={childActivityChanged}
                imagePath={item.data.imagePath}
                colored={
                  props.coloringCondition
                    ? item.data[props.coloringCondition.field]
                      ? 'red'
                      : 'green'
                    : undefined
                }
                description={item.description}
                name={item.name}
              ></Item>
            </Row>
          )
        })}
      </ItemBoxWrapper>
    </ItemBoxParent>
  )
}
