import {Trans} from '@lingui/macro'
import React, {useState} from 'react'
import {Row} from '.'
import {ManualDialogHandle} from '../presenters/ManualDialogHandle'
import {Button} from './Button'
import {Dialog, DIALOG_BG_COLOR} from './Dialog'
import {Manual} from './Manual'

interface ManualDialogProps {
  opened?: Function
  closed?: Function
  result?: Function
  show: boolean
}

export const ManualDialog = (props: ManualDialogProps) => {
  const [show, setShow] = useState(false)

  React.useEffect(() => {
    if (props.show) {
      setShow(true)
    }
  }, [props.show])
  
  const handleClose = () => {
    setShow(false)
    if (props.closed) {
      props.closed()
    }
  }

  return (
    <Dialog background={DIALOG_BG_COLOR.DEFAULT} show={show}>
      <Row justifyContent="start" marginTop="0">
        <ManualDialogHandle open={show} onClick={handleClose} />
      </Row>
      <Manual></Manual>
      <Button onClick={handleClose}>
        <Trans id="manual.close">Alles klar</Trans>
      </Button>
    </Dialog>
  )
}
