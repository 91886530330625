import React, { useContext } from 'react'
import { motion } from 'framer-motion'

import { GameStateContext } from '../../contexts'
import { Button, Card, Row } from '../../facets'
import { Trans } from '@lingui/macro'
import { i18n } from '@lingui/core'
import ReactMarkdown from 'react-markdown'

export const EventDecisionPresenter = () => {
  const { gameState, event, dispatch } = useContext(GameStateContext)

  const goToRisksPresenter = () => {
    dispatch({
      type: 'showRisks',
      outcome: gameState.outcome,
      probabilities: gameState.probabilities,
      action: gameState.action,
      funScoreChange: gameState.funScoreChange || 0,
      responsibilityScoreChange: gameState.responsibilityScoreChange || 0
    })
  }

  return (
    <>
      <motion.div initial={{ rotateY: 90 }} animate={{ rotateY: 0 }} transition={{ duration: 0.25 }}>
        <Card cardHeight={gameState.cardHeight} coachImage>
          <Card.CardText>
            <ReactMarkdown>{event.meta.goDescription[i18n.locale]}</ReactMarkdown>
          </Card.CardText>
        </Card>
      </motion.div>
      <Row marginTop="2rem" justifyContent="center">
        <Button onClick={goToRisksPresenter}>
          <Trans id="lets.go">Okay, los gehts!</Trans>
        </Button>
      </Row>
    </>
  )
}
