import styled from 'styled-components'

import * as theme from '../theme'

type ButtonSize = "large" | "small"
type ButtonVariant = "primary" | "secondary" | "link"
const DEFAULT_VARIANT = "primary"

interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  textColor?: string
  textTransform?: string
}

const regularColorFromVariantProps = (props: ButtonProps) => {
  const variant = props.variant || DEFAULT_VARIANT
  return props.variant === 'link' ? ['transparent'] : (theme as any)[variant]
}
const lightColorFromVariantProps = (props: ButtonProps) => {
  const variant = props.variant || DEFAULT_VARIANT
  return (theme as any)[`${variant}Light`]}


const textColorFromVariantProps = (props: ButtonProps) => {
  return props.variant === 'link' && props.textColor
    ? props.textColor
    : props.variant === 'link'
    ? (theme as any)[`textBlue`]
    : '#fff'
}

const paddingByButtonSize = (props: ButtonProps) => {
  return props.size === 'large' ? '16px 24px' : '13px 26px'
}

const fontSizeByButtonSize = (props: ButtonProps) => {
  return props.size === 'large' ? '1.6em' : '1rem'
}
const textTransform = (props: ButtonProps) => {
  return props.textTransform ? props.textTransform : 'none'
}

export const Button = styled.button<ButtonProps>`
  outline: none;
  border: none;
  text-transform: ${textTransform};
  text-align: center;
  letter-spacing: 0.01em;
  padding: ${paddingByButtonSize};

  background-color: ${regularColorFromVariantProps};
  color: ${textColorFromVariantProps};
  border-radius: 3px;

  font-size: ${fontSizeByButtonSize};
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  &:disabled {
    cursor: default;
    color: #D4CDC6;
    background-color: ${lightColorFromVariantProps};
  }
`
