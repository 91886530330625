import React from 'react'
import styled, {keyframes} from 'styled-components'

import {Icon} from '../facets'

interface ManualDialogHandleProps {
  open: boolean
  onClick: () => void
}

export const ManualDialogHandle = ({open, onClick}: ManualDialogHandleProps) => {
  return <Btn onClick={onClick}>{open ? <Icon variant="close" /> : <Icon variant="info" />}</Btn>
}

const swell = () => keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
`

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-top: 0 !important;

  background-color: #dfddce;
  border: 2px solid rgba(205, 202, 185, 0.2);

  animation: ${swell} 0.6s ease;

  svg {
    width: auto;
    height: 14px;
  }
`
