import React, { useContext, useLayoutEffect, useRef, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'

import { EventAction, GameStateContext } from '../../contexts'
import { Card, Row } from '../../facets'
import { ItemBox } from '../../facets/ItemBox'
import { Impact, ImpactType } from '../../util.ts/impact'
import { NOF_PROBABILITY_STEPS, ScoreCalculation } from '../../util.ts/score'
import { Trans } from '@lingui/macro'
import { replaceByLeaderName } from '../../util.ts/name'
import { i18n } from '@lingui/core'
import ReactMarkdown from 'react-markdown'

export const EventPresenter = () => {
  const { gameState, event, dispatch } = useContext(GameStateContext)
  const control = useAnimation()
  const rotateAnimation = { rotateY: 90, transition: { duration: 0.25 } }
  event.meta.actions.sort((a, b) => (a.dontGo === b.dontGo ? 0 : a.dontGo ? 1 : -1))

  const [cardHeight, setCardHeight] = useState(0)
  const cardRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(()=> {
      if(cardRef && cardRef.current) {
          setCardHeight(cardRef.current.clientHeight)
      }
  })

  const convertedItems = (data: EventAction[]) => {
    return data.map((action: EventAction) => {
      return { name: replaceByLeaderName(action.name[i18n.locale], gameState.leaders), data: action }
    })
  }

  const actionSelected = async (actions: EventAction[]) => {
    await control.start(rotateAnimation)
    const action = actions[0]
    let minorProb =
      event.meta.minorAccidentProbability +
      Impact.getValue(Impact.invertImpact(action.responsibilityImpact), ImpactType.PROBABILITY)
    let majorProb =
      event.meta.majorAccidentProbability +
      Impact.getValue(Impact.invertImpact(action.responsibilityImpact), ImpactType.PROBABILITY)

    const scoreSummary = ScoreCalculation.calculateScoreBySkills(
      gameState.leaders.map((l) => l.skill),
      {
        majorAccidentProbability: majorProb,
        minorAccidentProbability: minorProb,
        funScore: 0,
        responsibilityScore: 0
      }
    )

    ScoreCalculation.reduceToMaxOrMinProbability(scoreSummary)
    scoreSummary.majorAccidentProbability =
      scoreSummary.majorAccidentProbability <= 0
        ? 0
        : (scoreSummary.majorAccidentProbability / NOF_PROBABILITY_STEPS) * 100
    scoreSummary.minorAccidentProbability =
      scoreSummary.minorAccidentProbability <= 0
        ? 0
        : (scoreSummary.minorAccidentProbability / NOF_PROBABILITY_STEPS) * 100

    const probabilities = [
      100 - scoreSummary.minorAccidentProbability - scoreSummary.majorAccidentProbability,
      scoreSummary.minorAccidentProbability,
      scoreSummary.majorAccidentProbability
    ]

    const probabilitiesSum = probabilities.reduce((a, p) => a + p, 0)
    const random = Math.random() * probabilitiesSum

    const outcome =
      random <= probabilities[0]
        ? 'noAccident'
        : random <= probabilities[0] + probabilities[1]
          ? 'minorAccident'
          : 'majorAccident'

    if (action.dontGo) {
      dispatch({
        type: 'dontGo',
        responsibilityScoreChange:
          scoreSummary.responsibilityScore +
          Impact.getValue(action.responsibilityImpact, ImpactType.RESPONSIBILITY),
        funScoreChange: scoreSummary.funScore + Impact.getValue(action.funImpact, ImpactType.FUN),
        action,
        cardHeight: cardHeight
      })
    } else {
      dispatch({
        type: 'go',
        responsibilityScoreChange:
          scoreSummary.responsibilityScore +
          Impact.getValue(action.responsibilityImpact, ImpactType.RESPONSIBILITY),
        funScoreChange: scoreSummary.funScore + Impact.getValue(action.funImpact, ImpactType.FUN),
        action,
        outcome,
        probabilities,
        cardHeight: cardHeight
      })
    }
  }

  return (
    <>
      <motion.div animate={control}>
        <Card titleImage={event.meta.imagePath} outerRef={cardRef}>
          <Card.CardText>
            <ReactMarkdown>
              {replaceByLeaderName(
                event.meta.eventDescriptionDefault[i18n.locale],
                gameState.leaders
              )}
            </ReactMarkdown>
          </Card.CardText>
          <Card.CardText>
            <strong>
              <Trans id="event.go.or.dont">Wie entscheidet ihr euch?</Trans>
            </strong>
          </Card.CardText>
        </Card>
      </motion.div>
      <Row justifyContent="center">
        <ItemBox
          amountOfSelectableItems={1}
          items={convertedItems(event.meta.actions)}
          select={actionSelected}
          coloringCondition={{ field: 'dontGo' }}
          margins={3}
        ></ItemBox>
      </Row>
    </>
  )
}
