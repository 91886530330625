import React from 'react'
import styled from 'styled-components'

import * as theme from '../theme'

type SegmentVariant = 'positive' | 'negative' | 'special'
const DEFAULT_VARIANT = 'positive'
interface SegmentProps {
  probability: number
  variant?: SegmentVariant
}
const colorFromVariantProps = (props: SegmentProps) => {
  const variant = props.variant || DEFAULT_VARIANT
  return (theme as any)[variant]
}

interface WithChildren {
  children: React.ReactNode
}

const Bar = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 16px;

  display: flex;
  flex-direction: row;
`
const Segment = styled.div<SegmentProps>`
  flex: ${(props) => props.probability};
  height: 8px;

  background-color: ${colorFromVariantProps};

  &:first-of-type {
    border-radius: 4px 0 0 4px;
  }
  &:last-of-type {
    border-radius: 0 4px 4px 0;
  }
`

const ProbabilityBar = ({children}: WithChildren) => <Bar>{children}</Bar>

ProbabilityBar.Segment = ({probability, variant}: SegmentProps) => (
  <Segment probability={probability} variant={variant} />
)

export {ProbabilityBar}
