import {Dispatch, useReducer} from 'react'
import {gameParams} from '../config'
import {Scenario, Event, Day, Person, EventAction} from '../contexts/ScenariosContext'

export type GameScreen =
  | 'event'
  | 'manual'
  | 'didntGo'
  | 'eventDecision'
  | 'eventRisks'
  | 'victory'
  | 'avatars'
  | 'intro'
export type Outcome = 'noAccident' | 'minorAccident' | 'majorAccident' | 'noOutcome'
export interface GameState {
  gameOver: boolean
  day: number
  event: number
  screen: GameScreen
  minorAccidents: number
  funScore: number
  funScoreChange?: number
  funScoreHistory: number[]
  responsibilityScore: number
  responsibilityScoreChange?: number
  responsibilityScoreHistory: number[]
  decisions: string[]
  outcome: Outcome
  probabilities: number[]
  action?: EventAction
  leaders: Person[]
  cardHeight?: number
}
export const initialState: GameState = {
  gameOver: false,
  day: 1,
  event: 1,
  screen: 'intro',
  minorAccidents: 0,
  funScore: 0,
  funScoreHistory: [],
  responsibilityScore: 0,
  responsibilityScoreHistory: [],
  decisions: [],
  outcome: 'noOutcome',
  probabilities: [],
  leaders: []
}

type ActionGo = {
  type: 'go'
  responsibilityScoreChange: number
  outcome: Outcome
  probabilities: number[]
  funScoreChange: number
  action: EventAction
  cardHeight: number
}
type ActionDontGo = {
  type: 'dontGo'
  responsibilityScoreChange: number
  funScoreChange: number
  action: EventAction
  cardHeight: number
}

type ActionShowManual = {type: 'showManual'}
type ActionShowAvatars = {type: 'showAvatars'}
type ActionShowIntro = {type: 'showIntro'}
type ActionStartGame = {type: 'startGame'; leaders: Person[]}
type ActionShowSummary = {
  type: 'showSummary'
  funScoreChange: number
  responsibilityScoreChange: number
}
type ActionShowRisks = {
  type: 'showRisks'
  outcome: Outcome
  probabilities: number[]
  action?: EventAction
  funScoreChange: number
  responsibilityScoreChange: number
}

type ActionNextEvent = {
  type: 'nextEvent'
  scenario: Scenario
  day: Day
  dayCount: number
  event: Event
  eventCount: number
  funScoreChange: number
  responsibilityScoreChange: number
}
type ActionRestart = {type: 'restart'}

export type GameStateActionI =
  | ActionGo
  | ActionDontGo
  | ActionShowRisks
  | ActionNextEvent
  | ActionRestart
  | ActionStartGame
  | ActionShowManual
  | ActionShowSummary
  | ActionShowAvatars
  | ActionShowIntro

type GameStateReducer = (prevState: GameState, action: GameStateActionI) => GameState
const reducer: GameStateReducer = (state: GameState, action: GameStateActionI): GameState => {
  switch (action.type) {
    case 'showManual':
      return {
        ...state,
        screen: 'manual'
      }
    case 'showAvatars':
      return {
        ...state,
        screen: 'avatars'
      }
    case 'showSummary':
      const newFunScore = Math.round(state.funScore + (action.funScoreChange || 0))
      const newResponsibilityScore = state.responsibilityScore + (action.responsibilityScoreChange || 0)
      return {
        ...state,
        funScore: newFunScore,
        responsibilityScore: newResponsibilityScore,
        funScoreHistory: state.funScoreHistory.concat(newFunScore),
        responsibilityScoreHistory: state.responsibilityScoreHistory.concat(newResponsibilityScore),
        screen: 'victory'
      }
    case 'startGame':
      return {
        ...state,
        gameOver: false,
        day: 1,
        event: 1,
        screen: 'event',
        minorAccidents: 0,
        funScore: 0,
        responsibilityScore: 0,
        decisions: [],
        outcome: 'noOutcome',
        probabilities: [],
        leaders: action.leaders
      }
    case 'go':
      if (!action.action) {
        throw 'Undefined Action'
      }
      return {
        ...state,
        decisions: state.decisions.concat([action.action.id.toString()]),
        screen: 'eventDecision',
        outcome: action.outcome,
        probabilities: action.probabilities,
        funScoreChange: action.funScoreChange,
        action: action.action,
        cardHeight: action.cardHeight,
        responsibilityScoreChange: action.responsibilityScoreChange
      }
    case 'dontGo':
      if (!action.action) {
        throw 'Undefined Action'
      }
      return {
        ...state,
        funScoreChange: action.funScoreChange,
        action: action.action,
        decisions: state.decisions.concat([action.action.id.toString()]),
        screen: 'didntGo',
        cardHeight: action.cardHeight,
        responsibilityScoreChange: action.responsibilityScoreChange
      }
    case 'showRisks':
      if (!action.action) {
        throw 'Undefined Action'
      }
      let newGameState: GameState

      if (action.outcome === 'majorAccident') {
        newGameState = {
          ...state,
          screen: 'eventRisks',
          outcome: action.outcome,
          probabilities: action.probabilities,
          gameOver: true,
          action: action.action,
          funScoreChange: action.funScoreChange,
          responsibilityScoreChange: action.responsibilityScoreChange
        }
      } else if (action.outcome === 'minorAccident') {
        newGameState = {
          ...state,
          screen: 'eventRisks',
          outcome: action.outcome,
          probabilities: action.probabilities,
          minorAccidents: state.minorAccidents + 1,
          gameOver: state.minorAccidents >= gameParams.maxMinorAccidents - 1,
          action: action.action,
          funScoreChange: action.funScoreChange,
          responsibilityScoreChange: action.responsibilityScoreChange
        }
      } else {
        newGameState = {
          ...state,
          screen: 'eventRisks',
          outcome: action.outcome,
          probabilities: action.probabilities,
          action: action.action,
          funScoreChange: action.funScoreChange,
          responsibilityScoreChange: action.responsibilityScoreChange
        }
      }
      return newGameState
    case 'nextEvent':
      let nextDay: Day | undefined = action.day
      let nextEvent = action.day.events.find(
        (event) => event.sortorderInDay === action.event.sortorderInDay + 1
      )
      if (!nextEvent) {
        nextDay = action.scenario.days.find(
          (day) => day.meta.sortorderInScenario === action.day.meta.sortorderInScenario + 1
        )
        nextEvent = nextDay && nextDay.events.find((event) => event.sortorderInDay === 1)
      }

      const funScore = Math.round(state.funScore + (action.funScoreChange || 0))
      const responsibilityScore =
        state.responsibilityScore + (action.responsibilityScoreChange || 0)

      if (!nextEvent) {
        return {
          ...state,
          funScoreHistory: state.funScoreHistory.concat(funScore),
          funScore,
          funScoreChange: undefined,
          responsibilityScore,
          responsibilityScoreHistory: state.responsibilityScoreHistory.concat(responsibilityScore),
          screen: 'victory'
        }
      } else {
        return {
          ...state,
          screen: 'event',
          action: undefined,
          day: (nextDay && nextDay.meta.sortorderInScenario) || action.day.meta.sortorderInScenario,
          event: nextEvent.sortorderInDay,
          funScoreHistory: state.funScoreHistory.concat(funScore),
          funScore,
          funScoreChange: undefined,
          responsibilityScore,
          responsibilityScoreHistory: state.responsibilityScoreHistory.concat(responsibilityScore)
        }
      }
    case 'restart':
      return initialState
    default:
      return state
  }
}

type UseGameStateReturn = [GameState, Dispatch<GameStateActionI>]

export const useGameState = (): UseGameStateReturn => {
  const [gameState, dispatch] = useReducer<GameStateReducer>(reducer, initialState)
  return [gameState, dispatch]
}
