import React from 'react'
import Modal from 'react-modal';
import styled from 'styled-components'
import { AdditionalInfo } from '../contexts';
import { SnowSafety, Icon } from '../facets'
import {i18n} from '@lingui/core'
import { LinkIcon, LinkTitle } from '.';

const modalStyles = {
    overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 9999999
    },
    content: {
    padding: '0px',
    border: '0px',
    top: '72px',
    left: '21px',
    right: '21px',
    bottom: '21px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    overflow: 'visible',
    maxWidth: '400px',
    margin: 'auto'
    },
}
const ModalWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
height: 100%;
width: 100%;
`
const IconWrapper = styled.div`
align-self: flex-end;
padding: 21px 21px 0 0;
`
const SnowSafetyWrapper = styled.div`
position: absolute;
width: 113px;
height: 113px;
top: -49px;
left: 50%;
transform: translate(-50%, 0);
`
const Frame = styled.iframe`
height: 100%;
width: 100%;
border: 0px;
margin-top: 12px;
`

interface SnowSafetyPresenterProps {
    modalIsOpen: boolean
    modalSrc: string
    setModalIsOpen: (open: boolean) => void
}

export const SnowSafetyPresenter = (
    props: SnowSafetyPresenterProps
) => {
  
    function closeModal() {
        props.setModalIsOpen(false);
    }

    // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#root');

    return (
        <Modal
            isOpen={props.modalIsOpen}
            onRequestClose={closeModal}
            style={modalStyles}
            contentLabel="Snow-Safety-Modal"
        >
            <ModalWrapper>
                <IconWrapper onClick={closeModal}>
                    <Icon variant="close" />
                </IconWrapper>
                <SnowSafetyWrapper>
                    <SnowSafety></SnowSafety>
                </SnowSafetyWrapper>
                <Frame src={props.modalSrc} />
            </ModalWrapper>
        </Modal>
    )
}

interface ModalButtonProps {
    info: AdditionalInfo
    setModalIsOpen: (open: boolean) => void
    setModalSrc: (link: string) => void
}

const ModalAnchor = styled.a`
    cursor: pointer;
`

export const ModalButton = (
    props: ModalButtonProps
) => {
    function openModal() {
        let link = props.info.url
        const anchorIndex = link.indexOf('#')
        link = anchorIndex != -1 ? link.substring(0, anchorIndex) : link
        link = link.indexOf("?") == -1 ? `${link}?baspo=true` : `${link}&baspo=true`
        props.setModalIsOpen(true);
        props.setModalSrc(link)
    }

    return (
        <ModalAnchor onClick={openModal}>
            <LinkIcon><SnowSafety /></LinkIcon>
            <LinkTitle>{props.info.name[i18n.locale]}</LinkTitle>
        </ModalAnchor>
    )
}