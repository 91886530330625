import {Trans} from '@lingui/macro'
import {navigate} from '@reach/router'
import React, {useContext} from 'react'
import styled from 'styled-components'
import {GlobalBodyStyle} from '../../App'
import {GameStateContext} from '../../contexts'
import {Button, Row} from '../../facets'
import {Manual} from '../../facets/Manual'

const Link = styled.a`
  font-weight: 600;
  line-height: 24px;
  svg {
    margin-left: 3px;
  }
  cursor: pointer;
`

export const ManualPresenter = () => {
  const {dispatch} = useContext(GameStateContext)

  const goToLeaders = () => {
    dispatch({type: 'showAvatars'})
  }

  return (
    <>
      <GlobalBodyStyle light />
      <Row justifyContent="flex-end">
        <Link onClick={() => goToLeaders()}>
          <Trans id="manual.skip">Überspringen</Trans>
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4.30368 0.999966L8.60742 5.30371L4.30368 9.60746" stroke="#454545" />
          </svg>
        </Link>
      </Row>
      <Manual></Manual>
      <Row marginTop="3rem" justifyContent="center">
        <Button onClick={() => goToLeaders()}>
          <Trans id="manual.lets.go">Alles klar</Trans>
        </Button>
      </Row>
      <Row marginTop="1.5rem" justifyContent="center">
        <Button textColor="#454545" variant="link" onClick={() => navigate('../..')}>
          <Trans id="manual.back.to.sceanrio">Zur Lagerwahl</Trans>
        </Button>
      </Row>
    </>
  )
}
