import * as React from "react"

export const CoachSvg = () => (
    <svg
      height="100%"
      viewBox="0 0 95 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={95}
        height={95}
      >
        <circle cx={47.5} cy={47.5} r={47.5} fill="#C4C4C4" />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M43.756 112.568l-3.136 97.823H30.342L27.38 112.48l16.376.088zm12.021-96.071c1.045 1.05 2.352 2.014 3.397 3.065 2.44 2.365 3.224 5.08 3.485 8.495.174 2.365-.174 4.116-.61 6.393-.175 1.226-.175 2.277.174 3.503.784 2.365 2.265 4.379 2.962 6.743.697 2.453 1.394 5.693 1.22 8.32-.088 1.314 0 1.139-1.133 1.49-2.352.7-5.837.087-8.188.174-3.833.175-7.579 1.051-11.411 1.314-2.265.175-4.53.613-6.795.963-2.526.35-5.226.35-7.752.175-.262-.963-.087-2.364-.175-3.415-.087-1.401 0-2.803-.348-4.204-.348-1.313-1.132-2.715-1.132-4.028 0-1.314.348-2.89.435-4.204.087-1.401.262-2.802.262-4.204 0-2.277-.349-4.466-.262-6.743.087-3.328 1.046-6.831 2.7-9.809.523-.963 3.92-5.692 4.966-4.641"
          fill="#5D1211"
        />
        <path
          d="M64.227 85.069c1.394-5.518 2.7-10.334 3.658-15.764l2.526-12.611c0-2.803-3.048-5.43-6.446-5.955l-15.33-2.365c-.697.088-1.394.088-2.004.088l-17.16 2.364c-3.484.438-2.004 2.803-2.004 5.605 0 0 3.572 19.705 4.617 29.864.087 1.226-.436 1.926-.784 3.152-1.22 4.117-3.745 9.284-3.745 17.603h42.16s-.697-7.181-5.053-18.916c-.784-1.664-.958-1.226-.435-3.065z"
          fill="#E30336"
        />
        <path
          d="M47.938 57.133c-.523 1.489-2.875 4.291-4.966 4.466-1.916.176-2.787-1.138-3.832-2.802-1.133-1.664-2.614-6.043-2.875-7.882l11.672 6.218zm1.393 0c.436 1.489 2.44 4.291 4.181 4.466 1.568.176 2.265-1.138 3.223-2.802.959-1.664 2.09-5.517 2.352-7.444l-9.756 5.78z"
          stroke="#BF172D"
          strokeWidth={1.787}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.77 48.812c-2.09-.175-4.268-.35-6.358-.438l-.262.088c-.174-.088-6.881.788-6.881.788-.349 5.867 7.84 8.933 12.456 7.006 1.568-.7 3.31-1.664 4.094-3.153.523-1.05 1.133-2.54 1.22-3.766-1.394 0-2.962-.438-4.269-.525z"
          fill="#F08A49"
        />
        <path
          d="M52.206 51.965c-2.526 3.153-7.317 3.066-9.67-.263-.348-.437-.435-.963-.435-1.488V31.122h10.802v18.829c0 .7-.262 1.401-.697 2.014z"
          fill="#E9693C"
        />
        <path
          d="M46.892 41.806c7.938 0 14.373-6.469 14.373-14.45 0-7.98-6.435-14.45-14.373-14.45s-14.373 6.47-14.373 14.45c0 7.981 6.435 14.45 14.373 14.45z"
          fill="#F08A49"
        />
        <path
          d="M47.154 30.51c-.697 0-1.306-.614-1.306-1.314V20c0-.7.61-1.314 1.306-1.314.697 0 1.307.614 1.307 1.314v9.196c0 .7-.523 1.313-1.307 1.313z"
          fill="#F5AD7A"
        />
        <path
          d="M48.373 31.735h-2.438a.597.597 0 01-.61-.613v-.35c0-.351.261-.614.61-.614h2.438c.349 0 .61.263.61.613v.35c0 .35-.261.614-.61.614z"
          fill="#E9693C"
        />
        <path
          d="M43.234 33.662h8.014s-1.393 2.102-4.094 2.102c-2.7 0-3.92-2.102-3.92-2.102z"
          fill="#fff"
        />
        <path
          d="M39.75 27.706c.673 0 1.22-.549 1.22-1.226 0-.677-.547-1.226-1.22-1.226-.674 0-1.22.549-1.22 1.226 0 .677.546 1.226 1.22 1.226zM54.907 27.706c.673 0 1.22-.549 1.22-1.226 0-.677-.547-1.226-1.22-1.226-.674 0-1.22.549-1.22 1.226 0 .677.546 1.226 1.22 1.226z"
          fill="#5D1211"
        />
        <path
          opacity={0.2}
          d="M56.736 36.29a2.97 2.97 0 002.962-2.978 2.97 2.97 0 00-2.962-2.978 2.97 2.97 0 00-2.962 2.978 2.97 2.97 0 002.962 2.977zM37.572 36.377a2.97 2.97 0 002.962-2.977 2.97 2.97 0 00-2.962-2.978 2.97 2.97 0 00-2.961 2.978 2.97 2.97 0 002.961 2.977z"
          fill="#E30336"
        />
        <path
          d="M45.935 15.796c-.523 1.663 3.223 4.116 4.704 4.729 2.351.963 4.79 1.489 6.881 3.065 1.916 1.401 4.007 3.328 3.484 7.006 1.307-2.014 1.307-7.707.523-9.809-.61-1.488-1.916-2.977-3.397-3.853-.958-.525-2.7-.613-3.136-1.226"
          fill="#5D1211"
        />
        <path
          d="M51.335 16.233c1.481 4.642-10.888 5.167-13.066 6.13-4.79 2.103-5.575 4.467-5.487 8.67-1.917-5.079-.61-11.297 5.139-14.8"
          fill="#5D1211"
        />
        <path
          d="M59.523 17.722c-.261-3.415.61-6.743-2.178-9.633C54.82 5.549 49.593 5.11 46.11 5.11c-4.443.088-8.101 1.402-9.06 5.78-.174.964-.435 4.029 0 5.08.871 2.014 2.352 1.226 5.053 1.314 5.226.087 10.278.788 15.505.788"
          fill="#E30336"
        />
        <path
          d="M58.74 17.722c-4.966 2.978-11.064-2.452-16.29-3.766-2.352-.525-8.014-1.138-10.714.789-1.22.788-.697 2.802.784 2.977 6.272.7 12.717.35 19.076.35"
          fill="#5D1211"
        />
        <path
          d="M33.653 13.958c1.742-.438 3.745-1.139 5.749-1.139 2.178 0 4.355.526 6.446 1.226 2.178.701 4.181 1.402 6.446 1.927 1.742.438 6.097.963 7.404 1.84-2.962 3.94-9.582 0-12.805-1.665-4.007-2.102-7.491-2.54-12.02-2.54"
          fill="#BF172D"
        />
        <path
          d="M73.286 71.846s.87 1.927 4.268 22.244c.784 4.554-12.456-2.802-21.515-7.181"
          stroke="#F08A49"
          strokeWidth={10.504}
          strokeMiterlimit={10}
        />
        <path
          d="M68.495 52.666c2.265 2.014 11.672 20.668 11.15 22.069-.262.788-4.182 2.014-5.227 2.627s-4.268.876-5.226 1.489c-.436-1.226-3.136-4.992-3.659-6.218"
          fill="#E30336"
        />
        <path
          d="M70.324 78.326c2.875-.613 5.662-2.364 8.362-2.977 2.962 3.503-5.923 4.641-7.926 4.904"
          fill="#E9693C"
        />
        <path
          d="M29.645 55.993s-8.188 8.32-11.41 28.988c-1.046 6.743 8.1 2.014 21.34-1.84"
          stroke="#F08A49"
          strokeWidth={10.504}
          strokeMiterlimit={10}
        />
        <path
          d="M27.38 51.527c-2.264 2.015-4.53 6.218-6.445 8.583-1.743 2.102-2.352 4.379-3.31 6.918-.523 1.314-2.788 4.116-2.352 5.518.26.788 5.487 3.065 6.533 3.678 1.045.613 2.177 1.051 3.136 1.752.435-1.226 9.581-17.166 10.191-18.392"
          fill="#E30336"
        />
        <path
          d="M16.143 72.983c-.261 2.89-.61 3.24 2.352 4.73 1.568.788 3.136 1.576 4.704 2.277.435-.789.697-1.577.87-2.54"
          fill="#E9693C"
        />
        <path
          d="M40.534 76.137l-1.655.876-5.924-9.896 1.568-.876 6.01 9.896z"
          fill="#F6F6F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.17 71.145h.524c0 .7-.436 1.4-1.046 1.663-.61.263-1.393.088-1.829-.35a1.732 1.732 0 01-.523-1.225V69.83h2.962v1.314h-.087zm.524-5.342v1.751h-.523v1.226h-1.307v-2.977h1.83zm3.397.963c.174.438.174.788.174 1.226v.876H60.22c.522.263.958.788 1.045 1.401v1.051c0 .613-.436 1.226-1.045 1.489-.349.175-.871.175-1.22.087-.435-.175-.87-.525-1.045-.963-.087-.263-.174-.788-.174-.788h.435v-1.226h1.307v-1.051h-1.22v-1.226h-.522v-.088c0-.7.522-1.401 1.22-1.664.522-.175 1.219-.087 1.654.263.174.088.349.35.436.613z"
          fill="#fff"
        />
        <path
          d="M38.008 70.796h19.599c.958 0 1.655.788 1.655 1.664v26.273c0 .963-.784 1.664-1.655 1.664h-19.6c-.957 0-1.654-.788-1.654-1.664V72.46c0-.963.784-1.664 1.655-1.664z"
          fill="#5D1211"
        />
        <path d="M43.843 69.656h7.927v2.627h-7.927v-2.627z" fill="#3B1215" />
        <path
          d="M61.265 87.435c.087-4.73-1.916-7.444-6.968-6.13-1.394.35-3.398 1.138-1.917 2.364.871.788 3.484.35 4.704.35-1.394 0-5.314.088-5.4 1.84 0 1.926 3.92 1.138 4.79 1.663-1.132 0-4.878.263-4.53 2.014.262 1.402 4.095.964 5.227 1.051-1.045.263-6.272.964-3.136 2.89 1.133.701 5.488.438 6.36-.087"
          fill="#F08A49"
        />
      </g>
    </svg>
  )