import React, {createContext, useState, FC} from 'react'

type ToolTipContextState = {
  openToolTipKey: string
  setOpenToolTipKey: React.Dispatch<React.SetStateAction<string>>
}

const contextDefaultValues: ToolTipContextState = {
  openToolTipKey: '',
  setOpenToolTipKey: () => {}
}

export const ToolTipContext = createContext<ToolTipContextState>(contextDefaultValues)

const ToolTipProvider: FC = ({children}) => {
  const [openToolTipKey, setOpenToolTipKey] = useState<string>(contextDefaultValues.openToolTipKey)

  return (
    <ToolTipContext.Provider
      value={{
        openToolTipKey,
        setOpenToolTipKey
      }}
    >
      {children}
    </ToolTipContext.Provider>
  )
}

export default ToolTipProvider
