import React, { useContext } from 'react'
import styled from 'styled-components'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { Button, Row } from '../../facets'
import { GlobalBodyStyle } from '../../App'
import { Footer } from '../../facets/Footer'
import { HeaderImage } from '../../facets/HeaderImage'
import { CurrentScenarioContext, GameStateContext, Scenario } from '../../contexts'

export const IntroPresenter = () => {
  const scenario: Scenario = useContext(CurrentScenarioContext)
  const {dispatch} = useContext(GameStateContext)

  const IntroCopy = styled.p`
    text-align: center;
    font-size: 14px;
    line-height: 21px;
  `

  const ListItem = styled.div<{ small?: boolean }>`
    background: #46c6c0;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 1rem;
    color: #fff;
    font-weight: 600;
    text-align: center;
    padding: 15px;
    display: ${(props) => (props.small ? 'inline-block' : 'block')};
  `

  const Buttons = styled.div`
    margin-top: 2rem;
    text-align: center;
  `
  return (
    <>
      <HeaderImage />
      <GlobalBodyStyle light={true} />
      <Row marginTop="1.5rem">
        <IntroCopy>
          <Trans id="scenario.intro.small.title">
            Willkommen auf dem Lerntool zum Thema Sicherheit und Unfallprävention beim Sport mit
            Kindern und Jugendlichen
          </Trans>
        </IntroCopy>
      </Row>
      <Row marginTop="1.5rem">
        <h1>
          <Trans id="scenario.intro.title">
            Stellt euch vor ihr plant ein {scenario.meta.name[i18n.locale]}-Lager und führt dieses gemeinsam durch.
          </Trans>
        </h1>
      </Row>
      <Buttons>
        <Button variant="secondary" onClick={() => dispatch({ type: 'showManual' })}>
          <Trans id="intro.lets.go">Start</Trans>
        </Button>
      </Buttons>
      <Footer />
    </>
  )
}
