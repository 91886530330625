import React, {useContext, useState} from 'react'

import {CurrentScenarioContext, GameStateContext} from '../../contexts'
import {EventRisksDistributionPresenter} from '../../presenters'
import {Button, Card, Paragraph, Row} from '../../facets'
import styled, {css} from 'styled-components'
import {Dialog, DIALOG_BG_COLOR, DIALOG_TEXT_COLOR} from '../../facets/Dialog'
import {Outcome} from '../../hooks'
import {Trans} from '@lingui/macro'
import {replaceByLeaderName} from '../../util.ts/name'
import {Impact, ImpactType} from '../../util.ts/impact'
import {i18n} from '@lingui/core'
import {motion} from 'framer-motion'
import ReactMarkdown from 'react-markdown'

const DialogParagraph = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`

interface AppearingDivProps {
  show: boolean
  maringTop?: string
}
const AppearingDiv = styled.div<AppearingDivProps>`
  opacity: ${({show}) => (show ? 1 : 0)};
  margin-top: ${({maringTop}) => (maringTop ? maringTop : '0px')};
  transition: opacity 0.5s ease-in;
  text-align: center;
`

interface DisappearingDivProps {
  hide: boolean
}
const DisappearingDiv = styled.div<DisappearingDivProps>`
  opacity: ${({hide}) => (hide ? 0 : 1)};
  transition: opacity 0.5s ease-out;
`

interface ResultParagraphProps {
  outcome: Outcome
}
const ResultParagraph = styled.p<ResultParagraphProps>`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  ${(props) =>
    css`
      color: ${props.outcome === 'noAccident'
        ? '#00A19A'
        : props.outcome === 'majorAccident'
        ? '#BF122B'
        : props.outcome === 'minorAccident'
        ? '#F8B131'
        : '#454545'};
    `}
`

export const EventRisksPresenter = () => {
  const scenario = useContext(CurrentScenarioContext)
  const {gameState, day, dayCount, event, eventCount, dispatch} = useContext(GameStateContext)
  const [hasStopped, setHasStopped] = useState(false)
  const [hasStarted, setHasStarted] = useState(false)
  const [stop, setStop] = useState(false)
  const noRisks = gameState.probabilities[1] + gameState.probabilities[2] > 0 ? false : true
  let titleText, descriptionText, buttonText: any
  let goOn: () => void
  let gameOverText: any = ''

  if (!gameState.action) {
    throw 'Undefined Action'
  }

  if (gameState.outcome === 'noAccident') {
    titleText = <Trans id="event.risk.went.well">Alles gut!</Trans>
    descriptionText = replaceByLeaderName(
      gameState.action.noAccidentDescription[i18n.locale],
      gameState.leaders
    )
    buttonText = <Trans id="event.risk.go.on">Weiter gehts</Trans>
    goOn = () =>
      dispatch({
        type: 'nextEvent',
        scenario,
        day,
        dayCount,
        event,
        eventCount,
        funScoreChange: gameState.funScoreChange || 0,
        responsibilityScoreChange: gameState.responsibilityScoreChange || 0
      })
  } else if (gameState.outcome === 'minorAccident') {
    titleText = <Trans id="event.risk.minor.accident">Oh nein!</Trans>
    descriptionText = replaceByLeaderName(
      gameState.action.minorAccidentDescription[i18n.locale],
      gameState.leaders
    )
    if (gameState.gameOver) {
      buttonText = <Trans id="event.risk.go.to.summary">Weiter zur Zusammenfassung</Trans>
      gameOverText = (
        <Trans id="event.risk.to.many.minor.accidents">
          Leider muss das Lager aufgrund zu vieler Unfälle abgebrochen werden.
        </Trans>
      )
      goOn = () => dispatch({
        type: 'showSummary',
        funScoreChange: (gameState.funScoreChange || 0) + Impact.getValue('-', ImpactType.FUN),
        responsibilityScoreChange: gameState.responsibilityScoreChange || 0
      })
    } else {
      buttonText = <Trans id="event.risk.go.on">Weiter gehts</Trans>
      goOn = () =>
        dispatch({
          type: 'nextEvent',
          scenario,
          day,
          dayCount,
          event,
          eventCount,
          funScoreChange: (gameState.funScoreChange || 0) + Impact.getValue('-', ImpactType.FUN),
          responsibilityScoreChange: gameState.responsibilityScoreChange || 0
        })
    }
  } else {
    titleText = <Trans id="event.risk.worst.case">Wählt die 144!</Trans>
    descriptionText = replaceByLeaderName(
      gameState.action.majorAccidentDescription[i18n.locale],
      gameState.leaders
    )
    buttonText = <Trans id="event.risk.go.to.summary">Weiter zur Zusammenfassung</Trans>
    gameOverText = (
      <Trans id="event.risk.game.over">
        Leider muss das Lager aufgrund des schlimmen Unfalls abgebrochen werden.
      </Trans>
    )
    goOn = () => dispatch({
      type: 'showSummary',
      funScoreChange: (gameState.funScoreChange || 0) + Impact.getValue('--', ImpactType.FUN),
      responsibilityScoreChange: gameState.responsibilityScoreChange || 0
    })
  }

  const ClickWheelText = styled.p`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #a5a295;
  `

  const isDialogOpen = () => {
    return hasStarted && !stop
  }

  const wheelHasStopped = () => {
    setHasStopped(true)
    setHasStarted(false)
  }

  const wheelHasStarted = () => {
    setHasStarted(true)
    if (
      event.meta.riskWheelInfoText === undefined ||
      event.meta.riskWheelInfoText[i18n.locale] === '-'
    ) {
      setStop(true)
    }
  }

  const showDialog = () => {
    if (
      event.meta.riskWheelInfoText !== undefined &&
      event.meta.riskWheelInfoText[i18n.locale] !== '-'
    ) {
      return (
        <>
          <Dialog
            maximize={true}
            background={DIALOG_BG_COLOR.BEIGE}
            color={DIALOG_TEXT_COLOR.MEDIUM_DARK}
            show={isDialogOpen()}
          >
            <Row justifyContent="center">
              <DialogParagraph>{event.meta.riskWheelInfoText[i18n.locale]}</DialogParagraph>
            </Row>
            <Row justifyContent="center">
              <Button onClick={() => setStop(true)}>
                <Trans id="next">Weiter</Trans>
              </Button>
            </Row>
          </Dialog>
        </>
      )
    }

    return <></>
  }

  const showWheel = (titleText: any, descriptionText: any) => {
    return (
      <>
        <EventRisksDistributionPresenter
          outcome={gameState.outcome}
          imagePath={event.meta.imagePath}
          noAccidentProbability={gameState.probabilities[0]}
          minorAccidentProbability={gameState.probabilities[1]}
          majorAccidentProbability={gameState.probabilities[2]}
          wheelStopped={wheelHasStopped}
          wheelStarted={wheelHasStarted}
          stopWheel={stop}
        />
        <DisappearingDiv hide={hasStarted}>
          {!hasStopped ? (
            <motion.div
              animate={{y: [0, -8, 0]}}
              transition={{repeat: Infinity, delay: 1.8, repeatDelay: 1.8, duration: 0.4}}
            >
              <ClickWheelText>
                <Trans id="click.wheel">Tippe aufs Rad um es zu drehen</Trans>
              </ClickWheelText>
            </motion.div>
          ) : (
            ''
          )}
        </DisappearingDiv>
        {showDialog()}
        <AppearingDiv show={hasStopped}>
          <ResultParagraph outcome={gameState.outcome}>{titleText}</ResultParagraph>
          <ReactMarkdown>{descriptionText}</ReactMarkdown>
          {gameState.gameOver && (
            <Paragraph>
              <strong>{gameOverText}</strong>
            </Paragraph>
          )}
          <Row marginTop="2rem" justifyContent="center">
            <Button disabled={!hasStopped} onClick={goOn}>
              {buttonText}
            </Button>
          </Row>
        </AppearingDiv>
      </>
    )
  }

  return (
    <>
      {noRisks ? (
        <>
          <AppearingDiv maringTop="2em" show={true}>
            <ResultParagraph outcome={gameState.outcome}>{titleText}</ResultParagraph>
              <ReactMarkdown>{descriptionText}</ReactMarkdown>
            <Row justifyContent="center">
              <Button variant="link" textColor="#454545" textTransform="uppercase" onClick={goOn}>
                {buttonText}
              </Button>
            </Row>
          </AppearingDiv>
        </>
      ) : (
        showWheel(titleText, descriptionText)
      )}
    </>
  )
}
