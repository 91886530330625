import React from 'react'
import styled, {css} from 'styled-components'

import {positive, negative} from '../theme'
import {Icon} from './Icon'

type ButtonVariant = 'go' | 'dontGo' | 'info'

interface ButtonProps {
  variant: ButtonVariant
  size?: 'small' | 'medium'
}
interface IconButtonProps {
  variant: ButtonVariant
  onClick?: () => void
  size?: 'small' | 'medium'
}

const Button = styled.button<ButtonProps>`
  outline: none;
  border: none;
  ${(props) =>
    css`
      height: ${props.size === 'small' ? '30px' : '72px'};
      width: ${props.size === 'small' ? '30px' : '72px'};
    `}
  margin: 0 8px;

  border: 4px solid;
  border-radius: 100%;
  cursor: pointer;

  color: #ffffff;
  font-size: 1.6em;

  transition: border-color 100ms linear, transform 100ms linear;

  &:hover {
    border-color: transparent;
    transform: scale(1.1);

    svg {
      transform: scale(0.909);
    }
  }
`
export const Surface = styled.div<ButtonProps>`
  display: flex;

  ${(props) =>
    css`
      height: ${props.size === 'small' ? '22px' : '64px'};
      width: ${props.size === 'small' ? '22px' : '64px'};
    `}

  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${(props) =>
    props.variant === 'go' ? positive : props.variant === 'dontGo' ? negative : '#DFDDCE'};
  border-radius: 100%;

  svg {
    transition: transform 100ms linear;
  }
`

export const IconButton = ({variant, onClick, size}: IconButtonProps) => (
  <Button size={size} variant={variant} onClick={onClick}>
    <Surface size={size} variant={variant}>
      <Icon variant={variant} />
    </Surface>
  </Button>
)
