import React from "react"

export const ManualSpinningWheel = () => (
<svg width="272" height="272" viewBox="0 0 272 272" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="135.703" cy="136.177" r="96.9925" transform="rotate(4.70676 135.703 136.177)" fill="#DFDDCE"/>
<circle cx="135.71" cy="136.101" r="96.9925" transform="rotate(4.70676 135.71 136.101)" fill="url(#paint0_radial)" fill-opacity="0.2"/>
<circle cx="136.245" cy="136.112" r="96.393" transform="rotate(4.70676 136.245 136.112)" fill="url(#paint1_radial)" fill-opacity="0.2"/>
<mask id="path-4-inside-1" fill="white">
<path d="M112.803 221.841C103.796 219.428 95.2261 215.612 87.4064 210.534L135.8 136.015L112.803 221.841Z"/>
</mask>
<path d="M112.803 221.841C103.796 219.428 95.2261 215.612 87.4064 210.534L135.8 136.015L112.803 221.841Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-4-inside-1)"/>
<mask id="path-5-inside-2" fill="white">
<path d="M87.4064 210.534C79.5866 205.456 72.615 199.178 66.7472 191.932L135.8 136.015L87.4064 210.534Z"/>
</mask>
<path d="M87.4064 210.534C79.5866 205.456 72.615 199.178 66.7472 191.932L135.8 136.015L87.4064 210.534Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-5-inside-2)"/>
<mask id="path-6-inside-3" fill="white">
<path d="M66.7472 191.932C60.8795 184.686 56.1888 176.562 52.8474 167.857L135.8 136.015L66.7472 191.932Z"/>
</mask>
<path d="M66.7472 191.932C60.8795 184.686 56.1888 176.562 52.8474 167.857L135.8 136.015L66.7472 191.932Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-6-inside-3)"/>
<mask id="path-7-inside-4" fill="white">
<path d="M52.8474 167.857C49.506 159.152 47.5555 149.976 47.0675 140.665L135.8 136.015L52.8474 167.857Z"/>
</mask>
<path d="M52.8474 167.857C49.506 159.152 47.5555 149.976 47.0675 140.665L135.8 136.015L52.8474 167.857Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-7-inside-4)"/>
<mask id="path-8-inside-5" fill="white">
<path d="M47.0675 140.665C46.5796 131.354 47.5602 122.024 49.9734 113.018L135.8 136.015L47.0675 140.665Z"/>
</mask>
<path d="M47.0675 140.665C46.5796 131.354 47.5602 122.024 49.9734 113.018L135.8 136.015L47.0675 140.665Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-8-inside-5)"/>
<mask id="path-9-inside-6" fill="white">
<path d="M61.2805 87.6214C66.3587 79.8017 72.636 72.83 79.8821 66.9623L135.8 136.015L61.2805 87.6214Z"/>
</mask>
<path d="M61.2805 87.6214C66.3587 79.8017 72.636 72.83 79.8821 66.9623L135.8 136.015L61.2805 87.6214Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-9-inside-6)"/>
<mask id="path-10-inside-7" fill="white">
<path d="M103.957 53.0625C112.662 49.7211 121.838 47.7706 131.149 47.2826L135.8 136.015L103.957 53.0625Z"/>
</mask>
<path d="M103.957 53.0625C112.662 49.7211 121.838 47.7706 131.149 47.2826L135.8 136.015L103.957 53.0625Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-10-inside-7)"/>
<mask id="path-11-inside-8" fill="white">
<path d="M158.797 50.1885C167.803 52.6017 176.373 56.4174 184.193 61.4956L135.8 136.015L158.797 50.1885Z"/>
</mask>
<path d="M158.797 50.1885C167.803 52.6017 176.373 56.4174 184.193 61.4956L135.8 136.015L158.797 50.1885Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-11-inside-8)"/>
<mask id="path-12-inside-9" fill="white">
<path d="M204.852 80.0972C210.72 87.3432 215.41 95.4677 218.752 104.172L135.8 136.015L204.852 80.0972Z"/>
</mask>
<path d="M204.852 80.0972C210.72 87.3432 215.41 95.4677 218.752 104.172L135.8 136.015L204.852 80.0972Z" fill="#E20033" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-12-inside-9)"/>
<mask id="path-13-inside-10" fill="white">
<path d="M224.532 131.364C225.02 140.676 224.039 150.006 221.626 159.012L135.8 136.015L224.532 131.364Z"/>
</mask>
<path d="M224.532 131.364C225.02 140.676 224.039 150.006 221.626 159.012L135.8 136.015L224.532 131.364Z" fill="#E20033" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-13-inside-10)"/>
<mask id="path-14-inside-11" fill="white">
<path d="M210.319 184.408C205.241 192.228 198.963 199.199 191.717 205.067L135.8 136.015L210.319 184.408Z"/>
</mask>
<path d="M210.319 184.408C205.241 192.228 198.963 199.199 191.717 205.067L135.8 136.015L210.319 184.408Z" fill="#F8B131" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-14-inside-11)"/>
<mask id="path-15-inside-12" fill="white">
<path d="M167.642 218.967C158.937 222.308 149.761 224.259 140.45 224.747L135.8 136.015L167.642 218.967Z"/>
</mask>
<path d="M167.642 218.967C158.937 222.308 149.761 224.259 140.45 224.747L135.8 136.015L167.642 218.967Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-15-inside-12)"/>
<mask id="path-16-inside-13" fill="white">
<path d="M49.9734 113.018C52.3866 104.011 56.2023 95.4411 61.2805 87.6214L135.8 136.015L49.9734 113.018Z"/>
</mask>
<path d="M49.9734 113.018C52.3866 104.011 56.2023 95.4411 61.2805 87.6214L135.8 136.015L49.9734 113.018Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-16-inside-13)"/>
<mask id="path-17-inside-14" fill="white">
<path d="M79.8821 66.9623C87.1281 61.0946 95.2526 56.4039 103.957 53.0625L135.8 136.015L79.8821 66.9623Z"/>
</mask>
<path d="M79.8821 66.9623C87.1281 61.0946 95.2526 56.4039 103.957 53.0625L135.8 136.015L79.8821 66.9623Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-17-inside-14)"/>
<mask id="path-18-inside-15" fill="white">
<path d="M131.149 47.2826C140.461 46.7946 149.79 47.7753 158.797 50.1885L135.8 136.015L131.149 47.2826Z"/>
</mask>
<path d="M131.149 47.2826C140.461 46.7946 149.79 47.7753 158.797 50.1885L135.8 136.015L131.149 47.2826Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-18-inside-15)"/>
<mask id="path-19-inside-16" fill="white">
<path d="M184.193 61.4956C192.013 66.5738 198.984 72.8511 204.852 80.0972L135.8 136.015L184.193 61.4956Z"/>
</mask>
<path d="M184.193 61.4956C192.013 66.5738 198.984 72.8511 204.852 80.0972L135.8 136.015L184.193 61.4956Z" fill="#E20033" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-19-inside-16)"/>
<mask id="path-20-inside-17" fill="white">
<path d="M218.752 104.172C222.093 112.877 224.044 122.053 224.532 131.365L135.8 136.015L218.752 104.172Z"/>
</mask>
<path d="M218.752 104.172C222.093 112.877 224.044 122.053 224.532 131.365L135.8 136.015L218.752 104.172Z" fill="#E20033" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-20-inside-17)"/>
<mask id="path-21-inside-18" fill="white">
<path d="M221.626 159.012C219.213 168.018 215.397 176.588 210.319 184.408L135.8 136.015L221.626 159.012Z"/>
</mask>
<path d="M221.626 159.012C219.213 168.018 215.397 176.588 210.319 184.408L135.8 136.015L221.626 159.012Z" fill="#F8B131" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-21-inside-18)"/>
<mask id="path-22-inside-19" fill="white">
<path d="M191.717 205.067C184.471 210.935 176.347 215.626 167.642 218.967L135.8 136.015L191.717 205.067Z"/>
</mask>
<path d="M191.717 205.067C184.471 210.935 176.347 215.626 167.642 218.967L135.8 136.015L191.717 205.067Z" fill="#F8B131" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-22-inside-19)"/>
<mask id="path-23-inside-20" fill="white">
<path d="M140.45 224.747C131.139 225.235 121.809 224.254 112.803 221.841L135.8 136.015L140.45 224.747Z"/>
</mask>
<path d="M140.45 224.747C131.139 225.235 121.809 224.254 112.803 221.841L135.8 136.015L140.45 224.747Z" fill="#00A19A" stroke="#323232" stroke-opacity="0.1" stroke-width="2" mask="url(#path-23-inside-20)"/>
<g filter="url(#filter0_d)">
<circle cx="219.864" cy="175.514" r="1.70162" transform="rotate(113.986 219.864 175.514)" fill="url(#paint2_radial)"/>
</g>
<g filter="url(#filter1_d)">
<circle cx="204.005" cy="198.739" r="1.70162" transform="rotate(113.986 204.005 198.739)" fill="url(#paint3_radial)"/>
</g>
<g filter="url(#filter2_d)">
<circle cx="182.127" cy="216.117" r="1.70162" transform="rotate(113.986 182.127 216.117)" fill="url(#paint4_radial)"/>
</g>
<g filter="url(#filter3_d)">
<circle cx="155.823" cy="226.221" r="1.70162" transform="rotate(113.986 155.823 226.221)" fill="url(#paint5_radial)"/>
</g>
<g filter="url(#filter4_d)">
<circle cx="126.242" cy="227.795" r="1.70162" transform="rotate(113.986 126.242 227.795)" fill="url(#paint6_radial)"/>
</g>
<g filter="url(#filter5_d)">
<circle cx="98.0084" cy="221.106" r="1.70162" transform="rotate(113.986 98.0084 221.106)" fill="url(#paint7_radial)"/>
</g>
<g filter="url(#filter6_d)">
<circle cx="74.8268" cy="205.524" r="1.70162" transform="rotate(113.986 74.8268 205.524)" fill="url(#paint8_radial)"/>
</g>
<g filter="url(#filter7_d)">
<circle cx="56.4058" cy="183.872" r="1.70162" transform="rotate(113.986 56.4058 183.872)" fill="url(#paint9_radial)"/>
</g>
<g filter="url(#filter8_d)">
<circle cx="45.0668" cy="156.431" r="1.70162" transform="rotate(113.986 45.0668 156.431)" fill="url(#paint10_radial)"/>
</g>
<g filter="url(#filter9_d)">
<circle cx="43.3772" cy="126.012" r="1.70162" transform="rotate(113.986 43.3772 126.012)" fill="url(#paint11_radial)"/>
</g>
<g filter="url(#filter10_d)">
<circle cx="51.5027" cy="96.9313" r="1.70162" transform="rotate(113.986 51.5027 96.9313)" fill="url(#paint12_radial)"/>
</g>
<g filter="url(#filter11_d)">
<circle cx="68.9566" cy="72.0377" r="1.70162" transform="rotate(113.986 68.9566 72.0377)" fill="url(#paint13_radial)"/>
</g>
<g filter="url(#filter12_d)">
<circle cx="91.2109" cy="54.8356" r="1.70162" transform="rotate(113.986 91.2109 54.8356)" fill="url(#paint14_radial)"/>
</g>
<g filter="url(#filter13_d)">
<circle cx="118.007" cy="44.9508" r="1.70162" transform="rotate(113.986 118.007 44.9508)" fill="url(#paint15_radial)"/>
</g>
<g filter="url(#filter14_d)">
<circle cx="146.877" cy="43.65" r="1.70162" transform="rotate(113.986 146.877 43.65)" fill="url(#paint16_radial)"/>
</g>
<g filter="url(#filter15_d)">
<circle cx="174.212" cy="51.0973" r="1.70162" transform="rotate(113.986 174.212 51.0973)" fill="url(#paint17_radial)"/>
</g>
<g filter="url(#filter16_d)">
<circle cx="199.576" cy="68.277" r="1.70162" transform="rotate(113.986 199.576 68.277)" fill="url(#paint18_radial)"/>
</g>
<g filter="url(#filter17_d)">
<circle cx="216.681" cy="89.444" r="1.70162" transform="rotate(113.986 216.681 89.444)" fill="url(#paint19_radial)"/>
</g>
<g filter="url(#filter18_d)">
<circle cx="226.839" cy="116.951" r="1.70162" transform="rotate(113.986 226.839 116.951)" fill="url(#paint20_radial)"/>
</g>
<g filter="url(#filter19_d)">
<circle cx="228.359" cy="145.329" r="1.70162" transform="rotate(113.986 228.359 145.329)" fill="url(#paint21_radial)"/>
</g>
<circle cx="136.25" cy="136.251" r="88.8539" transform="rotate(162.706 136.25 136.251)" fill="url(#paint22_radial)" fill-opacity="0.3"/>
<circle cx="135.862" cy="135.933" r="59.2359" transform="rotate(16.015 135.862 135.933)" fill="#F7F4E8"/>
<circle cx="136.106" cy="136.106" r="86.3421" transform="rotate(162.706 136.106 136.106)" fill="url(#paint23_radial)" fill-opacity="0.5"/>
<circle cx="135.861" cy="135.932" r="51.6968" transform="rotate(16.015 135.861 135.932)" fill="url(#paint24_radial)"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="84" y="84" width="104" height="104">
<circle cx="135.861" cy="135.932" r="51.6968" transform="rotate(16.015 135.861 135.932)" fill="url(#paint25_radial)"/>
</mask>
<g mask="url(#mask0)">
<rect x="20.5871" y="77.3223" width="204.435" height="122.26" fill="white"/>
</g>
<mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="84" y="84" width="104" height="104">
<circle cx="135.953" cy="135.953" r="51.6968" transform="rotate(16.015 135.953 135.953)" fill="#B64D4D"/>
</mask>
<g mask="url(#mask1)">
<path d="M0.0449982 158.862L204.492 158.862L204.492 75.0892L0.044998 75.0892L0.0449982 158.862Z" fill="url(#paint26_linear)"/>
<path d="M236 159.57C224.494 150.248 201.483 123.165 189.564 114.551C187.322 112.958 178.59 102.514 176.701 104.343C173.397 107.411 166.258 115.613 161.302 121.572C160.18 122.929 159.472 124.699 157.997 125.348C153.867 127.236 150.858 118.504 147.023 121.1C146.315 121.631 144.25 123.637 143.541 124.227C141.122 126.351 138.467 124.876 136.166 122.752C132.921 119.684 127.493 111.896 125.132 113.784C121.238 116.852 116.341 121.395 113.686 124.817C110.264 129.243 105.602 135.438 102.652 140.335C98.6399 146.826 99.5839 153.493 95.6307 159.983" fill="url(#paint27_linear)"/>
<path d="M145.076 159.273C126.135 135.966 106.074 113.309 82.8268 94.3098C81.7648 93.4837 80.5257 92.5987 79.2276 92.7167C78.4639 92.8662 77.7522 93.2119 77.1625 93.7198C72.3062 97.0402 68.2212 101.367 65.1848 106.406C64.1227 108.176 63.0017 110.182 60.9955 110.772C57.2783 111.834 54.1511 107.114 50.3159 107.232C47.9558 107.35 46.1267 109.12 44.5336 110.831C37.2165 118.882 30.7141 127.637 25.1214 136.969C24.8429 137.56 24.416 138.068 23.8823 138.444C22.2302 139.447 20.3421 137.736 19.0441 136.379C16.4165 133.612 13.2417 131.422 9.72151 129.948C9.1378 129.655 8.48352 129.532 7.8334 129.594C7.12536 129.712 6.65333 130.302 6.1813 130.833C-2.02444 140.273 -8.65854 150.971 -13.4669 162.518L-12.0508 161.102" fill="url(#paint28_linear)"/>
<path d="M123.303 72.8936C125.486 77.1418 128.85 80.918 133.157 83.1012C135.287 84.2454 137.639 84.9168 140.052 85.0695C142.465 85.2223 144.883 84.8528 147.141 83.9862C151.566 82.0981 154.988 77.6728 155.165 72.8936" fill="url(#paint29_linear)"/>
<path d="M173.338 73.4243C173.008 75.1109 173.117 76.854 173.654 78.4865C174.192 80.119 175.139 81.586 176.406 82.7469C177.694 83.8932 179.257 84.686 180.943 85.0479C182.629 85.4099 184.379 85.3286 186.024 84.812C186.11 86.6752 186.603 88.4969 187.47 90.1485C188.336 91.8001 189.555 93.2414 191.039 94.3706C194.043 96.6347 197.807 97.6495 201.542 97.2027C203.767 96.8973 205.946 96.3227 208.032 95.4916C209.349 87.4798 209.785 79.3478 209.33 71.2412" fill="url(#paint30_linear)"/>
<path d="M124.011 84.9277C125.723 91.3001 131.8 95.3123 136.992 93.4832C139.529 97.3775 143.778 99.4426 147.731 98.6755C151.684 97.9085 155.283 94.5453 156.876 89.884C158.883 92.3032 161.951 93.2472 164.606 92.3032C167.261 91.3591 169.503 88.5859 170.211 85.2228" fill="url(#paint31_linear)"/>
<path d="M118.819 74.3682C120.117 78.3804 117.462 83.2187 113.391 84.2808C112.506 87.998 108.435 90.5941 104.717 89.9451C103.537 89.7681 102.062 89.296 101.236 90.1811C100.915 90.5752 100.692 91.0405 100.587 91.5382C100.008 93.2932 99.0463 94.8973 97.7706 96.2343C96.4949 97.5712 94.9376 98.6074 93.2116 99.2676C90.3204 100.33 87.1932 100.271 84.1251 100.094C81.0569 99.9167 77.2807 99.4446 74.7435 97.2615C74.0355 96.6125 73.3274 95.8454 72.3834 95.7274C71.8136 95.725 71.25 95.8458 70.7313 96.0814C66.6535 97.5064 62.2789 97.8641 58.0238 97.1204C53.7687 96.3768 49.7747 94.5567 46.4218 91.8332C44.4747 90.2401 42.8226 88.352 40.7575 86.8769C40.4249 86.6049 40.0071 86.4587 39.5774 86.4639C39.1054 86.5229 38.8104 86.9949 38.5744 87.4079C37.2856 89.2596 35.5179 90.7263 33.4602 91.6513C31.4024 92.5762 29.1321 92.9246 26.8917 92.6592C26.0709 92.5417 25.2427 92.4825 24.4135 92.4822C23.4748 92.6604 22.5627 92.9578 21.6994 93.3673C18.2968 94.6773 14.5912 94.9901 11.0172 94.269C7.44312 93.5479 4.14871 91.8228 1.52019 89.296L0.045105 75.1352L118.819 74.3682Z" fill="url(#paint32_linear)"/>
<path d="M167.91 134.136C164.901 133.074 161.066 133.192 157.761 133.31C157.582 133.32 157.406 133.37 157.249 133.458C157.092 133.546 156.957 133.669 156.854 133.817C156.752 133.965 156.684 134.134 156.658 134.312C156.631 134.49 156.645 134.672 156.699 134.844C157.054 136.017 157.291 137.223 157.407 138.443C157.466 139.092 157.938 141.393 158.528 141.393C160.83 141.216 163.19 138.856 165.314 137.971C166.396 137.512 167.394 136.874 168.264 136.083C168.416 135.944 168.528 135.767 168.591 135.57C168.653 135.373 168.662 135.164 168.618 134.962V134.962C168.58 134.776 168.495 134.602 168.371 134.457C168.247 134.313 168.088 134.202 167.91 134.136Z" fill="#4A0C0E"/>
<path d="M102.003 152.901C102.593 152.311 101.413 150.541 102.003 149.361C103.773 147 106.133 144.05 107.313 141.69C108.966 137.324 108.553 130.834 111.385 126.172C111.168 126.086 110.936 126.044 110.703 126.048C110.47 126.052 110.239 126.102 110.026 126.195C109.812 126.289 109.618 126.423 109.457 126.591C109.295 126.759 109.168 126.958 109.084 127.175C106.133 134.846 101.649 141.1 98.3449 148.535C97.499 150.68 96.8281 152.89 96.3388 155.143C95.8817 156.89 96.0272 158.74 96.7518 160.394C96.8417 158.78 97.381 157.223 98.309 155.899C99.2369 154.575 100.516 153.536 102.003 152.901Z" fill="#D70029"/>
<path d="M131.8 141.866C129.853 142.692 128.201 144.462 126.49 145.701C123.153 147.969 119.493 149.719 115.633 150.893C116.977 145.592 118.834 140.434 121.179 135.493C121.385 135.066 121.45 134.585 121.366 134.119C121.281 133.652 121.05 133.225 120.707 132.897L111.739 126.348L111.385 126.171C108.552 130.832 108.965 137.323 107.313 141.689C106.133 144.049 103.773 146.999 102.003 149.359C101.413 150.539 102.593 152.309 102.003 152.899C100.516 153.535 99.2368 154.573 98.3089 155.897C97.381 157.221 96.8416 158.779 96.7518 160.393C96.9704 160.859 97.2481 161.295 97.5778 161.691C98.3104 162.434 99.1448 163.07 100.056 163.579C105.484 166.942 111.444 170.365 117.757 169.892C122.123 169.538 126.076 167.355 129.912 165.172C131.977 163.992 134.16 162.753 135.34 160.629C135.918 159.357 136.296 158.004 136.461 156.617C137.169 151.963 137.642 147.276 137.877 142.574C136.992 141.984 135.98 141.612 134.924 141.489C133.867 141.366 132.797 141.495 131.8 141.866V141.866Z" fill="#A40005"/>
<path d="M113.745 121.096L118.229 114.724C119.527 112.954 121.061 110.947 123.244 110.829C124.516 110.804 125.768 111.153 126.843 111.833C128.776 112.858 130.596 114.084 132.272 115.491C133.275 116.376 134.219 117.91 133.275 118.795C132.331 119.68 131.859 119.208 131.151 119.09C129.524 118.68 127.942 118.106 126.43 117.379C127.901 118.161 129.22 119.201 130.325 120.447C130.738 120.978 131.21 121.745 130.856 122.394C130.502 123.043 129.322 122.984 128.555 122.689L124.542 121.509C125.953 122.257 127.166 123.328 128.082 124.636C128.437 125.108 128.673 125.698 128.378 126.111C128.082 126.524 127.433 126.347 126.961 126.17L124.365 125.344C124.11 125.215 123.82 125.174 123.539 125.226C123.126 125.344 122.949 125.816 122.831 126.229L121.002 131.481C120.884 131.835 120.707 132.307 120.353 132.425C120.029 132.542 119.674 132.542 119.35 132.425C115.842 131.399 112.784 129.216 110.676 126.229" fill="#4A0C0E"/>
<path d="M143.128 120.152C143.01 122.158 141.181 129.416 141.063 131.422C145.017 132.071 149.855 130.478 153.749 131.068C153.826 131.101 153.91 131.113 153.993 131.102C154.076 131.092 154.155 131.06 154.221 131.009C154.339 130.95 154.339 130.773 154.339 130.655L154.103 121.686" fill="#E86E3C"/>
<path d="M139.411 129.122C141.166 129.131 142.918 129.229 144.663 129.417C145.548 133.547 144.014 141.749 150.15 142.044C146.964 142.398 141.889 143.106 138.88 142.398C138.272 142.257 137.706 141.974 137.228 141.572C136.52 140.864 138.172 138.976 138.172 137.973C138.178 135.118 138.474 132.271 139.057 129.476L139.411 129.122Z" fill="#D70029"/>
<path d="M154.339 130.3C154.868 130.249 155.401 130.352 155.873 130.595C156.262 130.958 156.53 131.432 156.64 131.952C157.289 134.076 158.706 140.744 158.706 143.045C156.404 143.93 151.33 142.101 148.911 142.337C141.831 141.157 145.548 133.545 144.663 129.415C147.866 129.915 151.098 130.21 154.339 130.3V130.3Z" fill="#A40005"/>
<path d="M159.059 141.803C159.531 141.862 162.6 142.688 162.895 142.983C163.19 143.278 167.32 143.396 167.615 149.769C167.91 157.085 170.624 162.868 166.73 171.069C165.845 185.525 168.5 185.761 165.963 199.391L136.284 195.851C138.172 186.705 145.253 150.89 146.669 141.567C150.681 141.921 155.047 141.272 159.059 141.803Z" fill="#A40005"/>
<path d="M148.675 145.758C145.902 158.916 138.231 187.533 136.52 195.616L120.294 194.318C120.235 190.601 124.778 168.828 126.077 162.633C126.077 156.438 128.142 146.761 131.741 141.982C132.154 141.392 139.588 141.097 144.899 141.097C145.475 141.092 146.045 141.217 146.566 141.463C147.088 141.709 147.547 142.07 147.91 142.518C148.273 142.966 148.53 143.49 148.662 144.051C148.795 144.612 148.799 145.195 148.675 145.758V145.758Z" fill="#D70029"/>
<path d="M140.237 90.4764C144.106 88.3299 148.642 87.7209 152.94 88.7708C157.238 89.8207 160.982 92.4523 163.426 96.1407C168.795 104.224 166.14 113.724 157.997 119.093C155.047 121.04 152.097 123.459 148.793 123.459C141.595 114.078 140.827 102.159 140.237 90.4764Z" fill="#E86E3C"/>
<path d="M152.569 113.489C152.253 114.939 152.23 116.439 152.504 117.898C152.778 119.358 153.342 120.748 154.162 121.985C154.487 122.577 155.009 123.036 155.637 123.283L156.227 121.041C156.556 121.425 156.963 121.734 157.421 121.948C157.879 122.162 158.377 122.275 158.883 122.28L160.771 118.976C157.938 117.281 155.406 115.127 153.277 112.604" fill="#4A0C0E"/>
<path d="M148.793 123.459C143.069 123.459 136.284 119.741 131.977 115.434C126.194 109.652 131.446 98.9721 139.057 91.1836C139.406 90.8804 139.806 90.6408 140.237 90.4756C140.827 102.158 141.594 114.077 148.793 123.459Z" fill="#EE8749"/>
<path d="M134.868 98.0303C132.322 98.7775 129.852 99.7653 127.493 100.98C127.198 101.157 126.844 101.393 126.844 101.688C126.852 101.8 126.889 101.907 126.951 102.001C127.013 102.094 127.098 102.169 127.198 102.22C129.041 103.871 131.132 105.225 133.393 106.232" fill="#EE8749"/>
<path d="M131.151 99.7979C131.373 101.863 131.808 103.9 132.449 105.875C133.116 107.856 134.346 109.599 135.989 110.891C136.809 111.517 137.764 111.944 138.778 112.139C139.791 112.333 140.836 112.29 141.83 112.012C142.252 111.891 142.637 111.668 142.951 111.363C141.844 111.383 140.745 111.168 139.728 110.73C138.71 110.293 137.798 109.643 137.051 108.825C135.566 107.181 134.512 105.195 133.983 103.043" fill="#E86E3C"/>
<path d="M150.917 101.271C149.56 103.395 145.076 111.006 143.837 113.248C143.601 113.661 143.365 114.192 143.66 114.605L144.132 114.959L146.02 115.962C146.138 116.021 146.315 116.139 146.433 116.08C146.551 116.021 146.787 115.903 146.905 115.785C147.023 115.667 147.377 115.608 147.495 115.785C148.448 114.675 149.494 113.649 150.622 112.717" fill="#4A0C0E"/>
<path d="M151.212 88.3488C157.997 90.2369 164.783 94.9572 166.022 102.392C167.261 109.826 163.839 115.608 158.646 120.27L156.05 118.972C154.281 118.161 152.633 117.109 151.153 115.844C149.737 114.487 148.734 112.658 148.852 110.711C148.97 108.764 150.976 105.578 149.855 103.277C149.387 102.472 148.741 101.786 147.967 101.271C145.902 99.6774 143.718 98.2023 141.476 96.7273C140.532 96.0782 138.349 95.1932 136.874 94.2491C140.237 89.7058 145.43 87.1687 151.212 88.3488Z" fill="#A40005"/>
<path d="M145.194 84.6918C146.846 84.1607 149.324 84.2788 151.035 84.2788C152.746 84.2788 155.519 84.7508 157.053 85.5178C165.609 89.3531 172.984 98.3216 169.798 107.644C168.323 111.951 165.963 117.616 162.6 120.684C162.288 121.035 161.876 121.282 161.42 121.392C160.944 121.395 160.476 121.273 160.063 121.038L158.647 120.271C163.839 115.61 167.261 109.119 166.022 102.393C164.783 95.6664 157.997 90.2381 151.212 88.35C145.43 87.1699 140.237 89.7071 136.874 94.2503C136.264 93.9242 135.738 93.4594 135.34 92.8933C133.452 89.5891 143.129 85.3408 145.194 84.6918Z" fill="#D70029"/>
<path d="M150.209 101.567C156.582 102.983 164.016 104.931 170.27 106.996C170.152 108.884 169.149 110.654 168.323 112.365L149.973 107.35" fill="#DACCA8"/>
<path opacity="0.62" d="M139.588 94.8417L143.011 97.0838L146.669 99.444L148.675 100.742L149.973 101.686C150.312 101.995 150.575 102.379 150.74 102.807C150.957 103.733 150.875 104.704 150.504 105.58C150.325 106.453 149.963 107.277 149.442 107.999C148.738 108.771 147.872 109.375 146.905 109.77C144.545 110.832 141.83 111.835 139.293 111.127C138.491 110.889 137.748 110.487 137.11 109.947C136.138 109.156 135.369 108.145 134.868 106.996C134.523 106.185 134.285 105.332 134.16 104.459C134.101 103.869 134.101 103.22 134.101 102.63C134.071 102.077 134.012 101.525 133.924 100.978C133.811 100.235 133.528 99.5288 133.098 98.9129C132.843 98.5755 132.522 98.2943 132.154 98.0869L135.458 92.1865L139.588 94.8417Z" fill="#FBB03B"/>
<path d="M132.567 97.3184C132.939 97.4737 133.274 97.7068 133.548 98.0019C133.823 98.2969 134.032 98.6473 134.16 99.0295C134.373 99.799 134.492 100.591 134.514 101.39L134.75 107.113C134.468 106.913 134.229 106.658 134.046 106.365C133.864 106.071 133.742 105.743 133.688 105.402L131.859 99.3835C131.703 98.9899 131.662 98.5603 131.741 98.1444" fill="#071E30"/>
<path d="M140.178 98.1456C139.885 99.9118 139.013 101.531 137.7 102.748C137.464 102.984 136.992 103.22 136.815 102.925C136.782 102.883 136.763 102.831 136.763 102.777C136.763 102.724 136.782 102.672 136.815 102.63C136.911 101.654 137.233 100.715 137.756 99.885C138.278 99.0554 138.986 98.3589 139.824 97.8506" fill="#071E30"/>
<path d="M143.601 99.8545C143.011 102.923 142.598 106.05 142.303 109.177" stroke="white" stroke-width="2.77" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M146.787 101.331C146.445 102.863 146.248 104.424 146.197 105.992" stroke="white" stroke-width="2.77" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M147.967 142.22C153.867 139.387 162.305 139.859 167.969 134.431C168.854 133.605 169.503 134.962 170.152 135.965C171.627 138.207 172.099 139.151 172.63 140.803C174.135 145.016 174.6 149.53 173.987 153.961C171.686 150.539 168.736 146.822 165.137 144.757C163.485 143.813 161.655 143.046 159.826 143.282C157.997 143.518 155.991 145.052 154.398 146.468" fill="#A40005"/>
<path d="M176.642 174.555L181.245 173.198C180.95 164.524 176.76 156.441 171.332 149.714C168.854 146.705 166.022 143.755 162.304 142.398C159.531 141.336 156.463 141.218 153.454 141.454C168.854 145.525 172.63 160.217 176.642 174.555Z" fill="#D70029"/>
<path d="M176.642 174.557C172.63 160.219 168.854 145.527 153.454 141.456L167.438 176.091C170.537 175.791 173.613 175.278 176.642 174.557V174.557Z" fill="#A40005"/>
<path d="M144.072 129.302L142.479 129.184C140.933 131.519 140.188 134.294 140.355 137.09C140.414 138.506 140.709 139.922 140.65 141.338C140.591 142.754 139.824 145.233 139.234 147.18C138.635 149.429 138.181 151.716 137.877 154.024C136.225 164.822 134.573 175.796 133.747 186.653C133.57 189.485 133.393 192.553 132.508 195.268C133.039 195.74 134.278 195.622 135.045 195.445C136.284 178.57 137.523 161.577 141.417 145.115C141.797 143.666 142.034 142.184 142.125 140.689C142.125 139.863 142.066 138.978 142.007 138.152C141.945 135.076 142.655 132.033 144.072 129.302V129.302Z" fill="#DACCA8"/>
<path opacity="0.66" d="M158.705 138.918C158.231 139.371 157.637 139.678 156.994 139.803C155.021 140.376 153.01 140.81 150.976 141.101C151.566 140.098 152.569 139.508 153.513 138.8C154.457 138.092 156.463 135.555 158.174 134.198C158.233 134.139 158.292 134.08 158.351 134.139C158.41 134.198 158.292 134.257 158.292 134.198" fill="#4A0C0E"/>
<path d="M87.311 286.485L178.648 109.888C182.72 101.981 194.343 93.8977 195.936 92.7177C197.53 91.5376 198.12 88.8824 195.7 88.1744C194.284 87.8204 181.953 92.8947 174.164 107.941L88.7861 272.973L77.5164 294.805L81.5877 297.519L87.311 286.485Z" fill="#4A0C0E"/>
<path d="M179.947 110.712C180.6 109.406 181.371 108.162 182.248 106.995C181.339 105.527 180.204 104.213 178.884 103.101C177.581 104.885 176.436 106.781 175.462 108.765L163.131 132.543C165.786 130.832 168.5 129.18 171.214 127.587L179.947 110.712Z" fill="#4A0C0E"/>
<path d="M171.214 127.59C168.5 129.183 165.786 130.835 163.131 132.546L157.997 142.4C161.121 140.315 164.134 138.07 167.025 135.673L171.214 127.59Z" fill="#4A0C0E"/>
<path d="M167.025 135.671C164.134 138.068 161.121 140.313 157.997 142.397L154.044 150.127C156.876 148.298 159.649 146.41 162.423 144.521L167.025 135.671Z" fill="#4A0C0E"/>
<path d="M162.423 144.522C159.649 146.411 156.876 148.299 154.044 150.128L148.203 161.456L155.932 157.09L162.423 144.522Z" fill="#4A0C0E"/>
<path d="M88.5499 287.31L155.932 157.089L148.202 161.455L90.025 273.857L78.7554 295.629L82.8856 298.343L88.5499 287.31Z" fill="#4A0C0E"/>
<path d="M195.17 94.3689C196.763 93.1889 197.943 90.2387 196.94 89.0586C195.937 87.8786 186.378 92.5398 178.885 103.101C180.204 104.214 181.339 105.528 182.248 106.996C186.85 100.682 193.812 95.372 195.17 94.3689Z" fill="#4A0C0E"/>
<path d="M84.6561 284.891L173.397 106.936C177.351 99.03 176.997 84.8692 177.056 82.8631C177.115 80.857 178.885 78.8508 180.832 80.3849C182.779 81.919 185.139 94.2507 177.587 109.415L94.6867 275.745L83.7121 297.695L79.1098 295.984L84.6561 284.891Z" fill="#47060B"/>
<path d="M171.981 106.407C172.593 105.083 173.125 103.724 173.574 102.336C175.296 102.206 177.028 102.346 178.708 102.749C178.029 104.86 177.181 106.912 176.17 108.885L164.193 132.9C164.016 129.714 163.78 126.527 163.485 123.4L171.981 106.407Z" fill="#F2A027"/>
<path d="M163.485 123.398C163.78 126.526 164.016 129.712 164.193 132.898L159.295 142.811C159.177 139.093 159.177 135.376 159.413 131.541L163.485 123.398Z" fill="#DACCA8"/>
<path d="M159.413 131.542C159.177 135.377 159.177 139.094 159.295 142.812L155.401 150.6C155.224 147.237 155.106 143.874 154.988 140.511L159.413 131.542Z" fill="#4D9E98"/>
<path d="M154.988 140.509C155.106 143.872 155.224 147.235 155.401 150.598L149.737 161.986L148.675 153.136L154.988 140.509Z" fill="#DACCA8"/>
<path d="M83.24 284.361L148.675 153.138L149.737 161.988L93.2706 275.216L82.296 297.165L77.6937 295.454L83.24 284.361Z" fill="#F2A027"/>
<path d="M176.17 84.4587C176.17 82.4526 177.822 79.7974 179.416 79.8564C181.009 79.9154 182.779 90.4771 178.708 102.75C177.028 102.347 175.296 102.207 173.574 102.337C175.993 94.9023 176.111 86.1698 176.17 84.4587Z" fill="#A40005"/>
<path d="M38.9872 166.886C39.9313 163.699 40.8754 162.106 43.1765 160.159C47.1297 156.796 85.718 131.955 95.5126 127.058C97.7547 125.937 100.823 125.111 102.947 125.347" stroke="#A9DBD8" stroke-width="2.62" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M100.056 117.381C99.2558 120.158 97.6049 122.613 95.3354 124.402C91.4412 127.706 53.443 152.134 43.5894 156.913C42.567 157.482 41.4765 157.918 40.3442 158.211C39.0312 158.539 37.6584 158.544 36.3431 158.225C35.0278 157.907 33.8093 157.274 32.7917 156.382" stroke="#D6FCFB" stroke-width="2.62" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M79.4044 142.281C82.3546 147.12 85.3047 152.017 87.3108 157.268C89.317 162.519 90.379 168.361 89.376 173.966C89.33 174.239 89.2297 174.5 89.081 174.733L88.3729 174.91L83.1216 175.677C82.2365 170.957 81.2925 166.06 78.6373 162.047" fill="#989898"/>
</g>
<g filter="url(#filter20_d)">
<path d="M135.648 220L131 239.396L140.005 239.396L135.648 220Z" fill="url(#paint33_linear)"/>
</g>
<defs>
<filter id="filter0_d" x="217.162" y="173.812" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter1_d" x="201.303" y="197.037" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter2_d" x="179.425" y="214.415" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter3_d" x="153.121" y="224.52" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter4_d" x="123.54" y="226.093" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter5_d" x="95.3063" y="219.404" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter6_d" x="72.1248" y="203.822" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter7_d" x="53.7037" y="182.17" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter8_d" x="42.3647" y="154.729" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter9_d" x="40.6752" y="124.311" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter10_d" x="48.8007" y="95.2295" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter11_d" x="66.2546" y="70.3359" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter12_d" x="88.5088" y="53.1338" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter13_d" x="115.305" y="43.249" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter14_d" x="144.175" y="41.9482" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter15_d" x="171.51" y="49.3955" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter16_d" x="196.874" y="66.5752" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter17_d" x="213.979" y="87.7422" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter18_d" x="224.137" y="115.249" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter19_d" x="225.656" y="143.627" width="5.40409" height="5.40409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter20_d" x="127" y="218" width="15.0052" height="25.3959" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dx="-1" dy="1"/>
<feGaussianBlur stdDeviation="1.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(135.71 136.101) rotate(82.4715) scale(97.3205 97.9035)">
<stop offset="0.958647" stop-color="#454545" stop-opacity="0"/>
<stop offset="0.989308" stop-color="#0E0D06" stop-opacity="0.4"/>
</radialGradient>
<radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(136.245 136.112) rotate(82.4715) scale(96.719 97.2984)">
<stop offset="0.912717" stop-color="#454545"/>
<stop offset="0.938496" stop-color="#454545" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(219.864 175.514) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint3_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(204.005 198.739) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint4_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.127 216.117) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint5_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.823 226.221) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint6_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(126.242 227.795) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint7_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(98.0084 221.106) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint8_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(74.8268 205.524) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint9_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(56.4058 183.872) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint10_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(45.0668 156.431) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint11_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(43.3772 126.012) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint12_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.5027 96.9313) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint13_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(68.9566 72.0377) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint14_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.2109 54.8356) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint15_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(118.007 44.9508) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint16_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(146.877 43.65) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint17_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174.212 51.0973) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint18_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(199.576 68.277) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint19_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(216.681 89.444) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint20_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(226.839 116.951) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint21_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(228.358 145.329) rotate(90) scale(1.70162)">
<stop offset="0.291667" stop-color="#B6B2A0"/>
<stop offset="1" stop-color="#AEAB98"/>
</radialGradient>
<radialGradient id="paint22_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(136.25 136.251) rotate(82.4715) scale(89.1544 89.6885)">
<stop offset="0.598057" stop-color="#4A483B"/>
<stop offset="0.779404" stop-color="#454545" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint23_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(136.106 136.106) rotate(81.8098) scale(84.9146 85.4233)">
<stop offset="0.61146" stop-color="#454545" stop-opacity="0.13"/>
<stop offset="0.674448" stop-color="#454545" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint24_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(135.861 135.932) rotate(90) scale(51.6968)">
<stop offset="0.75" stop-color="white"/>
<stop offset="1" stop-color="#F5F4EE"/>
</radialGradient>
<radialGradient id="paint25_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(135.861 135.932) rotate(90) scale(51.6968)">
<stop offset="0.75" stop-color="white"/>
<stop offset="1" stop-color="#F5F4EE"/>
</radialGradient>
<linearGradient id="paint26_linear" x1="92.4563" y1="177.773" x2="109.137" y2="74.2749" gradientUnits="userSpaceOnUse">
<stop stop-color="#82A8A9"/>
<stop offset="1" stop-color="#AFC7C8"/>
</linearGradient>
<linearGradient id="paint27_linear" x1="38177.8" y1="9418.41" x2="38615.4" y2="6120.11" gradientUnits="userSpaceOnUse">
<stop stop-color="#82A8A9"/>
<stop offset="1" stop-color="#AFC7C8"/>
</linearGradient>
<linearGradient id="paint28_linear" x1="14791.2" y1="12471" x2="15640.2" y2="8585.04" gradientUnits="userSpaceOnUse">
<stop stop-color="#82A8A9"/>
<stop offset="1" stop-color="#AFC7C8"/>
</linearGradient>
<linearGradient id="paint29_linear" x1="7527.05" y1="328.073" x2="7537.64" y2="-1.71538" gradientUnits="userSpaceOnUse">
<stop stop-color="#82A8A9"/>
<stop offset="1" stop-color="#AFC7C8"/>
</linearGradient>
<linearGradient id="paint30_linear" x1="11783.6" y1="1276.21" x2="11867.9" y2="-126.403" gradientUnits="userSpaceOnUse">
<stop stop-color="#82A8A9"/>
<stop offset="1" stop-color="#AFC7C8"/>
</linearGradient>
<linearGradient id="paint31_linear" x1="144.403" y1="104.346" x2="146.598" y2="88.7512" gradientUnits="userSpaceOnUse">
<stop stop-color="#82A8A9" stop-opacity="0"/>
<stop offset="0.88" stop-color="#AFC7C8"/>
</linearGradient>
<linearGradient id="paint32_linear" x1="11365.1" y1="1370.85" x2="11392.5" y2="-134.234" gradientUnits="userSpaceOnUse">
<stop stop-color="#82A8A9"/>
<stop offset="1" stop-color="#AFC7C8"/>
</linearGradient>
<linearGradient id="paint33_linear" x1="139.313" y1="232.065" x2="129.615" y2="232.065" gradientUnits="userSpaceOnUse">
<stop stop-color="#494541"/>
<stop offset="1" stop-color="#ABA399"/>
</linearGradient>
</defs>
</svg>
)