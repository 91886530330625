import React from 'react'

export const BASPOLogoDe = () => (
  <svg
    id="baf5abb5-f59c-410b-9a81-081af1ae862e"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    width="55.9mm"
    height="22.9mm"
    viewBox="0 0 158.5 64.9"
  >
    <path
      d="M31,7.5a2,2,0,0,0,1.1.3c.7,0,1.2-.3,1.2-1.1s-2.4-1.1-2.4-2.5a1.5,1.5,0,0,1,1.7-1.4l1,.2v.5a1.3,1.3,0,0,0-.9-.2c-.6,0-1.2.2-1.2.9s2.4,1,2.4,2.6-.9,1.4-1.7,1.4L30.9,8Z"
      fill="#181716"
    />
    <path
      d="M37.9,4.8l-.8-.2a1.5,1.5,0,0,0-1.5,1.6A1.5,1.5,0,0,0,37,7.8h.9v.4H37a1.8,1.8,0,0,1-1.9-2,1.9,1.9,0,0,1,1.9-2,1.4,1.4,0,0,1,.9.2Z"
      fill="#181716"
    />
    <path
      d="M39.3,2.5h.5V5h0A1.3,1.3,0,0,1,41,4.2c1,0,1.3.7,1.3,1.7V8.1h-.5V5.9c0-.7-.1-1.3-.9-1.3s-1.1.8-1.1,1.5v2h-.5Z"
      fill="#181716"
    />
    <path
      d="M48,8.1h-.5L46.4,4.8h0l-1,3.3h-.6L43.6,4.3h.5l1,3.3h0l1.1-3.3h.5l1,3.3h.1l1-3.3h.5Z"
      fill="#181716"
    />
    <path
      d="M53.1,8a2.2,2.2,0,0,1-1.1.2c-1.3,0-1.8-.8-1.8-2a1.8,1.8,0,0,1,1.6-2c1.1,0,1.6.9,1.6,1.9v.3H50.7A1.3,1.3,0,0,0,52,7.8a1.8,1.8,0,0,0,1.1-.3Zm-.3-2c0-.7-.2-1.4-.9-1.4A1.3,1.3,0,0,0,50.7,6Z"
      fill="#181716"
    />
    <path d="M55.3,3.3h-.4V2.7h.4Zm-.4,1h.4V8.1h-.4Z" fill="#181716" />
    <path d="M56.7,7.8l2.4-3.1H56.8V4.3h2.8v.4l-2.3,3h2.3v.4H56.7Z" fill="#181716" />
    <path
      d="M63.6,8a2.2,2.2,0,0,1-1.1.2c-1.3,0-1.8-.8-1.8-2a1.8,1.8,0,0,1,1.6-2c1.1,0,1.6.9,1.6,1.9v.3H61.3c0,.8.4,1.4,1.2,1.4a1.8,1.8,0,0,0,1.1-.3Zm-.2-2c0-.7-.3-1.4-1-1.4A1.3,1.3,0,0,0,61.3,6Z"
      fill="#181716"
    />
    <path
      d="M65.4,5.2a2.5,2.5,0,0,0-.1-.9h.5v.8h0c.2-.5.5-.9,1-.9h.3v.5h-.3c-.8,0-1,.9-1,1.6V8.1h-.4Z"
      fill="#181716"
    />
    <path d="M68.9,3.3h-.5V2.7h.5Zm-.5,1h.5V8.1h-.5Z" fill="#181716" />
    <path
      d="M70.4,7.6l1,.2c.4,0,.8-.2.8-.6s-1.8-.8-1.8-1.9.6-1.1,1.3-1.1l.9.2v.4l-.9-.2c-.5,0-.8.2-.8.7s1.9.6,1.9,1.9-.8,1-1.4,1h-1Z"
      fill="#181716"
    />
    <path
      d="M76.7,4.8l-.8-.2a1.4,1.4,0,0,0-1.5,1.6,1.5,1.5,0,0,0,1.4,1.6h.9v.4h-1a1.8,1.8,0,0,1-1.8-2,1.9,1.9,0,0,1,1.9-2,1.4,1.4,0,0,1,.9.2Z"
      fill="#181716"
    />
    <path
      d="M78.1,2.5h.5V5h0a1.2,1.2,0,0,1,1.2-.8c1,0,1.3.7,1.3,1.7V8.1h-.5V5.9c0-.7-.1-1.3-.9-1.3s-1.1.8-1.1,1.5v2h-.5Z"
      fill="#181716"
    />
    <path
      d="M85.4,8a2,2,0,0,1-1.1.2c-1.2,0-1.7-.8-1.7-2s.6-2,1.6-2,1.5.9,1.5,1.9v.3H83.1a1.2,1.2,0,0,0,1.3,1.4,1.7,1.7,0,0,0,1-.3Zm-.2-2c0-.7-.3-1.4-1-1.4A1.3,1.3,0,0,0,83.1,6Z"
      fill="#181716"
    />
    <path d="M89.7,2.9h2.6v.5H90.2V5.2h2v.5h-2v2h2.2v.4H89.7Z" fill="#181716" />
    <path d="M94.5,3.3H94V2.7h.5Zm-.5,1h.5V8.1H94Z" fill="#181716" />
    <path
      d="M99.3,8.1h-.5V7.5h0a1.3,1.3,0,0,1-1.2.7c-1.1,0-1.6-.8-1.6-2s.5-2,1.6-2a1.4,1.4,0,0,1,1.2.8h0V2.5h.5Zm-1.6-.3c.8,0,1.1-.9,1.1-1.6s-.3-1.6-1.1-1.6-1.2.9-1.2,1.6S96.8,7.8,97.7,7.8Z"
      fill="#181716"
    />
    <path
      d="M104,7.8c0,1.1-.5,2-1.8,2l-1.1-.2V9.1a3.5,3.5,0,0,0,1.1.3c1.3,0,1.4-.9,1.4-2h-.1a1,1,0,0,1-1.1.7c-1.2,0-1.6-1-1.6-1.9s.4-2,1.5-2a1.1,1.1,0,0,1,1.2.6h.1V4.3h.4Zm-1.6-.1c.8,0,1.2-.8,1.2-1.5s-.3-1.6-1.2-1.6-1.1.9-1.1,1.6S101.6,7.7,102.4,7.7Z"
      fill="#181716"
    />
    <path
      d="M108.3,8a2,2,0,0,1-1.1.2c-1.3,0-1.7-.8-1.7-2s.6-2,1.6-2,1.5.9,1.5,1.9v.3H106c0,.8.4,1.4,1.2,1.4a1.8,1.8,0,0,0,1.1-.3Zm-.2-2c0-.7-.3-1.4-1-1.4A1.3,1.3,0,0,0,106,6Z"
      fill="#181716"
    />
    <path
      d="M110.1,5.2V4.3h.4V5h0a1.4,1.4,0,0,1,1.3-.8c.9,0,1.3.7,1.3,1.5V8.1h-.5V5.8c0-.7-.2-1.2-.9-1.2s-1.1.8-1.1,1.4V8.1h-.5Z"
      fill="#181716"
    />
    <path
      d="M116.3,4.2a1.8,1.8,0,0,1,1.8,2c0,1.1-.5,2-1.8,2s-1.7-.9-1.7-2S115.1,4.2,116.3,4.2Zm0,3.6c.9,0,1.3-.7,1.3-1.6s-.4-1.6-1.3-1.6-1.2.7-1.2,1.6S115.5,7.8,116.3,7.8Z"
      fill="#181716"
    />
    <path
      d="M119.3,7.6l1,.2c.5,0,.9-.2.9-.6s-1.9-.8-1.9-1.9.6-1.1,1.3-1.1l.9.2v.4l-.9-.2c-.5,0-.8.2-.8.7s1.9.6,1.9,1.9-.8,1-1.4,1h-1Z"
      fill="#181716"
    />
    <path
      d="M122.8,7.6l1,.2c.5,0,.9-.2.9-.6s-1.9-.8-1.9-1.9.6-1.1,1.3-1.1l.9.2v.4l-.9-.2c-.5,0-.8.2-.8.7s1.9.6,1.9,1.9-.8,1-1.4,1h-1Z"
      fill="#181716"
    />
    <path
      d="M129.2,8a2.2,2.2,0,0,1-1.1.2c-1.3,0-1.8-.8-1.8-2a1.8,1.8,0,0,1,1.6-2c1.1,0,1.6.9,1.6,1.9v.3h-2.7a1.3,1.3,0,0,0,1.3,1.4,1.8,1.8,0,0,0,1.1-.3Zm-.3-2c0-.7-.2-1.4-.9-1.4A1.3,1.3,0,0,0,126.8,6Z"
      fill="#181716"
    />
    <path
      d="M131,5.2a2.8,2.8,0,0,0-.1-.9h.5V5h0a1.3,1.3,0,0,1,1.2-.8c1,0,1.3.7,1.3,1.5V8.1h-.4V5.8c0-.7-.3-1.2-.9-1.2A1.3,1.3,0,0,0,131.4,6V8.1H131Z"
      fill="#181716"
    />
    <path
      d="M135.4,7.6l1,.2c.5,0,.9-.2.9-.6s-1.9-.8-1.9-1.9.6-1.1,1.3-1.1l.9.2v.4l-.9-.2c-.5,0-.8.2-.8.7s1.9.6,1.9,1.9-.8,1-1.4,1h-1Z"
      fill="#181716"
    />
    <path
      d="M141.7,4.8l-.8-.2a1.5,1.5,0,0,0-1.5,1.6,1.5,1.5,0,0,0,1.4,1.6h.9v.4h-.9a1.8,1.8,0,0,1-1.9-2,1.9,1.9,0,0,1,1.9-2,1.4,1.4,0,0,1,.9.2Z"
      fill="#181716"
    />
    <path
      d="M143.1,2.5h.5V5h0a1.2,1.2,0,0,1,1.2-.8c1,0,1.3.7,1.3,1.7V8.1h-.5V5.9c0-.7-.1-1.3-.9-1.3s-1.1.8-1.1,1.5v2h-.5Z"
      fill="#181716"
    />
    <path
      d="M150.1,7.5h0a1.3,1.3,0,0,1-1.2.7c-1.1,0-1.3-.7-1.3-1S149,5.8,150,5.8h0V5.6c0-.6-.2-1-.8-1A1.9,1.9,0,0,0,148,5V4.5l1.2-.3c.9,0,1.3.5,1.3,1.5V7.3a1.7,1.7,0,0,0,.1.8h-.5ZM150,6.2h-.1c-.8,0-1.8.1-1.8.9a.7.7,0,0,0,.8.7c1.1,0,1.1-.9,1.1-1.3Z"
      fill="#181716"
    />
    <path
      d="M152.5,4.7h-.7V4.3h.7V4c0-.8.1-1.6,1.1-1.6h.4v.4h-.4c-.6,0-.6.7-.6,1.2v.3h.9v.4H153V8.1h-.5Z"
      fill="#181716"
    />
    <path
      d="M157,4.7h-.9V7.2c0,.3.1.6.5.6h.4v.4h-.6c-.9,0-.9-.5-.9-1.2V4.7h-.7V4.3h.7V3.4l.5-.2V4.3h.9Z"
      fill="#181716"
    />
    <path
      d="M34.7,13.8l-1.2-.2a2.1,2.1,0,0,0-2.1,2.3,2,2,0,0,0,2.1,2.2,2.5,2.5,0,0,0,1.2-.2v.5l-1.2.2a2.4,2.4,0,0,1-2.6-2.7,2.5,2.5,0,0,1,2.6-2.7,2.5,2.5,0,0,1,1.2.2Z"
      fill="#181716"
    />
    <path
      d="M37.9,14.6c1.3,0,1.8.9,1.8,2a1.8,1.8,0,0,1-1.8,2,1.8,1.8,0,0,1-1.8-2A1.8,1.8,0,0,1,37.9,14.6Zm0,3.6c.8,0,1.3-.7,1.3-1.6S38.7,15,37.9,15s-1.3.7-1.3,1.6S37.1,18.2,37.9,18.2Z"
      fill="#181716"
    />
    <path
      d="M41.2,15.6a2.8,2.8,0,0,0-.1-.9h.5v.7h0a1.3,1.3,0,0,1,1.3-.8c.9,0,1.3.6,1.3,1.5v2.4h-.5V16.2c0-.7-.3-1.2-.9-1.2s-1.1.7-1.1,1.4v2.1h-.5Z"
      fill="#181716"
    />
    <path
      d="M46.3,15.1h-.8v-.4h.8v-.3c0-.8,0-1.6,1-1.6h.4v.4h-.4c-.6,0-.6.7-.6,1.1v.4h.9v.4h-.9v3.4h-.4Z"
      fill="#181716"
    />
    <path
      d="M51.6,18.4l-1.1.2c-1.3,0-1.8-.9-1.8-2s.7-2,1.6-2,1.6.9,1.6,1.9v.2H49.3c0,.8.4,1.5,1.2,1.5a2.9,2.9,0,0,0,1.1-.3Zm-.2-2.1c0-.7-.3-1.3-1-1.3a1.2,1.2,0,0,0-1.1,1.3Zm-.8-3.1h.6l-1,1.1h-.3Z"
      fill="#181716"
    />
    <path
      d="M56.4,18.5h-.5v-.6h0a1.2,1.2,0,0,1-1.2.7c-1,0-1.6-.9-1.6-2s.5-2,1.6-2a1.2,1.2,0,0,1,1.2.7h0V12.9h.5Zm-1.6-.3c.8,0,1.1-.9,1.1-1.6S55.6,15,54.8,15s-1.1.8-1.1,1.6S53.9,18.2,54.8,18.2Z"
      fill="#181716"
    />
    <path
      d="M60.7,18.4l-1.1.2c-1.3,0-1.8-.9-1.8-2s.7-2,1.6-2,1.6.9,1.6,1.9v.2H58.3a1.4,1.4,0,0,0,1.3,1.5,2.9,2.9,0,0,0,1.1-.3Zm-.3-2.1c0-.7-.2-1.3-.9-1.3a1.3,1.3,0,0,0-1.2,1.3Zm-.7-3.1h.6l-1,1.1H59Z"
      fill="#181716"
    />
    <path
      d="M62.5,15.5a1.7,1.7,0,0,0-.1-.8h.5v.7h0a1.2,1.2,0,0,1,1-.8h.3v.5h-.3c-.8,0-1,.8-1,1.5v1.9h-.4Z"
      fill="#181716"
    />
    <path
      d="M67.7,17.9h0a1.4,1.4,0,0,1-1.2.7c-1.1,0-1.3-.7-1.3-1.1s1.4-1.3,2.4-1.3h.1V16c0-.7-.2-1-.9-1a2,2,0,0,0-1.1.3v-.5l1.1-.2c1,0,1.4.4,1.4,1.4v2.5h-.5Zm0-1.3h-.1c-.9,0-1.8.1-1.8.9s.3.7.8.7,1.1-1,1.1-1.4Z"
      fill="#181716"
    />
    <path
      d="M71.5,15.1h-.8v2.5c0,.3.1.6.5.6h.4v.4h-.5c-.9,0-.9-.6-.9-1.3V15.1h-.8v-.4h.8v-1h.5v1.1h.8Z"
      fill="#181716"
    />
    <path d="M73.4,13.7H73V13h.4Zm-.4,1h.4v3.8H73Z" fill="#181716" />
    <path
      d="M76.7,14.6a1.8,1.8,0,0,1,1.8,2,1.8,1.8,0,1,1-3.6,0A1.8,1.8,0,0,1,76.7,14.6Zm0,3.6c.8,0,1.3-.7,1.3-1.6S77.5,15,76.7,15s-1.3.7-1.3,1.6S75.9,18.2,76.7,18.2Z"
      fill="#181716"
    />
    <path
      d="M80,15.6a2.8,2.8,0,0,0-.1-.9h.5v.7h0a1.2,1.2,0,0,1,1.2-.8c1,0,1.4.6,1.4,1.5v2.4h-.5V16.2c0-.7-.3-1.2-.9-1.2s-1.2.7-1.2,1.4v2.1H80Z"
      fill="#181716"
    />
    <path
      d="M87,17.9a1.9,1.9,0,0,0,1,.3.8.8,0,0,0,.9-.7c0-.9-1.9-.8-1.9-1.9s.6-1,1.3-1a1.6,1.6,0,0,1,.9.2v.4l-.9-.2c-.5,0-.8.1-.8.6s1.9.6,1.9,1.9a1.2,1.2,0,0,1-1.3,1.1L87,18.4Z"
      fill="#181716"
    />
    <path
      d="M93.8,17.6v.9h-.4v-.7h0a1.3,1.3,0,0,1-1.3.8c-.9,0-1.3-.6-1.3-1.5V14.7h.5V17c0,.7.3,1.2.9,1.2s1.1-.8,1.1-1.4V14.7h.5Z"
      fill="#181716"
    />
    <path d="M96.1,13.7h-.5V13h.5Zm-.5,1h.5v3.8h-.5Z" fill="#181716" />
    <path
      d="M97.5,17.9a1.9,1.9,0,0,0,1,.3.8.8,0,0,0,.9-.7c0-.9-1.9-.8-1.9-1.9s.7-1,1.3-1a1.6,1.6,0,0,1,.9.2v.4l-.9-.2c-.4,0-.8.1-.8.6s1.9.6,1.9,1.9a1.2,1.2,0,0,1-1.3,1.1l-1.1-.2Z"
      fill="#181716"
    />
    <path
      d="M101,17.9a1.9,1.9,0,0,0,1,.3.8.8,0,0,0,.9-.7c0-.9-1.8-.8-1.8-1.9s.6-1,1.2-1a1.6,1.6,0,0,1,.9.2v.4l-.9-.2c-.4,0-.8.1-.8.6s1.9.6,1.9,1.9a1.2,1.2,0,0,1-1.3,1.1l-1.1-.2Z"
      fill="#181716"
    />
    <path
      d="M107.4,18.4l-1.1.2c-1.3,0-1.7-.9-1.7-2s.6-2,1.6-2,1.5.9,1.5,1.9v.2h-2.6c0,.8.4,1.5,1.2,1.5a2.9,2.9,0,0,0,1.1-.3Zm-.2-2.1c0-.7-.3-1.3-1-1.3a1.2,1.2,0,0,0-1.1,1.3Z"
      fill="#181716"
    />
    <path
      d="M34.7,24.2a2.5,2.5,0,0,0-1.2-.2,2,2,0,0,0-2.1,2.2c0,1.4.7,2.3,2.1,2.3l1.2-.2v.4a2.5,2.5,0,0,1-1.2.2,2.4,2.4,0,0,1-2.6-2.7,2.5,2.5,0,0,1,2.6-2.7l1.2.2Z"
      fill="#181716"
    />
    <path
      d="M37.9,24.9a1.8,1.8,0,0,1,1.8,2c0,1.1-.5,2-1.8,2a1.8,1.8,0,0,1-1.8-2A1.8,1.8,0,0,1,37.9,24.9Zm0,3.6c.8,0,1.3-.7,1.3-1.6s-.5-1.6-1.3-1.6-1.3.7-1.3,1.6S37.1,28.5,37.9,28.5Z"
      fill="#181716"
    />
    <path
      d="M41.2,25.9a2.8,2.8,0,0,0-.1-.9h.5v.7h0a1.4,1.4,0,0,1,1.3-.8c.9,0,1.3.7,1.3,1.5v2.4h-.5V26.5c0-.7-.3-1.2-.9-1.2s-1.1.8-1.1,1.4v2.1h-.5Z"
      fill="#181716"
    />
    <path
      d="M46.3,25.4h-.8V25h.8v-.3c0-.8,0-1.6,1-1.6h.4v.4h-.4c-.6,0-.6.7-.6,1.2V25h.9v.4h-.9v3.4h-.4Z"
      fill="#181716"
    />
    <path
      d="M51.6,28.7a2.2,2.2,0,0,1-1.1.2c-1.3,0-1.8-.8-1.8-2a1.8,1.8,0,0,1,1.6-2c1.1,0,1.6.9,1.6,1.9v.3H49.3c0,.8.4,1.4,1.2,1.4a1.8,1.8,0,0,0,1.1-.3Zm-.2-2c0-.7-.3-1.4-1-1.4a1.3,1.3,0,0,0-1.1,1.4Z"
      fill="#181716"
    />
    <path
      d="M56.4,28.8h-.5v-.6h0a1.2,1.2,0,0,1-1.2.7c-1,0-1.6-.8-1.6-2s.5-2,1.6-2a1.3,1.3,0,0,1,1.2.8h0V23.2h.5Zm-1.6-.3c.8,0,1.1-.9,1.1-1.6s-.3-1.6-1.1-1.6-1.1.9-1.1,1.6S53.9,28.5,54.8,28.5Z"
      fill="#181716"
    />
    <path
      d="M60.7,28.7a2.2,2.2,0,0,1-1.1.2c-1.3,0-1.8-.8-1.8-2a1.8,1.8,0,0,1,1.6-2c1.1,0,1.6.9,1.6,1.9v.3H58.3a1.3,1.3,0,0,0,1.3,1.4,1.8,1.8,0,0,0,1.1-.3Zm-.3-2c0-.7-.2-1.4-.9-1.4a1.3,1.3,0,0,0-1.2,1.4Z"
      fill="#181716"
    />
    <path
      d="M62.5,25.9a2.5,2.5,0,0,0-.1-.9h.5v.8h0c.2-.5.5-.9,1-.9h.3v.5h-.3c-.8,0-1,.9-1,1.6v1.8h-.4Z"
      fill="#181716"
    />
    <path
      d="M67.7,28.2h0a1.2,1.2,0,0,1-1.2.7c-1.1,0-1.3-.7-1.3-1s1.4-1.4,2.4-1.4h.1v-.2c0-.6-.2-1-.9-1a1.5,1.5,0,0,0-1.1.4v-.5l1.1-.3c1,0,1.4.5,1.4,1.5v2.4h-.5Zm0-1.3h-.1c-.9,0-1.8.1-1.8.9s.3.7.8.7,1.1-.9,1.1-1.3Z"
      fill="#181716"
    />
    <path d="M69.5,28.5l2.3-3.1H69.6V25h2.8v.4l-2.3,3h2.3v.4H69.5Z" fill="#181716" />
    <path d="M74.3,24h-.5v-.6h.5Zm-.5,1h.5v3.8h-.5Z" fill="#181716" />
    <path
      d="M77.5,24.9a1.8,1.8,0,0,1,1.8,2c0,1.1-.5,2-1.8,2a1.8,1.8,0,0,1-1.8-2A1.8,1.8,0,0,1,77.5,24.9Zm0,3.6c.9,0,1.3-.7,1.3-1.6s-.4-1.6-1.3-1.6-1.2.7-1.2,1.6S76.7,28.5,77.5,28.5Z"
      fill="#181716"
    />
    <path
      d="M80.8,25.9V25h.4v.7h0a1.4,1.4,0,0,1,1.3-.8c.9,0,1.3.7,1.3,1.5v2.4h-.5V26.5c0-.7-.2-1.2-.9-1.2s-1.1.8-1.1,1.4v2.1h-.5Z"
      fill="#181716"
    />
    <path
      d="M88.1,28.7a2,2,0,0,1-1.1.2c-1.3,0-1.7-.8-1.7-2s.6-2,1.6-2,1.5.9,1.5,1.9v.3H85.8c0,.8.4,1.4,1.2,1.4a1.8,1.8,0,0,0,1.1-.3Zm-.2-2c0-.7-.3-1.4-1-1.4a1.3,1.3,0,0,0-1.1,1.4Z"
      fill="#181716"
    />
    <path
      d="M92.4,28.2a2.1,2.1,0,0,0,1.2.3,1,1,0,0,0,1.1-1.1c0-1-2.4-1.1-2.4-2.5A1.5,1.5,0,0,1,94,23.5l1,.2v.5A1.3,1.3,0,0,0,94,24c-.6,0-1.2.2-1.2.9s2.5,1,2.5,2.6-1,1.4-1.8,1.4l-1.2-.2Z"
      fill="#181716"
    />
    <path d="M98.2,28.8h-.6L96.3,25h.5l1.1,3.3h0L99,25h.5Z" fill="#181716" />
    <path d="M101.2,24h-.5v-.6h.5Zm-.5,1h.5v3.8h-.5Z" fill="#181716" />
    <path d="M102.6,28.5l2.3-3.1h-2.2V25h2.8v.4l-2.3,3h2.3v.4h-2.9Z" fill="#181716" />
    <path d="M106.6,28.5l2.3-3.1h-2.3V25h2.8v.4l-2.3,3h2.3v.4h-2.8Z" fill="#181716" />
    <path
      d="M113.4,28.7a2.2,2.2,0,0,1-1.1.2c-1.3,0-1.8-.8-1.8-2a1.8,1.8,0,0,1,1.7-2c1,0,1.5.9,1.5,1.9v.3h-2.6c0,.8.4,1.4,1.2,1.4a1.8,1.8,0,0,0,1.1-.3Zm-.2-2c0-.7-.3-1.4-1-1.4a1.3,1.3,0,0,0-1.1,1.4Z"
      fill="#181716"
    />
    <path
      d="M115.2,25.9a2.5,2.5,0,0,0-.1-.9h.5v.8h0c.2-.5.5-.9,1-.9h.3v.5h-.3c-.8,0-1,.9-1,1.6v1.8h-.4Z"
      fill="#181716"
    />
    <path
      d="M120.4,28.2h0a1.2,1.2,0,0,1-1.2.7c-1.1,0-1.2-.7-1.2-1s1.3-1.4,2.3-1.4h.1v-.2c0-.6-.2-1-.9-1a1.7,1.7,0,0,0-1.1.4v-.5l1.1-.3c1,0,1.4.5,1.4,1.5v2.4h-.5Zm0-1.3h-.1c-.9,0-1.8.1-1.8.9s.3.7.8.7,1.1-.9,1.1-1.3Z"
      fill="#181716"
    />
    <path
      d="M34.7,34.5l-1.2-.2a2.1,2.1,0,0,0-2.1,2.3,2,2,0,0,0,2.1,2.2,2.5,2.5,0,0,0,1.2-.2v.5l-1.2.2a2.4,2.4,0,0,1-2.6-2.7,2.5,2.5,0,0,1,2.6-2.7,2.5,2.5,0,0,1,1.2.2Z"
      fill="#181716"
    />
    <path
      d="M37.9,35.3c1.3,0,1.8.9,1.8,2a1.8,1.8,0,0,1-1.8,2,1.8,1.8,0,0,1-1.8-2A1.8,1.8,0,0,1,37.9,35.3Zm0,3.6c.8,0,1.3-.7,1.3-1.6s-.5-1.6-1.3-1.6-1.3.7-1.3,1.6S37.1,38.9,37.9,38.9Z"
      fill="#181716"
    />
    <path
      d="M41.2,36.3a2.8,2.8,0,0,0-.1-.9h.5v.7h0a1.3,1.3,0,0,1,1.3-.8c.9,0,1.3.6,1.3,1.5v2.4h-.5V36.9c0-.7-.3-1.2-.9-1.2s-1.1.7-1.1,1.4v2.1h-.5Z"
      fill="#181716"
    />
    <path
      d="M46.3,35.8h-.8v-.4h.8v-.3c0-.8,0-1.6,1-1.6h.4V34h-.4c-.6,0-.6.7-.6,1.1v.4h.9v.4h-.9v3.4h-.4Z"
      fill="#181716"
    />
    <path
      d="M51.6,39.1l-1.1.2c-1.3,0-1.8-.9-1.8-2s.7-2,1.6-2,1.6.9,1.6,1.9v.2H49.3c0,.8.4,1.5,1.2,1.5a2.9,2.9,0,0,0,1.1-.3ZM51.4,37c0-.7-.3-1.3-1-1.3A1.2,1.2,0,0,0,49.3,37Z"
      fill="#181716"
    />
    <path
      d="M56.4,39.2h-.5v-.6h0a1.2,1.2,0,0,1-1.2.7c-1,0-1.6-.9-1.6-2s.5-2,1.6-2a1.2,1.2,0,0,1,1.2.7h0V33.6h.5Zm-1.6-.3c.8,0,1.1-.9,1.1-1.6s-.3-1.6-1.1-1.6-1.1.8-1.1,1.6S53.9,38.9,54.8,38.9Z"
      fill="#181716"
    />
    <path
      d="M60.7,39.1l-1.1.2c-1.3,0-1.8-.9-1.8-2s.7-2,1.6-2,1.6.9,1.6,1.9v.2H58.3a1.4,1.4,0,0,0,1.3,1.5,2.9,2.9,0,0,0,1.1-.3ZM60.4,37c0-.7-.2-1.3-.9-1.3A1.3,1.3,0,0,0,58.3,37Z"
      fill="#181716"
    />
    <path
      d="M62.5,36.2a1.7,1.7,0,0,0-.1-.8h.5v.7h0a1.2,1.2,0,0,1,1-.8h.3v.5h-.3c-.8,0-1,.8-1,1.5v1.9h-.4Z"
      fill="#181716"
    />
    <path
      d="M67.7,38.6h0a1.4,1.4,0,0,1-1.2.7c-1.1,0-1.3-.7-1.3-1.1s1.4-1.3,2.4-1.3h.1v-.2c0-.7-.2-1-.9-1a2,2,0,0,0-1.1.3v-.5l1.1-.2c1,0,1.4.4,1.4,1.4v2.5h-.5Zm0-1.3h-.1c-.9,0-1.8.1-1.8.9s.3.7.8.7,1.1-1,1.1-1.4Z"
      fill="#181716"
    />
    <path d="M69.5,38.8l2.3-3H69.6v-.4h2.8v.4l-2.3,3h2.3v.4H69.5Z" fill="#181716" />
    <path d="M74.3,34.4h-.5v-.7h.5Zm-.5,1h.5v3.8h-.5Z" fill="#181716" />
    <path
      d="M79,38.3a2.8,2.8,0,0,0,.1.9h-.5v-.7h0a1.3,1.3,0,0,1-1.3.8c-.9,0-1.3-.6-1.3-1.5V35.4h.5v2.3c0,.7.3,1.2.9,1.2a1.3,1.3,0,0,0,1.2-1.4V35.4H79Z"
      fill="#181716"
    />
    <path
      d="M80.8,36.3v-.9h.4v.7h0a1.3,1.3,0,0,1,1.3-.8c.9,0,1.3.6,1.3,1.5v2.4h-.5V36.9c0-.7-.2-1.2-.9-1.2s-1.1.7-1.1,1.4v2.1h-.5Z"
      fill="#181716"
    />
    <path
      d="M87.9,38.6a1.5,1.5,0,0,0,1,.3.8.8,0,0,0,.8-.7c0-.9-1.8-.8-1.8-1.9s.6-1,1.2-1a2.2,2.2,0,0,1,1,.2v.4l-.8-.2c-.5,0-.9.1-.9.6s1.9.6,1.9,1.9a1.2,1.2,0,0,1-1.3,1.1l-1.1-.2Z"
      fill="#181716"
    />
    <path d="M93,39.2h-.6l-1.3-3.8h.5l1.1,3.3h0l1.1-3.3h.5Z" fill="#181716" />
    <path d="M96,34.4h-.4v-.7H96Zm-.4,1H96v3.8h-.4Z" fill="#181716" />
    <path d="M97.5,38.8l2.3-3H97.6v-.4h2.7v.4l-2.3,3h2.3v.4H97.5Z" fill="#181716" />
    <path
      d="M101.7,36.2v-.8h.5v.7h0a1.2,1.2,0,0,1,1-.8h.3v.5h-.3c-.8,0-1,.8-1,1.5v1.9h-.5Z"
      fill="#181716"
    />
    <path
      d="M107,38.6h0a1.4,1.4,0,0,1-1.2.7c-1.1,0-1.3-.7-1.3-1.1s1.4-1.3,2.4-1.3h.1v-.2c0-.7-.3-1-.9-1a2,2,0,0,0-1.1.3v-.5l1.1-.2c1,0,1.4.4,1.4,1.4v2.5H107Zm0-1.3h-.2c-.8,0-1.7.1-1.7.9a.6.6,0,0,0,.7.7c1.2,0,1.2-1,1.2-1.4Z"
      fill="#181716"
    />
    <path
      d="M22.3,2.7A27.4,27.4,0,0,0,11.7.9h0A28,28,0,0,0,1.1,2.7s-.3,7.7,1.2,12a15.7,15.7,0,0,0,9.4,9.8h0a16,16,0,0,0,9.5-9.8C22.7,10.4,22.3,2.7,22.3,2.7Z"
      fill="#e11a27"
    />
    <polygon
      points="18.8 9.3 18.8 13.6 13.8 13.6 13.8 18.5 9.6 18.5 9.6 13.6 4.6 13.6 4.6 9.3 9.6 9.3 9.6 4.4 13.8 4.4 13.8 9.3 18.8 9.3"
      fill="#fff"
    />
    <path
      d="M31.5,54.8h1.8c.7,0,1.7.2,1.7,1.3a1.1,1.1,0,0,1-1.1,1.2h0a1.2,1.2,0,0,1,1.2,1.2c0,1.3-1.1,1.5-1.8,1.5H31.5Zm1,2.1h.4c.5,0,1-.1,1-.6s-.5-.7-1-.7h-.4Zm0,2.3H33c.5,0,1.1-.1,1.1-.7s-.6-.8-1.1-.8h-.5Z"
      fill="#181716"
    />
    <path
      d="M39.6,60h-.9v-.5h-.1a1.4,1.4,0,0,1-1.2.6c-.9,0-1.3-.6-1.3-1.5V56.2h1v2c0,.5,0,1.1.6,1.1s.9-.8.9-1.3V56.2h1Z"
      fill="#181716"
    />
    <path
      d="M40.8,56.2h.9v.5h0a1.4,1.4,0,0,1,1.3-.6c.9,0,1.3.6,1.3,1.5V60h-1V58c0-.5,0-1.2-.6-1.2s-.9.8-.9,1.3V60h-1Z"
      fill="#181716"
    />
    <path
      d="M48,59.6h0a1.3,1.3,0,0,1-1.2.5c-1.2,0-1.6-1-1.6-2s.4-2,1.6-2a1.3,1.3,0,0,1,1.2.5h0V54.4h1V60H48Zm-.9-.3c.7,0,.9-.7.9-1.2s-.3-1.3-.9-1.3-.8.8-.8,1.3S46.4,59.3,47.1,59.3Z"
      fill="#181716"
    />
    <path
      d="M53.3,59.8a2.9,2.9,0,0,1-1.4.3,2,2,0,0,1-.2-4c1.4,0,1.8,1,1.8,2.3H50.9a1,1,0,0,0,1.1,1,2.8,2.8,0,0,0,1.3-.4Zm-.7-2.1c-.1-.5-.3-.9-.8-.9a.8.8,0,0,0-.9.9Z"
      fill="#181716"
    />
    <path
      d="M56.7,57l-.9-.2c-.2,0-.6.1-.6.4s1.8.3,1.8,1.7-.8,1.2-1.6,1.2l-1.2-.2v-.8a1.7,1.7,0,0,0,1,.3c.2,0,.6-.1.6-.5s-1.7-.2-1.7-1.6.7-1.2,1.5-1.2h1.1Z"
      fill="#181716"
    />
    <path
      d="M58,56.4a3.5,3.5,0,0,1,1.4-.3c1.2,0,1.7.5,1.7,1.6V60h-.9v-.6h0a1.4,1.4,0,0,1-1.2.7A1.3,1.3,0,0,1,57.6,59a1,1,0,0,1,.7-1.1,3.1,3.1,0,0,1,1.3-.3h.5c0-.6-.2-.8-.8-.8a2.1,2.1,0,0,0-1.2.4Zm1.2,3a.8.8,0,0,0,.7-.4,1.1,1.1,0,0,0,.2-.8h-.4c-.4,0-1.1.1-1.1.7A.6.6,0,0,0,59.2,59.4Z"
      fill="#181716"
    />
    <path
      d="M62.1,56.2h1v.5h0a1.3,1.3,0,0,1,1.1-.6,1.1,1.1,0,0,1,1.2.7,1.2,1.2,0,0,1,1.2-.7c.9,0,1.2.6,1.2,1.5V60h-1V57.7c0-.3,0-.9-.6-.9s-.7.8-.7,1.3V60h-1V57.7c0-.3,0-.9-.6-.9s-.8.8-.8,1.3V60h-1Z"
      fill="#181716"
    />
    <path
      d="M69.3,56.9h-.8v-.7h.8v-.8l1-.3v1.1h.9v.7h-.9v1.8c0,.3.1.6.4.6h.5V60h-.7a1.1,1.1,0,0,1-1.2-1.2Z"
      fill="#181716"
    />
    <path
      d="M74.5,56.9h-.7v-.7h.7v-.6a1.2,1.2,0,0,1,1.3-1.3h.7v.8H76c-.4,0-.5.3-.5.6v.5h.9v.7h-.9V60h-1Z"
      fill="#181716"
    />
    <path
      d="M80.7,60h-.9v-.5h0a1.6,1.6,0,0,1-1.3.6c-.9,0-1.3-.6-1.3-1.5V56.2h1v2c0,.5,0,1.1.7,1.1s.8-.8.8-1.3V56.2h1Zm-2.9-4.5v-.9h.8v.9Zm2.4-.9v.9h-.9v-.9Z"
      fill="#181716"
    />
    <path
      d="M81.9,56.2h.9V57h0a1.3,1.3,0,0,1,1.1-.9h.3v1h-.5c-.8,0-.8,1-.8,1.6V60h-1Z"
      fill="#181716"
    />
    <path
      d="M90,55.7l-1-.2c-.4,0-.9.2-.9.7s2.3.6,2.3,2.3-.9,1.6-1.9,1.6l-1.4-.2V59a2.7,2.7,0,0,0,1.2.3c.4,0,.9-.2.9-.7S87,58,87,56.3s.9-1.6,1.9-1.6l1.2.2Z"
      fill="#181716"
    />
    <path
      d="M91.4,56.2h1v.5h0a1.2,1.2,0,0,1,1.2-.6c1.2,0,1.6.9,1.6,2s-.4,2-1.6,2a1.5,1.5,0,0,1-1.2-.5h0v2h-1Zm1,1.9c0,.5.2,1.2.9,1.2s.8-.7.8-1.2-.1-1.3-.8-1.3S92.4,57.5,92.4,58.1Z"
      fill="#181716"
    />
    <path
      d="M97.9,56.1a2,2,0,0,1,0,4,2,2,0,0,1-2.1-2A1.9,1.9,0,0,1,97.9,56.1Zm0,3.2c.7,0,.9-.7.9-1.3s-.3-1.2-.9-1.2-1,.6-1,1.2S97.1,59.3,97.9,59.3Z"
      fill="#181716"
    />
    <path
      d="M100.8,56.2h.9V57h0a1.3,1.3,0,0,1,1.1-.9h.3v1h-.5c-.8,0-.8,1-.8,1.6V60h-1Z"
      fill="#181716"
    />
    <path
      d="M104.2,56.9h-.8v-.7h.8v-.8l1-.3v1.1h.9v.7h-.9v1.8c0,.3,0,.6.4.6h.5V60h-.7a1.1,1.1,0,0,1-1.2-1.2Z"
      fill="#181716"
    />
    <path
      d="M109.1,54.8H111c.6,0,1.7.2,1.7,1.3a1.2,1.2,0,0,1-1.1,1.2h0a1.3,1.3,0,0,1,1.2,1.2c0,1.3-1.2,1.5-1.8,1.5h-1.9Zm1.1,2.1h.4c.5,0,1-.1,1-.6s-.5-.7-1-.7h-.4Zm0,2.3h.5c.5,0,1.1-.1,1.1-.7s-.6-.8-1.1-.8h-.5Z"
      fill="#181716"
    />
    <path
      d="M115.3,54.8h1.2l2.1,5.2h-1.2l-.4-1.2h-2.2l-.4,1.2h-1.1Zm.6,1h0l-.8,2.2h1.6Z"
      fill="#181716"
    />
    <path
      d="M122.2,55.7l-1.1-.2a.8.8,0,0,0-.8.7c0,1,2.3.6,2.3,2.3s-.9,1.6-2,1.6l-1.3-.2V59a2.1,2.1,0,0,0,1.2.3c.4,0,.9-.2.9-.7s-2.3-.6-2.3-2.3.9-1.6,1.8-1.6l1.3.2Z"
      fill="#181716"
    />
    <path
      d="M123.6,54.8h1.3c1.1,0,2.2.3,2.2,1.6S126.2,58,125,58h-.4v2h-1Zm1,2.4h.4c.5,0,1-.2,1-.8s-.5-.8-1-.8h-.4Z"
      fill="#181716"
    />
    <path
      d="M130.2,54.7a2.7,2.7,0,1,1-2.5,2.7A2.4,2.4,0,0,1,130.2,54.7Zm0,4.6c1.1,0,1.5-1,1.5-1.9s-.4-1.9-1.5-1.9-1.4,1-1.4,1.9S129.2,59.3,130.2,59.3Z"
      fill="#181716"
    />
  </svg>
)
