import {Event, EventAction, Skill} from '../contexts'
import {Impact, ImpactType} from './impact'
import {SkillKeys} from './skill'


export const MAX_NOF_ACCIDENT_PROBABILITY_STEPS = 12
export const NOF_PROBABILITY_STEPS = 20

export interface ScoreSummary {
  funScore: number
  responsibilityScore: number
  minorAccidentProbability: number
  majorAccidentProbability: number
}

export class ScoreCalculation {
  static calculateScoreBySkills(selectedSkills: Skill[], scoreSummary: ScoreSummary): ScoreSummary {
    selectedSkills.forEach((selectedSkill, index) => {
      //stop if same skills selected (just works for 2 in total)
      if (
        selectedSkills.filter((skill) => selectedSkill.key === skill.key).length > 1 && index !== 0
      ) {
        return
      }
      switch (selectedSkill.key) {
        case SkillKeys.MORE_FUN:
          scoreSummary.funScore += (Impact.MORE.values[ImpactType.FUN] / 2)
          break
        case SkillKeys.MOST_FUN_MORE_MINOR_ACCIDENTS:
          scoreSummary.minorAccidentProbability += Impact.MORE.values[ImpactType.PROBABILITY]
          scoreSummary.funScore += (Impact.MOST.values[ImpactType.FUN] / 2)
          break
        case SkillKeys.LEAST_MAJOR_ACCIDENTS:
          scoreSummary.majorAccidentProbability += Impact.MOST.values[ImpactType.PROBABILITY]
          break
        case SkillKeys.LEAST_MINOR_ACCIDENTS:
          scoreSummary.minorAccidentProbability += Impact.MOST.values[ImpactType.PROBABILITY]
          break
        case SkillKeys.LESS_ACCIDENTS:
          scoreSummary.majorAccidentProbability += Impact.LESS.values[ImpactType.PROBABILITY]
          scoreSummary.minorAccidentProbability += Impact.LESS.values[ImpactType.PROBABILITY]
          break
      }
    })

    if (scoreSummary.minorAccidentProbability < 0) {
      scoreSummary.minorAccidentProbability = 0
    }
    if (scoreSummary.majorAccidentProbability < 0) {
      scoreSummary.majorAccidentProbability = 0
    }
    return scoreSummary
  }

  //Reduce max accident probability to 12 / 20
  static reduceToMaxOrMinProbability(scoreSummary: ScoreSummary) {
    if (scoreSummary.majorAccidentProbability + scoreSummary.minorAccidentProbability > MAX_NOF_ACCIDENT_PROBABILITY_STEPS) {
      const diff =
        scoreSummary.majorAccidentProbability + scoreSummary.minorAccidentProbability - MAX_NOF_ACCIDENT_PROBABILITY_STEPS
      for (let i = 0; i < diff; i++) {
        if (i % 2 === 0) {
          scoreSummary.majorAccidentProbability--
        } else {
          scoreSummary.minorAccidentProbability--
        }
      }
    } else if (scoreSummary.majorAccidentProbability <= 0) {
      scoreSummary.minorAccidentProbability++
    }
  }

  static calculateFunScore(passedEvents: number, funScore: number) {
    const funPerEvent = funScore / passedEvents
    if (funPerEvent >= Impact.MORE.values[ImpactType.FUN]) {
      return 4
    }
    if (funPerEvent >= 0) {
      return 3
    }
    if (funPerEvent < 0 && funPerEvent >= Impact.LESS.values[ImpactType.FUN]) {
      return 2
    }
    return 1
  }

  static calculateAmountofResponsibilityTokens(
    maxResponsibilityScore: number,
    responsibilityScore: number
  ) {
    const resPerEvent = responsibilityScore / maxResponsibilityScore
    if (resPerEvent >= 0.8) {
      return 4
    }
    if (resPerEvent >= 0.6) {
      return 3
    }
    if (resPerEvent >= 0.4) {
      return 2
    }
    if (resPerEvent >= 0.2) {
      return 1
    }
    return 0
  }

  static calculateMaxResponsibilityScore(events: Event[]): number {
    let possibleResponsibilityScore = 0
    events.forEach((event: Event) => {
      const action: EventAction | undefined = event.meta.actions.find(
        (action: EventAction) =>
          action.responsibilityImpact === Impact.MOST.id ||
          action.responsibilityImpact === Impact.MORE.id
      )
      if (action) {
        possibleResponsibilityScore += Impact.fromString(action.responsibilityImpact).values[
          ImpactType.RESPONSIBILITY
        ]
      }
    })
    return possibleResponsibilityScore
  }
}
