import {messages as messagesDe} from './../locales/de/messages'
import {messages as messagesFr} from './../locales/fr/messages'
import {messages as messagesIt} from './../locales/it/messages'

export class MessageCatalog {
  public static getCatalog(locale: string): any {
    switch (locale) {
      case 'fr':
        return messagesFr
      case 'it':
        return messagesIt
      case 'de':
        return messagesDe
      default:
        return messagesDe
    }
  }
}
