import {keyframes} from 'styled-components'
import {positive, negative} from '../../theme'

export const IndicatorAnimationIdl = () => keyframes`
  0% {}
  100% {}
`

export const IndicatorAnimationPositive = () => keyframes`
  0% {
    transform: scale(1);
    background-color: ${positive};
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
`

export const IndicatorAnimationNegative = () => keyframes`
  0%, 100% {
    transform: translate3d(0px, 0, 0);
    background-color: ${negative};
  }
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
`
