import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import { i18n } from '@lingui/core'

import { GameStateContext, CurrentScenarioContext } from '../../contexts'
import { Button, Card, Row } from '../../facets'
import { Trans } from '@lingui/macro'
import { replaceByLeaderName } from '../../util.ts/name'
import ReactMarkdown from 'react-markdown'

export const DidntGoPresenter = () => {
  const scenario = useContext(CurrentScenarioContext)
  const { day, dayCount, event, eventCount, dispatch, gameState } = useContext(GameStateContext)

  if (!gameState.action) {
    throw 'Undefined Action'
  }

  return (
    <>
      <motion.div initial={{ rotateY: 90 }} animate={{ rotateY: 0 }} transition={{ duration: 0.25 }}>
        <Card cardHeight={gameState.cardHeight} coachImage>
          <Card.CardText>
            <ReactMarkdown>
              {replaceByLeaderName(
                gameState.action.dontGoDescription[i18n.locale],
                gameState.leaders
              )}
            </ReactMarkdown>
          </Card.CardText>
        </Card>
      </motion.div>
      <Row justifyContent="center">
        <Button
          onClick={() =>
            dispatch({
              type: 'nextEvent',
              scenario,
              day,
              dayCount,
              event,
              eventCount,
              funScoreChange: gameState.funScoreChange || 0,
              responsibilityScoreChange: gameState.responsibilityScoreChange || 0
            })
          }
        >
          <Trans id="go.on">Weiter gehts</Trans>
        </Button>
      </Row>
    </>
  )
}
