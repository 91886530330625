import React from 'react'

/*
  ScenariosContext provides all scenarios to game components.
*/

export interface Translation {
  [key: string]: string
}

export type Scenarios = Scenario[]
export interface Scenario {
  meta: {
    id: number
    name: Translation
  }
  days: Day[]
  leaders: Person[]
}

export interface Day {
  meta: {
    id: number
    sortorderInScenario: number
    scenarioId: number
  }
  events: Event[]
}

export interface Skill {
  id: number
  name: Translation
  description: Translation
  key: string
}

export interface Person {
  id: number
  description: Translation
  name: Translation
  article: Translation
  skill: Skill
}

export interface Event {
  meta: {
    id: number
    dayId: number
    imagePath: string
    shorthand: Translation

    eventDescriptionDefault: Translation
    eventDescriptionAfterMinorAccident: Translation

    goDescription: Translation

    riskWheelInfoText: Translation
    summaryInfoText: Translation

    goWithCautionEffective: boolean
    goWithCautionDescription: Translation

    minorAccidentProbability: number
    majorAccidentProbability: number

    actions: EventAction[]
  }
  sortorderInDay: number
  itemIds: number[]
}

export interface EventAction {
  id: number
  eventId: number
  name: Translation
  responsibilityImpact: string
  funImpact: string
  noAccidentDescription: Translation
  minorAccidentDescription: Translation
  majorAccidentDescription: Translation
  dontGoDescription: Translation
  dontGo: boolean
  imagePath: string
}

export interface AdditionalInfo {
  id: number
  eventId: number
  scenarioId: number
  name: Translation
  url: string
}

export const ScenariosContext = React.createContext<null | Scenarios>(null)

/*
  CurrentScenarioContext provides the current scenario to game components.
*/

export const CurrentScenarioContext = React.createContext<Scenario>({} as Scenario)
