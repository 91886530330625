import React from 'react'
import { Trans } from '@lingui/macro'
import { Row } from '.'
import { ManualSpinningWheel } from './icon/Manual'
import styled from 'styled-components'
import { ManualTitleVector } from './icon/ManualTitleVector'
import { FunAndToken } from './icon/FunAndToken'
import { GoDontGo } from './icon/GoDontGo'

export const Manual = () => {
    const Title = styled.h1`
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    `
    const SubTitle = styled.h2`
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    `

    const ManualDiv = styled.div`
    text-align: center;
    .mr-1 {
        margin-right: 2rem;
    }

    .mr-1:nth-child(1) {
        margin-left: 2rem;
    }
    `

    return (
        <>
        <ManualDiv>
                <Title>
                    <Trans id="manual.heading">Spielanleitung</Trans>
                </Title>
                <ManualTitleVector></ManualTitleVector>
            <SubTitle>
                <Trans id="manual.balance.title">
                    Die richtige Balance zwischen Spass und Verantwortung
                </Trans>
            </SubTitle>
            <p>
                <Trans id="manual.balance.description">
                    Stellt euch vor, ihr reist mit einer Schulklasse von 20
                    Vierzehnjährigen in die Berge. Euer Ziel ist es, diesen das
                    aufregendste und spassigste Schneesportlager zu ermöglichen,
                    ohne dass sich dabei ein Unfall ereignet.
                </Trans>
            </p>
            <Row marginTop="2.5rem" justifyContent="center">
            <GoDontGo></GoDontGo>
            </Row>
            <SubTitle>
                <Trans id="manual.go.or.dont.title">Go oder Don’t Go?</Trans>
            </SubTitle>
            <p>
                <Trans id="manual.go.or.dont.description">
                    Das Lager dauert sechs Tage, an denen ihr immer wieder neuen
                    Events ausgesetzt werdet und eure Entscheidung, über GO oder
                    DON’T GO notwendig wird.
                </Trans>
            </p>
            <Row marginTop="2.5rem" justifyContent="center">
            <FunAndToken></FunAndToken>
            </Row>
            <SubTitle>
                <Trans id="manual.fun.responsability.title">
                    Spass und Vernunftspunkte
                </Trans>
            </SubTitle>
            <p>
                <Trans id="manual.fun.responsability.description">
                    Ihr erreicht eine möglichst hohe Punktzahl mit Vernunft und
                    Glück. Die Vernünftigkeit eurer Entscheide wird jeweils
                    durch euren J+S Coach bewertet.
                </Trans>
            </p>
            <ManualSpinningWheel></ManualSpinningWheel>
            <SubTitle>
                <Trans id="manual.responsability.wheel.title">
                    Risikorad
                </Trans>
            </SubTitle>
            <p>
                <Trans id="manual.responsability.wheel.text">
                    Wie im richtigen Leben, kann jede noch so risikoreiche
                    Situation immernoch gut ausgehen und genau so bleibt aber
                    auch immer ein Restrisiko stehen. Wie viel Glück ihr habt,
                    entscheidet das Risikorad.
                </Trans>
            </p>
            </ManualDiv>
        </>
    )
}
