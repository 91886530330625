// Text colors
export const textLight = '#827D78'
export const textDark = '#454545'
export const textBlue = '#006F9E'


// Shape colors
export const positive = '#00a19a'
export const positiveLight = 'rgba(0, 109, 101, 0.2)'
export const negative = '#E20033'
export const negativeLight = 'rgba(191, 18, 43, 0.2)'
export const special = '#F8B131'
export const specialLight = 'rgba(191, 18, 43, 0.2)'

//Button colors
export const primary = "#006F9E"
export const primaryLight  = "#F0EEE9"
export const secondary = "#46C6C0"
export const secondaryLight  = "#F0EEE9"

//Outline Color
export const outlineColor = '1px solid #454545'
export const outlineLightColor = '1px solid white'
