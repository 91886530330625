import React from 'react'
import styled, {css} from 'styled-components'
import {Logo} from './Logo'

export const Footer = () => {
  return (
    <Wrap>
      <Logo variant="BASPO" width={180} height={70} />
    </Wrap>
  )
}

const Wrap = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
`
