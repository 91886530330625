import {Trans} from '@lingui/macro'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import {api} from '../config'
import {Impact} from '../util.ts/impact'
import {Icon} from './Icon'
import {Surface} from './IconButton'
import {Row} from './Row'

export const DecisionWrapper = styled.div`
  border-bottom: 1px solid rgba(205, 202, 185, 0.5);
  padding: 0 0 12px;
  text-align: left;
  width: 100%;

  .positiv {
    color: #00a19a;
    font-weight: bold;
  }

  .negativ {
    color: #e20033;
    font-weight: bold;
  }

  .div {
    flex-grow: 1 !important;
  }

  p {
    display: block;
  }
`

const SectionTitle = styled.h4`
  text-align: left;
  margin: 12px 0 0;
`

export interface DecisionProps {
  decision: any
  text: any
}

export interface ActionDecisionProps {
  decision: any
  text: any
  funImpact: string
  responsibilityImpact: string
  recommendation?: string
}

export const Decision = ({text, decision}: DecisionProps) => {
  return (
    <>
      <Row>
        <DecisionWrapper>
          <div>
            <Surface variant={decision}>
              <Icon variant={decision} />
            </Surface>
          </div>
          <div>
            <div>
              <p>{text}</p>
              {<p>{decision === 'go' ? 'Go' : decision === 'dontGo' ? 'Don’t Go' : 'Both'}</p>}
            </div>
          </div>
        </DecisionWrapper>
      </Row>
    </>
  )
}

export const ActionDecision = ({
  text,
  decision,
  funImpact,
  responsibilityImpact,
  recommendation
}: ActionDecisionProps) => {
  return (
    <>
      <Row>
        <DecisionWrapper>
          <div>
            <div>
              <SectionTitle>{text}</SectionTitle>
              {decision}
            </div>
          </div>
        </DecisionWrapper>
      </Row>
      {recommendation && (
        <Row>
          <DecisionWrapper>
            <div>
              <div>
                <SectionTitle>
                  <Trans id="our_recommendation">Unser Rat</Trans>
                </SectionTitle>
                <ReactMarkdown>{recommendation}</ReactMarkdown>
              </div>
            </div>
          </DecisionWrapper>
        </Row>
      )}
      <Row>
        <SectionTitle>
          <Trans id="your_decision_impact">Folge Eures Entscheids</Trans>
        </SectionTitle>
      </Row>
      <Row>
        <DecisionWrapper>
          {funImpact === Impact.MOST.id || funImpact === Impact.MORE.id ? (
            <p className="positiv">
              <Trans id="more.fun">Mehr Spass</Trans>
            </p>
          ) : funImpact === Impact.LESS.id || funImpact === Impact.LEAST.id ? (
            <p className="negativ">
              <Trans id="less.fun">Weniger Spass</Trans>
            </p>
          ) : (
            <p>
              <Trans id="no.impact">Keine Auswirkung</Trans>
            </p>
          )}
          {responsibilityImpact === Impact.MOST.id || responsibilityImpact === Impact.MORE.id ? (
            <p className="positiv">
              <Trans id="more.responsibility">Mehr Verantwortung</Trans>
            </p>
          ) : responsibilityImpact === Impact.LESS.id ||
            responsibilityImpact === Impact.LEAST.id ? (
            <p className="negativ">
              <Trans id="less.responsibility">Weniger Verantwortung</Trans>
            </p>
          ) : (
            <p>
              <Trans id="no.impact">Keine Auswirkung</Trans>
            </p>
          )}
        </DecisionWrapper>
      </Row>
    </>
  )
}
