import {Trans} from '@lingui/macro'
import {navigate} from '@reach/router'
import React, {useContext, useEffect} from 'react'
import {api} from '../../config'

import {CurrentScenarioContext, GameStateContext, Scenario} from '../../contexts'
import {HASHIDS} from '../../hashids-config'
import {LOCAL_GAME_STATE, LOCAL_SCENARIO, SUMMARY_HASHID} from '../../storage'

export const VictoryPresenter = () => {
  const {gameState} = useContext(GameStateContext)
  const scenario: Scenario = useContext(CurrentScenarioContext)

  useEffect(() => {
    localStorage.removeItem(SUMMARY_HASHID)
    fetch(`${api.base}/summary/scenario/${scenario.meta.id}`, {
      method: 'post',
      body: JSON.stringify(gameState),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((summary) => {
        localStorage.setItem(SUMMARY_HASHID, summary.id ? HASHIDS.encode(summary.id) : '')
        navigate(`/summary/${summary.id ? summary.id : undefined}`)
      })
      .catch(console.log)
      .finally(() => {
        const summaryId = localStorage.getItem(SUMMARY_HASHID)
        navigate(`/summary/${summaryId ? summaryId : undefined}`)
      })
  }, [gameState, scenario.meta.id])

  useEffect(() => {
    if (gameState) {
      localStorage.removeItem(LOCAL_GAME_STATE)
      localStorage.setItem(LOCAL_GAME_STATE, JSON.stringify(gameState))
    }
  }, [gameState])

  useEffect(() => {
    if (scenario) {
      localStorage.removeItem(LOCAL_SCENARIO)
      localStorage.setItem(LOCAL_SCENARIO, JSON.stringify(scenario))
    }
  }, [scenario])

  return (
    <>
      <p>
        <Trans id="loading">Loading…</Trans>
      </p>
    </>
  )
}
