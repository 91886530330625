import {Trans} from '@lingui/macro'
import React, {useContext, useState} from 'react'
import {GlobalBodyStyle} from '../../App'
import {CurrentScenarioContext, GameStateContext, Person} from '../../contexts'
import {Button, Row} from '../../facets'
import {ItemBox} from '../../facets/ItemBox'
import {replaceByLeaderName} from '../../util.ts/name'
import {i18n} from '@lingui/core'
import styled from 'styled-components'

export const AvatarPresenter = () => {
  const scenario = useContext(CurrentScenarioContext)
  const {dispatch} = useContext(GameStateContext)
  const [leaders, setLeaders]: [Person[], any] = useState([])

  const Title = styled.h1`
    text-align: center;
  `

  const startGame = () => {
    dispatch({
      type: 'startGame',
      leaders: [...leaders]
    })
  }

  const defineLeaders = (persons: Person[]) => {
    setLeaders(persons)
  }

  const convertedItems = (data: Person[]) => {
    return data.map((person: Person) => {
      return {
        name: `${person.name[i18n.locale]} ${person.article[i18n.locale]} ${
          person.skill.name[i18n.locale]
        }`,
        description: replaceByLeaderName(person.skill.description[i18n.locale], [person]),
        data: person
      }
    })
  }

  return (
    <>
      <GlobalBodyStyle light={true}></GlobalBodyStyle>
      <Title>
        <Trans id="choose.leaders">
          Entscheidet euch, welche beiden Leiter gemeinsam ins Schneesportlager gehen sollen
        </Trans>
      </Title>
      <Row marginTop="1.5rem">
        <ItemBox
          amountOfSelectableItems={2}
          items={convertedItems(scenario.leaders)}
          select={defineLeaders}
        ></ItemBox>
      </Row>
      <Row marginTop="1.5rem" justifyContent="center">
        <Button onClick={startGame} disabled={leaders?.length !== 2}>
          <Trans id="next">Weiter</Trans>
        </Button>
      </Row>
    </>
  )
}
