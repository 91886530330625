import React from 'react'

export const MinorAccident = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#F8B131" />
    <circle cx="20" cy="20" r="18" stroke="#CF962F" strokeOpacity="0.2" strokeWidth="4" />
    <path d="M21.9519 9.60059H18.3999V23.2006H21.9519V9.60059Z" fill="white" />
    <path d="M21.9519 26.4004H18.3999V29.9524H21.9519V26.4004Z" fill="white" />
  </svg>
)
