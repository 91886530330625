import React, {useContext} from 'react'
import {RouteComponentProps, Link} from '@reach/router'

import {ScenariosContext} from '../contexts'
import {Trans} from '@lingui/macro'
import {i18n} from '@lingui/core'
import {GlobalBodyStyle} from '../App'
import styled from 'styled-components'
import {HeaderImage} from '../facets/HeaderImage'
import {Row} from '../facets'
import {Footer} from '../facets/Footer'

export const ScenariosPage = (props: RouteComponentProps) => {
  const scenarios = useContext(ScenariosContext)

  const IntroCopy = styled.p`
    text-align: center;
    font-size: 14px;
    line-height: 21px;
  `

  const ListItem = styled.div<{small?: boolean}>`
    background: #46c6c0;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 1rem;
    color: #fff;
    font-weight: 600;
    text-align: center;
    padding: 15px;
    display: ${(props) => (props.small ? 'inline-block' : 'block')};
  `

  const Buttons = styled.div`
    margin-top: 2rem;
    text-align: center;
  `
  return (
    <>
      <HeaderImage />
      <GlobalBodyStyle light={true} />
      <Row marginTop="1.5rem">
        <IntroCopy>
          <Trans id="scenarios.intro">
            Willkommen auf dem Lerntool zum Thema Sicherheit und Unfallprävention beim Sport mit
            Kindern und Jugendlichen
          </Trans>
        </IntroCopy>
      </Row>
      <Row marginTop="1.5rem">
        <h1>
          <Trans id="scenarios.title">
            Liebe J+S-Leiterinnen und J+S-Leiter, stellt euch vor ihr plant ein Sportlager und führt
            dieses gemeinsam durch. Welches Abenteuer steht euch bevor?
          </Trans>
        </h1>
      </Row>
      <Buttons>
        {scenarios &&
          scenarios.map((scenario) => (
            <div key={scenario.meta.id}>
              <Link to={`../scenario/${scenario.meta.id}`}>
                <ListItem>{scenario.meta.name[i18n.locale]}</ListItem>
              </Link>
            </div>
          ))}
        {/* TBD: <ListItem small><Trans id="scenarios.start">Start</Trans></ListItem> */}
      </Buttons>
      <Footer />
    </>
  )
}
