import React, {useEffect} from 'react'
import {motion} from 'framer-motion'
import styled, {css} from 'styled-components'
import {useAnimation, useMotionValue, animate} from 'framer-motion'

import * as theme from '../theme'
import {api} from '../config'

type SegmentVariant = 'positive' | 'negative' | 'special'
const DEFAULT_VARIANT = 'positive'

const SIZE = 350 // 250 is largest value that works with iPhone 5
const RADIUS = SIZE * 0.4 // Magic number based on what looks good
const CIRCUMFERENCE = RADIUS * 2 * Math.PI
const SEC_PER_REV = 0.6 // how long a full revolution takes

interface SegmentProps {
  activated: boolean
  probability: number
  initialRotation: number // Percentage, not degrees
  variant?: SegmentVariant
}
interface WheelImgProps {
  src: string
}
const colorFromVariantProps = (props: SegmentProps) => {
  const variant = props.variant || DEFAULT_VARIANT
  return (theme as any)[variant]
}

interface ProbabilityWheelProps {
  children: React.ReactNode
  finalRotation: number // Percentage, not degrees
  animationStarted?: Function
  animationStopped?: Function
  imagePath: string
  click?: (event: any) => void
  start?: boolean
  stop?: boolean
}

const Div = styled.div<{pointer: boolean}>`
  ${(props) =>
    css`
      cursor: ${props.pointer ? 'pointer' : 'default'};
    `}
  height: ${SIZE}px;
  width: ${SIZE}px;
  margin: 16px auto;
`

const Wheel = styled(motion.svg)`
  height: ${SIZE}px;
  width: 100%;
  transform-origin: ${SIZE / 2}px ${SIZE / 2}px;

  .dotted {
    stroke-dasharray: 0, 14.9%;
    stroke-linecap: round;
    stroke: url(#dotted-gradient);
    stroke-width: 6;
    fill: transparent;
  }
  .gray-stroke {
    stroke-width: 64;
    fill: transparent;
    stroke: url(#gradient);
  }
`

const Segment = styled(motion.circle)<SegmentProps>`
  stroke: ${colorFromVariantProps};
`

const Triangle = styled(motion.polygon)`
  fill: black;
  stroke-width: 1;
`

const WheelImg = styled.div<WheelImgProps>`
  background-image: url(${(props) => props.src});
  position: absolute;
  width: 250px;
  height: 250px;
  margin: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  pointer-event: none;
`

const Rotating = styled(motion.g)``

export const ProbabilityWheel = ({
  finalRotation,
  children,
  click,
  animationStarted,
  animationStopped,
  imagePath,
  start,
  stop
}: ProbabilityWheelProps) => {
  const currentRotation = useMotionValue(0)

  useEffect(() => {
    const easeIntoSpinning = async () => {
      animate(currentRotation, stop ? (finalRotation / 100) * 360 : currentRotation.get() + 360, {
        // finalRotation/100 equals how many times the wheel rotates after stop==true
        // This amounts to 4.5-5.5 revolutions (see EventRiskDistributionPresenter for details)
        // To maintain speed, the duration must be (finalRotation/100)*SEC_PER_ROT
        // To change how long one must wait for the wheel to stop, change SEC_PER_REV
        duration: stop ? (finalRotation / 100) * SEC_PER_REV : SEC_PER_REV,
        ease: stop ? 'easeOut' : 'linear',
        repeat: stop ? 0 : Infinity,
        onComplete: () => {
          if (animationStopped) {
            animationStopped()
          }
        }
      })
    }
    if (start) {
      easeIntoSpinning()
    }
  }, [currentRotation, stop, start, animationStopped, finalRotation])

  return (
    <Div pointer={!stop} onClick={click}>
      <WheelImg src={api.base + imagePath} />
      <Wheel>
        <Rotating style={{rotate: currentRotation}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="350" height="350">
            <defs>
              <radialGradient id="gradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                <stop offset="0%" stop-color="#fff" stop-opacity="0" />
                <stop offset="100%" stop-color="#d1d1d1" stop-opacity="1" />
              </radialGradient>
              <pattern
                id="image"
                x="-150"
                y="-150"
                patternUnits="userSpaceOnUse"
                height="700"
                width="700"
              />
            </defs>
            <circle className="gray-stroke" cx="50%" cy="50%" r="142" />
            <circle cx="50%" cy="50%" r="110" fill="url(#image)" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="350" height="350">
            <defs>
              <radialGradient id="dotted-gradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                <stop offset="29.17%" stop-color="#B6B2A0" stop-opacity="0" />
                <stop offset="100%" stop-color="#AEAB98" stop-opacity="1" />
              </radialGradient>
            </defs>
            <circle className="dotted" cx="50%" cy="50%" r="166" />
          </svg>
          {children}
        </Rotating>
        <Triangle
          // Magic numbers based on what looks good
          points={
            SIZE / 2 -
            SIZE / 50 +
            ',' +
            SIZE +
            ' ' +
            SIZE / 2 +
            ',' +
            (SIZE - SIZE / 15) +
            ' ' +
            (SIZE / 2 + SIZE / 50) +
            ',' +
            SIZE
          }
        ></Triangle>
      </Wheel>
    </Div>
  )
}

export const ProbabilityWheelSegment = ({
  activated,
  probability,
  initialRotation,
  variant
}: SegmentProps) => {
  const animationControl = useAnimation()

  useEffect(() => {
    animationControl.start({
      rotate: (initialRotation / 100) * 360,
      transition: {duration: 0}
    })
  }, [animationControl, initialRotation])
  useEffect(() => {
    if (false) {
      animationControl.start({
        scale: [1, 1.05, 1],
        transition: {
          delay: 0.5,
          duration: 1,
          repeat: Infinity,
          times: [0, 0.5, 1]
        }
      })
    }
  }, [animationControl, activated])

  return (
    <>
      <Segment
        activated={activated}
        animate={animationControl}
        probability={probability}
        initialRotation={initialRotation}
        variant={variant}
        r={RADIUS}
        strokeWidth={SIZE * 0.1} // Magic number based on what looks good
        strokeDasharray={CIRCUMFERENCE} // Make only one dash
        strokeDashoffset={((100 - probability) / 100) * CIRCUMFERENCE} // Regulates length of the segment
        cx="50%"
        cy="50%"
        fill="none"
      ></Segment>
    </>
  )
}
