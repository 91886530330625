import {Trans} from '@lingui/macro'
import React, {useContext} from 'react'
import styled from 'styled-components'
import {GameStateContext} from '../contexts'
import {GameState} from '../hooks'
import {ScoreCalculation} from '../util.ts/score'

export const FunScoreTitle = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-top: 1.5rem;
`

export const Rewind = styled.h1`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`

export interface FunScoreOverviewPresenterProps {
  gameState?: GameState
}

export const FunScoreOverviewPresenter = (props: FunScoreOverviewPresenterProps) => {
  const inGameGameState = useContext(GameStateContext).gameState
  const gameState = props.gameState ? props.gameState : inGameGameState

  function getFunClassification(score: number) {
    if (ScoreCalculation.calculateFunScore(gameState.decisions.length, score) <= 2) {
      return (
        <Trans id="review.was.not.amusing">
          Ihr hattet nicht so viel Spass. Die Jugendlichen gehen enttäuscht nach Hause. Einige sagen
          euch beim Abschied ganz frustriert, dass sie nie mehr ein J+S-Lager besuchen wollen.
        </Trans>
      )
    } else if (ScoreCalculation.calculateFunScore(gameState.decisions.length, score) <= 3) {
      return (
        <Trans id="review.was.fun">
          Ihr hattet ein durchschnittliches Lager. Die Jugendlichen gehen zufrieden, wenn auch nicht
          übermässig begeistert nach Hause. Einige erzählen euch von einem Skilager das sie im
          vergangenen Jahr besucht hatten, welches noch viel mehr Spass gemacht hat.
        </Trans>
      )
    } else {
      return (
        <Trans id="review.was.terrific">
          Ihr hattet eine Menge Spass. Die Jugendlichen gehen mit einem Strahlen im Gesicht nach
          Hause. Für viele Jugendliche war es das beste Lager das sie je besucht haben.
        </Trans>
      )
    }
  }

  return <>{getFunClassification(gameState.funScore)}</>
}
