import {useState, useEffect} from 'react'

import {api} from '../config'
import {Scenarios} from '../contexts'

export const useScenarios = () => {
  const [scenarios, setScenarios] = useState<null | Scenarios>(null)
  useEffect(() => {
    fetch(`${api.base}/scenarios`)
      .then((res) => res.json())
      .then((scenarios) => setScenarios(scenarios))
      .catch(console.log)
  }, [])

  return scenarios
}
