import React from 'react'
import {SvgProps} from './Decision'

export const VeryHappy = ({fill, height, width}: SvgProps) => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    width={width ? width : '24'}
    height={height ? height : '24'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="28.7 46 87 87"
  >
    <path
      fill="#e84e0f"
      d="M108.64,65.73a43.45,43.45,0,1,0-12.75,60.14A43.14,43.14,0,0,0,108.64,65.73Zm-15.47,56a38.47,38.47,0,1,1,11.28-53.23A38.52,38.52,0,0,1,93.17,121.68Z"
    />
    <path
      fill="#e84e0f"
      d="M85.35,86.69h.08a4,4,0,0,0,1.16-.17,4.68,4.68,0,0,0,1.81-.61c2.17-1.25,2.64-4.12,1.84-6.16a4,4,0,0,0-4.67-2.55c-2.51.57-4.11,3.68-3.87,6.09A3.64,3.64,0,0,0,85.35,86.69Z"
    />
    <path
      fill="#e84e0f"
      d="M56.2,84.93a3.38,3.38,0,0,0,1.14.2,4.33,4.33,0,0,0,3.8-2.7c1.11-2.27.83-4.55-.7-5.82-1.12-.93-3.1-1.34-5.26.29a2.48,2.48,0,0,0-1,1.81l0,.49c0,.07,0,.13,0,.2a4.71,4.71,0,0,0,.06,3.67A2.5,2.5,0,0,0,56.2,84.93Z"
    />
    <path
      fill="#e84e0f"
      d="M94.83,96.1a119.89,119.89,0,0,1-45.27-.38,2.54,2.54,0,0,0-3.07,3.08C47.62,106,51.25,114,58.24,117.08a35.67,35.67,0,0,0,24.95,1c3.74-1.16,7.63-3,10.09-6.17l.15-.22a3.36,3.36,0,0,0,.24-.29c2.66-3.64,3.83-8.46,4.32-12.89C98.19,96.76,96.25,95.84,94.83,96.1ZM93,98.27c0,.13,0,.26-.06.39S92.89,98.92,93,98.27ZM72.62,114.85c-6.22.26-13.56-1.19-17.27-6.6a24.32,24.32,0,0,1-3.2-7,126.69,126.69,0,0,0,40.25.29l-.12.53a19.57,19.57,0,0,1-2.76,6.55C85.66,113.3,78.33,114.62,72.62,114.85Z"
    />
  </svg>
)

export const Happy = ({fill, height, width}: SvgProps) => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    width={width ? width : '24'}
    height={height ? height : '24'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="28.7 46 87 87"
  >
    <path
      fill="#e84e0f"
      d="M72.26,132.88a43.45,43.45,0,1,1,23.63-7A43.48,43.48,0,0,1,72.26,132.88ZM72.14,51a38.47,38.47,0,1,0,32.31,17.49A38.24,38.24,0,0,0,72.14,51Z"
    />
    <path
      fill="#e84e0f"
      d="M70.72,115.77a21.38,21.38,0,0,1-19.84-13.53,2.5,2.5,0,1,1,4.66-1.82,16.27,16.27,0,0,0,30.28.08,2.5,2.5,0,1,1,4.65,1.84,21.16,21.16,0,0,1-17.88,13.35C72,115.75,71.34,115.77,70.72,115.77Z"
    />
    <path
      fill="#e84e0f"
      d="M93.83,88.43A2.51,2.51,0,0,1,91.33,86c0-3.24-1.69-4.68-3.29-4.68h0c-1.53,0-3.07,1.37-3.08,4.4a2.51,2.51,0,0,1-2.5,2.49h0a2.5,2.5,0,0,1-2.49-2.51c0-6.12,4.06-9.36,8-9.38H88c4,0,8.18,3.28,8.29,9.59a2.52,2.52,0,0,1-2.46,2.55Z"
    />
    <path
      fill="#e84e0f"
      d="M58.86,87.35a2.5,2.5,0,0,1-2.5-2.44c-.07-3.25-1.77-4.66-3.34-4.66S50,81.64,50,84.66a2.49,2.49,0,0,1-2.49,2.51h0A2.51,2.51,0,0,1,45,84.68c0-6.13,4-9.38,8-9.43s8.25,3.21,8.4,9.54a2.5,2.5,0,0,1-2.44,2.56Z"
    />
  </svg>
)

export const Sad = ({fill, height, width}: SvgProps) => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    width={width ? width : '24'}
    height={height ? height : '24'}
  >
    <path
      d="M22.9565 5.66659C21.8345 3.94702 20.3061 2.53046 18.5064 1.54225C16.7066 0.55403 14.691 0.0246147 12.638 0.000837889C10.5849 -0.0229389 8.55761 0.459655 6.73545 1.40593C4.9133 2.3522 3.35246 3.73298 2.191 5.42611C1.02954 7.11924 0.303283 9.07252 0.0764674 11.1132C-0.150348 13.1538 0.129273 15.2189 0.890594 17.1257C1.65192 19.0326 2.87147 20.7224 4.44136 22.0457C6.01125 23.369 7.88309 24.285 9.89127 24.7126C10.7507 24.8991 11.6272 24.9954 12.5066 25C14.7535 24.9942 16.9574 24.3829 18.8862 23.2303C20.815 22.0777 22.3974 20.4265 23.4669 18.4504C24.5364 16.4743 25.0535 14.2465 24.9637 12.0013C24.8738 9.75619 24.1805 7.57677 22.9565 5.69246V5.66659ZM18.5104 21.761C17.068 22.6971 15.4272 23.2838 13.7183 23.4746C12.0094 23.6654 10.2796 23.455 8.66629 22.8601C7.05298 22.2651 5.60067 21.3021 4.42476 20.0476C3.24886 18.793 2.38178 17.2815 1.89243 15.6331C1.40309 13.9847 1.30496 12.2448 1.60585 10.5519C1.90674 8.8589 2.59836 7.25944 3.62574 5.88061C4.65312 4.50178 5.98794 3.38158 7.52412 2.60903C9.0603 1.83648 10.7555 1.43289 12.475 1.43031C13.2478 1.43152 14.0184 1.51339 14.7742 1.6746C16.9139 2.12754 18.8711 3.20489 20.3984 4.77037C21.9257 6.33585 22.9544 8.31913 23.3543 10.4693C23.7543 12.6195 23.5075 14.84 22.6453 16.85C21.7831 18.8599 20.3441 20.569 18.5104 21.761Z"
      fill="#E84E0F"
    />
    <path
      d="M16.5562 9.00582C15.8348 9.16963 15.375 10.0663 15.4468 10.7561C15.463 11.0224 15.5809 11.2723 15.7762 11.4542C15.9714 11.636 16.229 11.7359 16.4958 11.7332H16.5188C16.6316 11.7341 16.7439 11.7186 16.8522 11.6873C17.0349 11.6651 17.211 11.6055 17.3695 11.5119C17.9961 11.1498 18.1283 10.3422 17.8868 9.73869C17.7921 9.47559 17.6048 9.25604 17.3598 9.12114C17.1149 8.98624 16.8292 8.94524 16.5562 9.00582Z"
      fill="#E84E0F"
    />
    <path
      d="M8.75858 8.83692C8.43669 8.56964 7.87051 8.4518 7.24686 8.92027C7.16427 8.98177 7.09586 9.0603 7.04628 9.15055C6.99669 9.24079 6.96708 9.34065 6.95946 9.44334V9.58129C6.95946 9.6014 6.95946 9.62152 6.95946 9.64164C6.89049 9.80856 6.85548 9.98756 6.85647 10.1682C6.85745 10.3488 6.89442 10.5274 6.9652 10.6935C6.99869 10.8291 7.07083 10.9521 7.17287 11.0475C7.2749 11.1429 7.40245 11.2067 7.54 11.231C7.64555 11.2667 7.7562 11.2852 7.86764 11.2856C8.10442 11.274 8.33291 11.1948 8.52599 11.0572C8.71906 10.9197 8.86862 10.7296 8.95689 10.5096C9.27878 9.85719 9.20118 9.1933 8.75858 8.83692Z"
      fill="#E84E0F"
    />
    <path
      d="M9.01787 15.9561C8.93352 15.9986 8.85835 16.0572 8.79667 16.1287C8.73498 16.2003 8.68799 16.2832 8.65837 16.3729C8.62874 16.4625 8.61707 16.5572 8.62402 16.6514C8.63097 16.7455 8.65639 16.8374 8.69885 16.9218C8.74131 17.0061 8.79997 17.0813 8.87148 17.143C8.94299 17.2047 9.02595 17.2517 9.11562 17.2813C9.20529 17.3109 9.29991 17.3226 9.39409 17.3156C9.48828 17.3087 9.58017 17.2833 9.66452 17.2408C10.5692 16.7884 11.5955 16.6395 12.5914 16.816C13.5874 16.9925 14.5 17.4851 15.1941 18.2208C15.3263 18.3503 15.5033 18.4238 15.6884 18.4259C15.8734 18.4281 16.0521 18.3588 16.1873 18.2325C16.3225 18.1062 16.4038 17.9326 16.4142 17.7478C16.4246 17.5631 16.3633 17.3815 16.2431 17.2408C15.3378 16.2771 14.1454 15.6315 12.8436 15.4C11.5417 15.1685 10.1999 15.3636 9.01787 15.9561Z"
      fill="#E84E0F"
    />
    <path
      d="M18.174 4.93637C18.0383 4.80566 17.8567 4.73349 17.6683 4.73539C17.4799 4.73729 17.2998 4.81311 17.1668 4.94651C17.0337 5.07992 16.9584 5.26025 16.9571 5.44864C16.9557 5.63704 17.0284 5.81844 17.1595 5.95376C17.4469 6.24116 17.7055 6.52856 17.9671 6.81596L18.3407 7.25569L18.5332 7.49423L18.6166 7.60056L18.6568 7.6523C19.133 8.2801 19.5716 8.93557 19.9702 9.61524C20.0678 9.77819 20.2253 9.89649 20.409 9.94482C20.5927 9.99316 20.788 9.96771 20.9531 9.8739C21.1152 9.77476 21.2326 9.61679 21.2808 9.43305C21.329 9.24931 21.3043 9.05404 21.2118 8.88812C20.3671 7.44992 19.3466 6.12246 18.174 4.93637Z"
      fill="#E84E0F"
    />
    <path
      d="M8.07491 4.56357C7.93797 4.43252 7.75575 4.35938 7.56621 4.35938C7.37667 4.35938 7.19445 4.43252 7.05751 4.56357C5.89067 5.64994 4.77556 6.78709 3.71217 7.97501C3.57726 8.10992 3.50146 8.29291 3.50146 8.48371C3.50146 8.67451 3.57726 8.85749 3.71217 8.99241C3.84709 9.12732 4.03007 9.20311 4.22087 9.20311C4.41167 9.20311 4.59466 9.12732 4.72957 8.99241C5.78912 7.80257 6.90423 6.66542 8.07491 5.58097C8.20796 5.44507 8.28248 5.26245 8.28248 5.07227C8.28248 4.88208 8.20796 4.69946 8.07491 4.56357Z"
      fill="#E84E0F"
    />
  </svg>
)

export const VerySad = ({fill, height, width}: SvgProps) => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width={width ? width : '24'}
    height={height ? height : '24'}
  >
    <path
      d="M18.3652 4.53327C17.4676 3.15762 16.2449 2.02437 14.8051 1.2338C13.3653 0.443224 11.7528 0.0196918 10.1104 0.000670311C8.46792 -0.0183511 6.84608 0.367724 5.38836 1.12474C3.93064 1.88176 2.68196 2.98638 1.7528 4.34088C0.823636 5.69539 0.242627 7.25802 0.0611739 8.89053C-0.120279 10.523 0.103418 12.1751 0.712475 13.7006C1.32153 15.2261 2.29717 16.5779 3.55309 17.6366C4.809 18.6952 6.30647 19.428 7.91302 19.7701C8.60055 19.9193 9.30177 19.9963 10.0053 20C11.8028 19.9954 13.5659 19.5063 15.1089 18.5842C16.652 17.6622 17.9179 16.3412 18.7735 14.7603C19.6291 13.1795 20.0428 11.3972 19.9709 9.60107C19.8991 7.80495 19.3444 6.06142 18.3652 4.55397V4.53327ZM14.806 17.4088C13.3395 18.3612 11.6236 18.8574 9.87514 18.8347C8.12669 18.812 6.42421 18.2714 4.98292 17.2813C3.54163 16.2912 2.42624 14.8961 1.77775 13.2721C1.12926 11.6482 0.976774 9.86853 1.33958 8.15798C1.70238 6.44743 2.56419 4.88285 3.81605 3.66201C5.06791 2.44116 6.65362 1.61888 8.37273 1.2991C10.0918 0.979316 11.8672 1.17639 13.4743 1.86542C15.0814 2.55444 16.4482 3.70448 17.4018 5.17015C18.0412 6.14124 18.4815 7.2296 18.6972 8.37212C18.9128 9.51465 18.8995 10.6886 18.6581 11.826C18.4166 12.9633 17.9518 14.0414 17.2906 14.9978C16.6293 15.9542 15.7848 16.7698 14.806 17.3973V17.4088Z"
      fill="#E84E0F"
    />
    <path
      d="M13.2472 7.20496C12.6678 7.33602 12.3 8.05337 12.3575 8.60518C12.3704 8.81824 12.4647 9.01817 12.6209 9.16366C12.7771 9.30915 12.9832 9.38907 13.1967 9.38691H13.2151C13.3053 9.3876 13.3951 9.3752 13.4818 9.35012C13.6278 9.3321 13.7687 9.28437 13.8956 9.20987C14.1182 9.05795 14.2812 8.83345 14.3567 8.57471C14.4322 8.31597 14.4155 8.03906 14.3095 7.79126C14.2337 7.58126 14.0842 7.40598 13.8888 7.2981C13.6933 7.19022 13.4653 7.15712 13.2472 7.20496Z"
      fill="#E84E0F"
    />
    <path
      d="M7.1681 8.40748C7.42331 7.88556 7.36123 7.36134 7.00716 7.06934C6.74964 6.85552 6.2967 6.76125 5.79778 7.13602C5.7317 7.18522 5.67698 7.24805 5.63731 7.32024C5.59764 7.39244 5.57396 7.47232 5.56786 7.55447V7.66483C5.56786 7.68093 5.56786 7.69702 5.56786 7.71082C5.51262 7.84474 5.48458 7.98832 5.48537 8.13318C5.48616 8.27805 5.51576 8.42131 5.57245 8.55463C5.59924 8.66312 5.65696 8.7615 5.73858 8.83782C5.82021 8.91415 5.92225 8.96513 6.03229 8.98457C6.11673 9.0132 6.20525 9.02795 6.2944 9.02826C6.48423 9.01945 6.66751 8.95624 6.82242 8.84618C6.97733 8.73611 7.09732 8.58383 7.1681 8.40748Z"
      fill="#E84E0F"
    />
    <path
      d="M14.5069 5.7448C14.3599 5.66248 14.2171 5.5727 14.0793 5.47579C14.008 5.42981 13.939 5.38152 13.87 5.33094L13.7781 5.26426C13.8884 5.34473 13.7643 5.25047 13.7505 5.23897C13.4971 5.03421 13.261 4.80889 13.0446 4.56531L12.886 4.37907L12.8193 4.2963C12.7273 4.16984 12.6377 4.04339 12.5549 3.91003C12.4721 3.77668 12.4008 3.65482 12.325 3.52147C12.2951 3.46169 12.2675 3.40421 12.2399 3.34443C12.2399 3.32834 12.2238 3.30994 12.2169 3.29155C12.21 3.27315 12.2054 3.26856 12.2376 3.34213C12.2169 3.32833 12.2031 3.25936 12.1962 3.23867C12.1523 3.09356 12.0543 2.97088 11.9225 2.89599C11.7907 2.8211 11.6352 2.79973 11.4881 2.83631C11.3437 2.87867 11.2214 2.97553 11.1472 3.10641C11.073 3.23728 11.0526 3.39191 11.0903 3.53756C11.6311 4.90145 12.6358 6.03075 13.9275 6.72655C13.992 6.77684 14.0664 6.81281 14.1458 6.83205C14.2253 6.85129 14.3079 6.85336 14.3883 6.83812C14.4686 6.82288 14.5447 6.79068 14.6116 6.74368C14.6785 6.69667 14.7346 6.63594 14.7761 6.56554C14.8177 6.49513 14.8437 6.41667 14.8526 6.3354C14.8614 6.25413 14.8528 6.17191 14.8273 6.09423C14.8018 6.01655 14.76 5.9452 14.7048 5.88494C14.6495 5.82469 14.5821 5.77691 14.5069 5.7448Z"
      fill="#E84E0F"
    />
    <path
      d="M5.6113 6.42082C6.97777 5.87906 8.10942 4.87278 8.80719 3.579C8.88402 3.44729 8.90539 3.29045 8.86658 3.14298C8.82777 2.99552 8.73198 2.86951 8.60026 2.79268C8.46855 2.71584 8.31171 2.69448 8.16424 2.73329C8.01678 2.77209 7.89077 2.86789 7.81394 2.99961C7.69532 3.25784 7.54048 3.49784 7.3541 3.71236L7.41158 3.67557L7.36099 3.73075L7.29202 3.81582C7.24144 3.8756 7.18855 3.93768 7.13337 3.99746C7.02761 4.11242 6.91725 4.22738 6.80229 4.33314C6.68733 4.43891 6.57237 4.54697 6.43671 4.64813H6.42522L6.33325 4.71711C6.26198 4.76769 6.193 4.81597 6.11943 4.86426C5.98147 4.95393 5.84122 5.039 5.69637 5.11717L5.48485 5.22523L5.35609 5.28501H5.3377H5.3216C5.17667 5.32841 5.05402 5.42595 4.9791 5.55738C4.90418 5.68881 4.88274 5.84405 4.91924 5.99087C4.99282 6.28746 5.3147 6.54267 5.6113 6.42082Z"
      fill="#E84E0F"
    />
    <path
      d="M11.0907 11.9392C10.7104 11.8805 10.3258 11.8536 9.94105 11.8587C9.51262 11.8357 9.08296 11.8542 8.6581 11.9139C7.66484 12.0817 6.42097 13.0083 6.81873 14.1556C7.2165 15.3029 8.86732 15.2133 9.8077 15.2133C10.7481 15.2133 12.3667 15.3742 12.7967 14.2246C13.2266 13.075 12.0908 12.1254 11.0907 11.9392ZM7.90626 13.7786C7.90584 13.7724 7.90584 13.7663 7.90626 13.7602V13.7763C7.90703 13.7717 7.90703 13.767 7.90626 13.7625V13.7786ZM11.7413 13.7786C11.7413 13.797 11.7413 13.8314 11.7413 13.7786V13.7786ZM11.7229 13.8429C11.7232 13.8483 11.7232 13.8537 11.7229 13.859L11.6953 13.8843L11.631 13.9234L11.6034 13.9395H11.5666C11.4696 13.971 11.3705 13.9956 11.27 14.0131C11.0533 14.0393 10.8353 14.0532 10.617 14.0545C10.3526 14.0545 10.0882 14.0545 9.82379 14.0545C9.55938 14.0545 9.32716 14.0545 9.08115 14.0384C8.90411 14.0384 8.6489 14.0085 8.55463 13.9924C8.39367 13.9644 8.23469 13.926 8.0787 13.8774L8.02122 13.8498L7.94534 13.8039L7.93385 13.7648C7.93148 13.7565 7.93148 13.7477 7.93385 13.7395L7.94534 13.7027C7.94534 13.728 7.92695 13.7556 7.94534 13.7027L7.96374 13.6429C7.97753 13.6222 8.01432 13.5509 8.02352 13.5348C8.10443 13.4382 8.19891 13.3538 8.30402 13.2842C8.48723 13.1727 8.6889 13.0949 8.89951 13.0543H8.96159L9.13173 13.0428C9.31567 13.0428 9.4973 13.029 9.67894 13.0428C10.0123 13.0428 10.348 13.0428 10.6814 13.075L10.8446 13.0888C11.0311 13.1456 11.2139 13.2139 11.3918 13.2934C11.4441 13.322 11.4927 13.3567 11.5367 13.3969C11.575 13.4298 11.6111 13.4651 11.6448 13.5027L11.6655 13.5233C11.6884 13.5532 11.7551 13.6935 11.7528 13.6751C11.7618 13.7015 11.7695 13.7284 11.7758 13.7556C11.7758 13.7556 11.7758 13.7763 11.7758 13.8061C11.7758 13.836 11.7758 13.8061 11.7758 13.8061C11.7594 13.8208 11.7417 13.8338 11.7229 13.8452V13.8429Z"
      fill="#E84E0F"
    />
  </svg>
)
