import React from 'react'

export const Bandaid = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 10L14 4C12 2 7 1 3.99999 4C0.999979 7 2 12 3.99999 14L9.99999 20L20 10Z"
      fill="#E20033"
    />
    <path
      d="M14 4L12.5858 5.41421L12.5858 5.41421L14 4ZM20 10L21.4142 11.4142C21.7893 11.0391 22 10.5304 22 10C22 9.46957 21.7893 8.96086 21.4142 8.58579L20 10ZM3.99999 14L2.58577 15.4142L2.58577 15.4142L3.99999 14ZM9.99999 20L8.58578 21.4142C8.96085 21.7893 9.46956 22 9.99999 22C10.5304 22 11.0391 21.7893 11.4142 21.4142L9.99999 20ZM3.99999 4L5.4142 5.41422L3.99999 4ZM12.5858 5.41421L18.5858 11.4142L21.4142 8.58579L15.4142 2.58579L12.5858 5.41421ZM2.58577 15.4142L8.58578 21.4142L11.4142 18.5858L5.4142 12.5858L2.58577 15.4142ZM18.5858 8.58579L8.58578 18.5858L11.4142 21.4142L21.4142 11.4142L18.5858 8.58579ZM5.41421 12.5858C4.82465 11.9962 4.22035 10.7388 4.12056 9.24196C4.02411 7.79522 4.41586 6.41256 5.4142 5.41422L2.58578 2.58579C0.584109 4.58745 -0.0241348 7.20478 0.129419 9.50804C0.279629 11.7611 1.17534 14.0038 2.58577 15.4142L5.41421 12.5858ZM5.4142 5.41422C6.41255 4.41588 7.79522 4.02412 9.24196 4.12057C10.7389 4.22036 11.9962 4.82466 12.5858 5.41421L15.4142 2.58579C14.0038 1.17534 11.7611 0.279638 9.50804 0.129431C7.20478 -0.0241191 4.58744 0.584128 2.58578 2.58579L5.4142 5.41422Z"
      fill="#E20033"
    />
    <path d="M28 38L34 44C36 46 41 47 44 44C47 41 46 36 44 34L38 28L28 38Z" fill="#E20033" />
    <path
      d="M34 44L35.4142 42.5858L35.4142 42.5858L34 44ZM28 38L26.5858 36.5858C26.2107 36.9609 26 37.4696 26 38C26 38.5304 26.2107 39.0391 26.5858 39.4142L28 38ZM44 34L45.4142 32.5858L45.4142 32.5858L44 34ZM38 28L39.4142 26.5858C39.0391 26.2107 38.5304 26 38 26C37.4696 26 36.9609 26.2107 36.5858 26.5858L38 28ZM44 44L42.5858 42.5858L44 44ZM35.4142 42.5858L29.4142 36.5858L26.5858 39.4142L32.5858 45.4142L35.4142 42.5858ZM45.4142 32.5858L39.4142 26.5858L36.5858 29.4142L42.5858 35.4142L45.4142 32.5858ZM29.4142 39.4142L39.4142 29.4142L36.5858 26.5858L26.5858 36.5858L29.4142 39.4142ZM42.5858 35.4142C43.1753 36.0038 43.7796 37.2612 43.8794 38.7581C43.9759 40.2048 43.5841 41.5875 42.5858 42.5858L45.4142 45.4142C47.4159 43.4126 48.0241 40.7952 47.8706 38.492C47.7204 36.2389 46.8247 33.9962 45.4142 32.5858L42.5858 35.4142ZM42.5858 42.5858C41.5875 43.5841 40.2048 43.9759 38.758 43.8794C37.2611 43.7796 36.0038 43.1754 35.4142 42.5858L32.5858 45.4142C33.9962 46.8247 36.2389 47.7204 38.492 47.8706C40.7952 48.0241 43.4126 47.4159 45.4142 45.4142L42.5858 42.5858Z"
      fill="#E20033"
    />
    <path
      d="M12 26L26 12L34 3.89339C36 1.8934 41 0.893381 44 3.89339C47 6.8934 46 11.8934 44 13.8934L36 22L22 36L13.8934 44C11.8934 46 6.89339 47 3.89339 44C0.893393 41 1.89339 36 3.89339 34L12 26Z"
      fill="#E20033"
    />
    <path
      d="M22 36L23.4049 37.4236L23.4142 37.4142L22 36ZM36 22L37.4142 23.4142L37.4235 23.4048L36 22ZM34 3.89339L32.5858 2.47914L32.5765 2.48857L34 3.89339ZM44 13.8934L42.5858 12.4791L42.5765 12.4886L44 13.8934ZM13.8934 44L12.4885 42.5764L12.4792 42.5858L13.8934 44ZM3.89339 44L2.47918 45.4142L2.47918 45.4142L3.89339 44ZM3.89339 34L2.48854 32.5764L2.47918 32.5858L3.89339 34ZM24.5858 10.5858L10.5858 24.5858L13.4142 27.4142L27.4142 13.4142L24.5858 10.5858ZM23.4142 37.4142L37.4142 23.4142L34.5858 20.5858L20.5858 34.5858L23.4142 37.4142ZM37.4142 20.5858L27.4142 10.5858L24.5858 13.4142L34.5858 23.4142L37.4142 20.5858ZM23.4142 34.5858L13.4142 24.5858L10.5858 27.4142L20.5858 37.4142L23.4142 34.5858ZM35.4142 5.30761C36.0038 4.71806 37.2612 4.11376 38.7581 4.01396C40.2048 3.91751 41.5875 4.30926 42.5858 5.3076L45.4142 2.47918C43.4126 0.477512 40.7952 -0.130732 38.492 0.0228228C36.2389 0.173034 33.9962 1.06874 32.5858 2.47918L35.4142 5.30761ZM42.5858 5.3076C43.5841 6.30595 43.9759 7.68862 43.8794 9.13536C43.7796 10.6323 43.1754 11.8896 42.5858 12.4792L45.4142 15.3076C46.8247 13.8972 47.7204 11.6545 47.8706 9.40144C48.0241 7.09818 47.4159 4.48085 45.4142 2.47918L42.5858 5.3076ZM32.5765 2.48857L24.5765 10.5952L27.4235 13.4048L35.4236 5.29822L32.5765 2.48857ZM37.4235 23.4048L45.4236 15.2982L42.5765 12.4886L34.5765 20.5952L37.4235 23.4048ZM12.4792 42.5858C11.8896 43.1753 10.6322 43.7796 9.13535 43.8794C7.68861 43.9759 6.30595 43.5841 5.30761 42.5858L2.47918 45.4142C4.48084 47.4159 7.09817 48.0241 9.40143 47.8706C11.6545 47.7204 13.8972 46.8247 15.3076 45.4142L12.4792 42.5858ZM5.30761 42.5858C4.30927 41.5874 3.91751 40.2048 4.01396 38.758C4.11375 37.2611 4.71805 36.0038 5.3076 35.4142L2.47918 32.5858C1.06873 33.9962 0.173026 36.2388 0.02282 38.492C-0.13073 40.7952 0.477519 43.4125 2.47918 45.4142L5.30761 42.5858ZM5.29821 35.4235L13.4048 27.4235L10.5952 24.5765L2.48857 32.5765L5.29821 35.4235ZM20.5952 34.5765L12.4886 42.5765L15.2982 45.4235L23.4048 37.4235L20.5952 34.5765Z"
      fill="#E20033"
    />
    <circle cx="24" cy="24" r="1" fill="white" />
    <circle cx="27" cy="21" r="1" fill="white" />
    <circle cx="21" cy="27" r="1" fill="white" />
    <circle cx="27" cy="27" r="1" fill="white" />
    <circle cx="30" cy="24" r="1" fill="white" />
    <circle cx="24" cy="30" r="1" fill="white" />
    <circle cx="21" cy="21" r="1" fill="white" />
    <circle cx="24" cy="18" r="1" fill="white" />
    <circle cx="18" cy="24" r="1" fill="white" />
  </svg>
)

export const BandaidLight = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 10L14 4C12 2 7 1 3.99999 4C0.999979 7 2 12 3.99999 14L9.99999 20L20 10Z"
      fill="#EAC7C7"
    />
    <path
      d="M14 4L12.5858 5.41421L12.5858 5.41421L14 4ZM20 10L21.4142 11.4142C21.7893 11.0391 22 10.5304 22 10C22 9.46957 21.7893 8.96086 21.4142 8.58579L20 10ZM3.99999 14L2.58577 15.4142L2.58577 15.4142L3.99999 14ZM9.99999 20L8.58578 21.4142C8.96085 21.7893 9.46956 22 9.99999 22C10.5304 22 11.0391 21.7893 11.4142 21.4142L9.99999 20ZM3.99999 4L5.4142 5.41422L3.99999 4ZM12.5858 5.41421L18.5858 11.4142L21.4142 8.58579L15.4142 2.58579L12.5858 5.41421ZM2.58577 15.4142L8.58578 21.4142L11.4142 18.5858L5.4142 12.5858L2.58577 15.4142ZM18.5858 8.58579L8.58578 18.5858L11.4142 21.4142L21.4142 11.4142L18.5858 8.58579ZM5.41421 12.5858C4.82465 11.9962 4.22035 10.7388 4.12056 9.24196C4.02411 7.79522 4.41586 6.41256 5.4142 5.41422L2.58578 2.58579C0.584109 4.58745 -0.0241348 7.20478 0.129419 9.50804C0.279629 11.7611 1.17534 14.0038 2.58577 15.4142L5.41421 12.5858ZM5.4142 5.41422C6.41255 4.41588 7.79522 4.02412 9.24196 4.12057C10.7389 4.22036 11.9962 4.82466 12.5858 5.41421L15.4142 2.58579C14.0038 1.17534 11.7611 0.279638 9.50804 0.129431C7.20478 -0.0241191 4.58744 0.584128 2.58578 2.58579L5.4142 5.41422Z"
      fill="#EAC7C7"
    />
    <path d="M28 38L34 44C36 46 41 47 44 44C47 41 46 36 44 34L38 28L28 38Z" fill="#EAC7C7" />
    <path
      d="M34 44L35.4142 42.5858L35.4142 42.5858L34 44ZM28 38L26.5858 36.5858C26.2107 36.9609 26 37.4696 26 38C26 38.5304 26.2107 39.0391 26.5858 39.4142L28 38ZM44 34L45.4142 32.5858L45.4142 32.5858L44 34ZM38 28L39.4142 26.5858C39.0391 26.2107 38.5304 26 38 26C37.4696 26 36.9609 26.2107 36.5858 26.5858L38 28ZM44 44L42.5858 42.5858L44 44ZM35.4142 42.5858L29.4142 36.5858L26.5858 39.4142L32.5858 45.4142L35.4142 42.5858ZM45.4142 32.5858L39.4142 26.5858L36.5858 29.4142L42.5858 35.4142L45.4142 32.5858ZM29.4142 39.4142L39.4142 29.4142L36.5858 26.5858L26.5858 36.5858L29.4142 39.4142ZM42.5858 35.4142C43.1753 36.0038 43.7796 37.2612 43.8794 38.7581C43.9759 40.2048 43.5841 41.5875 42.5858 42.5858L45.4142 45.4142C47.4159 43.4126 48.0241 40.7952 47.8706 38.492C47.7204 36.2389 46.8247 33.9962 45.4142 32.5858L42.5858 35.4142ZM42.5858 42.5858C41.5875 43.5841 40.2048 43.9759 38.758 43.8794C37.2611 43.7796 36.0038 43.1754 35.4142 42.5858L32.5858 45.4142C33.9962 46.8247 36.2389 47.7204 38.492 47.8706C40.7952 48.0241 43.4126 47.4159 45.4142 45.4142L42.5858 42.5858Z"
      fill="#EAC7C7"
    />
    <path
      d="M12 26L26 12L34 3.89339C36 1.8934 41 0.893381 44 3.89339C47 6.8934 46 11.8934 44 13.8934L36 22L22 36L13.8934 44C11.8934 46 6.89339 47 3.89339 44C0.893393 41 1.89339 36 3.89339 34L12 26Z"
      fill="#EAC7C7"
    />
    <path
      d="M22 36L23.4049 37.4236L23.4142 37.4142L22 36ZM36 22L37.4142 23.4142L37.4235 23.4048L36 22ZM34 3.89339L32.5858 2.47914L32.5765 2.48857L34 3.89339ZM44 13.8934L42.5858 12.4791L42.5765 12.4886L44 13.8934ZM13.8934 44L12.4885 42.5764L12.4792 42.5858L13.8934 44ZM3.89339 44L2.47918 45.4142L2.47918 45.4142L3.89339 44ZM3.89339 34L2.48854 32.5764L2.47918 32.5858L3.89339 34ZM24.5858 10.5858L10.5858 24.5858L13.4142 27.4142L27.4142 13.4142L24.5858 10.5858ZM23.4142 37.4142L37.4142 23.4142L34.5858 20.5858L20.5858 34.5858L23.4142 37.4142ZM37.4142 20.5858L27.4142 10.5858L24.5858 13.4142L34.5858 23.4142L37.4142 20.5858ZM23.4142 34.5858L13.4142 24.5858L10.5858 27.4142L20.5858 37.4142L23.4142 34.5858ZM35.4142 5.30761C36.0038 4.71806 37.2612 4.11376 38.7581 4.01396C40.2048 3.91751 41.5875 4.30926 42.5858 5.3076L45.4142 2.47918C43.4126 0.477512 40.7952 -0.130732 38.492 0.0228228C36.2389 0.173034 33.9962 1.06874 32.5858 2.47918L35.4142 5.30761ZM42.5858 5.3076C43.5841 6.30595 43.9759 7.68862 43.8794 9.13536C43.7796 10.6323 43.1754 11.8896 42.5858 12.4792L45.4142 15.3076C46.8247 13.8972 47.7204 11.6545 47.8706 9.40144C48.0241 7.09818 47.4159 4.48085 45.4142 2.47918L42.5858 5.3076ZM32.5765 2.48857L24.5765 10.5952L27.4235 13.4048L35.4236 5.29822L32.5765 2.48857ZM37.4235 23.4048L45.4236 15.2982L42.5765 12.4886L34.5765 20.5952L37.4235 23.4048ZM12.4792 42.5858C11.8896 43.1753 10.6322 43.7796 9.13535 43.8794C7.68861 43.9759 6.30595 43.5841 5.30761 42.5858L2.47918 45.4142C4.48084 47.4159 7.09817 48.0241 9.40143 47.8706C11.6545 47.7204 13.8972 46.8247 15.3076 45.4142L12.4792 42.5858ZM5.30761 42.5858C4.30927 41.5874 3.91751 40.2048 4.01396 38.758C4.11375 37.2611 4.71805 36.0038 5.3076 35.4142L2.47918 32.5858C1.06873 33.9962 0.173026 36.2388 0.02282 38.492C-0.13073 40.7952 0.477519 43.4125 2.47918 45.4142L5.30761 42.5858ZM5.29821 35.4235L13.4048 27.4235L10.5952 24.5765L2.48857 32.5765L5.29821 35.4235ZM20.5952 34.5765L12.4886 42.5765L15.2982 45.4235L23.4048 37.4235L20.5952 34.5765Z"
      fill="#EAC7C7"
    />
    <circle cx="24" cy="24" r="1" fill="white" />
    <circle cx="27" cy="21" r="1" fill="white" />
    <circle cx="21" cy="27" r="1" fill="white" />
    <circle cx="27" cy="27" r="1" fill="white" />
    <circle cx="30" cy="24" r="1" fill="white" />
    <circle cx="24" cy="30" r="1" fill="white" />
    <circle cx="21" cy="21" r="1" fill="white" />
    <circle cx="24" cy="18" r="1" fill="white" />
    <circle cx="18" cy="24" r="1" fill="white" />
  </svg>
)
