import React from 'react'

import {Bandaid, BandaidLight} from './icon/Bandaid'
import {Close} from './icon/Close'
import {Go, DontGo} from './icon/Decision'
import {Info} from './icon/Info'

type Variant = 'bandaid' | 'bandaidLight' | 'go' | 'dontGo' | 'info' | 'close'
interface IconProps {
  variant: Variant
  width?: number
  height?: number
  fill?: string
}

export const Icon = ({variant, width, height, fill}: IconProps) => {
  switch (variant) {
    case 'bandaid':
      return <Bandaid />
    case 'bandaidLight':
      return <BandaidLight />
    case 'go':
      return <Go height={height} width={width} fill={fill} />
    case 'dontGo':
      return <DontGo height={height} width={width} fill={fill} />
    case 'info':
      return <Info />
    case 'close':
      return <Close />
    default:
      return null
  }
}
