import React from 'react'

export const Go = ({fill, height, width}: SvgProps) => (
  <svg
    width={width ? width : '32'}
    height={height ? height : '32'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 17 L6 11 L0 17 L12 29 L32 9 L26 3 L12 17 Z" fill={fill ? fill : 'white'} />
  </svg>
)

export interface SvgProps {
  fill?: string
  height?: number
  width?: number
}

export const DontGo = ({fill, height, width}: SvgProps) => (
  <svg
    width={width ? width : '32'}
    height={height ? height : '32'}
    viewBox={'0 0 32 32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0 L0 6 L10 16 L0 26 L6 32 L16 22 L26 32 L32 26 L21.8182 16 L32 6 L26 0 L16 10 L6 0 Z"
      fill={fill ? fill : 'white'}
    />
  </svg>
)

export const Circle = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17" cy="17" r="15" fill="#DFDDCE" />
    <circle cx="17" cy="17" r="16" stroke="#CDCAB9" stroke-opacity="0.2" stroke-width="2" />
    <svg
      width="13"
      height="13"
      viewBox={'0 0 32 32'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0 L0 6 L10 16 L0 26 L6 32 L16 22 L26 32 L32 26 L21.8182 16 L32 6 L26 0 L16 10 L6 0 Z"
        fill="black"
      />
    </svg>
  </svg>
)
