import React, {useMemo, useState} from 'react'

import {ProbabilityWheel, ProbabilityWheelSegment} from '../facets'

interface EventRisksDistributionPresenterProps {
  outcome: 'noOutcome' | 'noAccident' | 'minorAccident' | 'majorAccident'
  noAccidentProbability: number
  minorAccidentProbability: number
  majorAccidentProbability: number
  stopWheel?: boolean
  wheelStopped?: Function
  wheelStarted?: Function
  imagePath: string
}
export const EventRisksDistributionPresenter = ({
  outcome,
  noAccidentProbability,
  minorAccidentProbability,
  majorAccidentProbability,
  wheelStopped,
  wheelStarted,
  stopWheel,
  imagePath
}: EventRisksDistributionPresenterProps) => {
  // Pre-generate the random value when the component is first rendered
  const random = useMemo(() => Math.random(), [])
  const [hasStopped, setStopped] = useState(false)
  const [hasStarted, setStarted] = useState(false)

  // This rotates the start (if viewed counter-clockwise) of the 'positive' segment to the bottom
  // This allows us to easily calculate the necessary rotations for any given outcome
  // This value is a percentage of one whole rotation (i.e. 100 = 360deg)
  const baseOffset = 25 - noAccidentProbability
  // If applied after baseOffset, this rotates the middle of the 'positive' segment to the bottom
  // We want this, because it looks nicer than just the baseOffset
  // This value is a percentage of one whole rotation (i.e. 100 = 360deg)
  const aestheticOffset = noAccidentProbability / 2
  // Final offset we have to apply to the initial wheel
  // This value is a percentage of one whole rotation (i.e. 100 = 360deg)
  const offset = baseOffset + aestheticOffset

  // Calculate random rotation that will stop in the correct place for the given outcome
  // This value is a percentage of one whole rotation (i.e. 100 = 360deg)
  let finalRotation = 0

  // We want to rotate at least four times
  // And we want to 'cancel' the aestheticOffset so we can easily apply the random rotations
  const baseRotation = 400 + (100 - aestheticOffset)
  if (outcome === 'noAccident') {
    // 'Positive' segment comes first, so we can just rotate to somewhere in this segment
    finalRotation = baseRotation + random * noAccidentProbability
  } else if (outcome === 'minorAccident') {
    // 'Special' segment comes second, so we must first rotate past the 'positive' segment
    finalRotation = baseRotation + noAccidentProbability + random * minorAccidentProbability
  } else if (outcome === 'majorAccident') {
    // 'Negative' segment comes last, so we must first rotate past the 'positive' and 'special' segments
    finalRotation =
      baseRotation +
      noAccidentProbability +
      minorAccidentProbability +
      random * majorAccidentProbability
  }

  const afterWheelAnimationStopped = () => {
    setStopped(true)
    if (wheelStopped) {
      wheelStopped()
    }
  }

  const startWheel = () => {
    setStarted(true)
    if (wheelStarted) {
      wheelStarted()
    }
  }

  return (
    <ProbabilityWheel
      imagePath={imagePath}
      stop={stopWheel}
      click={startWheel}
      start={hasStarted}
      animationStopped={afterWheelAnimationStopped}
      finalRotation={finalRotation}
    >
      <ProbabilityWheelSegment
        // The 'positive' segment needs no rotation relative to other segments
        initialRotation={0 + offset}
        activated={hasStopped ? outcome === 'noAccident' : false}
        probability={noAccidentProbability}
        variant="positive"
      />
      <ProbabilityWheelSegment
        // The 'special' segment needs be rotated to the position after the 'positive' segment
        initialRotation={-minorAccidentProbability + offset}
        activated={hasStopped ? outcome === 'minorAccident' : false}
        probability={minorAccidentProbability}
        variant="special"
      />
      {majorAccidentProbability > 0 && (
        <ProbabilityWheelSegment
          // The 'negative' segment needs be rotated to the position before the 'positive' segment
          initialRotation={noAccidentProbability + offset}
          activated={hasStopped ? outcome === 'majorAccident' : false}
          probability={majorAccidentProbability}
          variant="negative"
        />
      )}
    </ProbabilityWheel>
  )
}
