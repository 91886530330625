import {useEffect, useState} from 'react'
import {api} from '../config'
import {GameState} from './useGameState'
import {HASHIDS} from '../hashids-config'
import {Summary} from '../pages/Summary'
import {AdditionalInfo} from '../contexts'

export interface StoredGameStateProps {
  hashId: string | undefined
}

interface Summary {
  id: number
  scenarioId: number
  data: GameState
}

export const useStoredSummary = (props: StoredGameStateProps) => {
  const id = HASHIDS.decode(props.hashId ? props.hashId : '')[0]
  const [result, setResult] = useState<null | {
    summary: Summary
    additionalInfo: AdditionalInfo[]
  }>()
  useEffect(() => {
    fetch(`${api.base}/summary/${id}`)
      .then((res) => res.json())
      .then((summary: Summary) => {
        fetch(`${api.base}/scenario/${summary.scenarioId}/additional`)
          .then((res) => res.json())
          .then((info) => setResult({summary: summary, additionalInfo: info}))
          .catch(console.log)
      })
      .catch(console.log)
  }, [])
  return result
}
