import styled, {css} from 'styled-components'

import React, {useState} from 'react'
import {ArrowDown, ArrowUp} from './icon/Accordion'
import {api} from '../config'
import {MajorAccident} from './icon/MajorAccident'
import {MinorAccident} from './icon/MinorAccident'
import {Trans} from '@lingui/macro'
import {Impact} from '../util.ts/impact'

const AccordionWrapper = styled.div`
  flex-grow: 1;
  background: rgba(240, 238, 233, 0.2);
  border: 1px solid #f5f4ee;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 12px;
`

const AccordionHeader = styled.div`
  padding: 12px 0px;
  line-height: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
`

const TitleWrap = styled.div``
const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`
const SubLine = styled.div`
  font-size: 16px;
  line-height: 24px;

  display: flex;
`

const Arrow = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: end;
`

const AccordionContentWrapper = styled.div<AccordionContentProps>`
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  ${(props) =>
    props.opened &&
    css`
      max-height: ${props.opened ? '2160px' : '0px'};
      border-top: ${props.opened ? '1px solid rgba(205, 202, 185, 0.5)' : 'none'};
    `}
`

const Thumbnail = styled.div<ThumbnailProps>`
  display: flex;
  flex: 0 0 96px;
  width: 96px;
  height: 96px;
  background-image: url('${api.base}${(props) => props.image}');
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 6px solid ${(props) => props.imageBorderColor || '#F5F4EE'};
  position: relative;
  margin-right: 22px;
  svg {
    position: absolute;
    bottom: -4px;
    right: -4px;
  }
`

export const AccordionDescriptionLinkWrapper = styled.div`
  border-top: 1px solid rgba(205, 202, 185, 0.5);
  box-sizing: border-box;
  border-radius: 3px;
`

const AccordionFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #f5f4ee;
  padding-top: 12px;
  margin-top: 6px;
`

export interface AccordionProps {
  children: React.ReactNode[] | React.ReactNode
  title: string
  opened?: boolean
  image?: string
  funImpact?: string
  responsibilityImpact?: string
  footer?: any
}

export interface AccordionContentProps {
  opened: boolean
}

interface ThumbnailProps {
  image: string
  imageBorderColor?: string
}

export const Accordion = ({
  children,
  title,
  opened,
  image,
  funImpact,
  responsibilityImpact,
  footer
}: AccordionProps) => {
  const [isOpened, setOpen] = useState(opened === undefined ? false : opened)
  const toggle = () => {
    setOpen(!isOpened)
  }

  const negativeFunImpact = funImpact === Impact.LESS.id || funImpact === Impact.LEAST.id
  const negativeResponsibilityImpact =
    responsibilityImpact === Impact.LESS.id || responsibilityImpact === Impact.LEAST.id

  let imageBorderColor = '#F5F4EE'
  let showMinorAccidentIcon = false
  let showMajorAccidentIcon = false

  if (negativeFunImpact && negativeResponsibilityImpact) {
    imageBorderColor = '#E20033'
    showMajorAccidentIcon = true
  }

  if (
    (!negativeFunImpact && negativeResponsibilityImpact) ||
    (negativeFunImpact && !negativeResponsibilityImpact)
  ) {
    imageBorderColor = '#F8B131'
    showMinorAccidentIcon = true
  }

  return (
    <>
      <AccordionWrapper>
        <AccordionHeader onClick={toggle}>
          {image && (
            <Thumbnail image={image} imageBorderColor={imageBorderColor}>
              {showMajorAccidentIcon && <MajorAccident />}
              {showMinorAccidentIcon && <MinorAccident />}
            </Thumbnail>
          )}
          <TitleWrap>
            <Title>{title}</Title>
            <SubLine>
              <Trans id="show_feedback">Feedback anzeigen</Trans>
              <Arrow>{isOpened ? <ArrowUp /> : <ArrowDown />}</Arrow>
            </SubLine>
          </TitleWrap>
        </AccordionHeader>
        <AccordionContentWrapper opened={isOpened}>{children}</AccordionContentWrapper>
        {footer && <AccordionFooter>{footer}</AccordionFooter>}
      </AccordionWrapper>
    </>
  )
}
