import React from 'react'
import styled from 'styled-components'
import {i18n} from '@lingui/core'

import {BASPOLogoDe} from './images/BASPO-Logo-de'
import {JSLogo} from './images/JS-Logo'
import {GSLogo} from './images/GS-Logo'
import {BASPOLogoIt} from './images/BASPO-Logo-it'
import {BASPOLogoFr} from './images/BASPO-Logo-fr'

type Variant = 'JS' | 'BASPO'

interface LogoProps {
  variant: Variant
  width?: number
  height?: number
}

interface WrapProps {
  width?: number
  height?: number
}

export const Logo = ({variant, width, height}: LogoProps) => {
  switch (variant) {
    case 'JS':
      return (
        <Wrap width={width} height={height}>
          {i18n.locale === 'it' ? <GSLogo /> : <JSLogo />}
        </Wrap>
      )
    case 'BASPO':
      return (
        <Wrap width={width} height={height}>
          {i18n.locale === 'de' && <BASPOLogoDe />}
          {i18n.locale === 'fr' && <BASPOLogoFr />}
          {i18n.locale === 'it' && <BASPOLogoIt />}
        </Wrap>
      )
    default:
      return null
  }
}

const Wrap = styled.div<WrapProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  svg  {
    width: 100%;
    height: 100%;
  }
`
