import React, {useContext, useEffect} from 'react'
import {RouteComponentProps} from '@reach/router'

import {ScenariosContext, CurrentScenarioContext, GameStateContext} from '../contexts'
import {useGameState, GameScreen} from '../hooks'
import {
  DidntGoPresenter,
  EventPresenter,
  EventDecisionPresenter,
  EventRisksPresenter,
  GameStatePresenter,
  VictoryPresenter,
  IntroPresenter
} from '../presenters'
import {ManualPresenter} from '../presenters/gameScreens/ManualPresenter'
import {AvatarPresenter} from '../presenters/gameScreens/AvatarPresenter'
import {Trans} from '@lingui/macro'
import {ScoreCalculation} from '../util.ts/score'

type GameScreenMap = {
  [screen in GameScreen]: () => JSX.Element | null
}

export const Scenario = ({id}: RouteComponentProps<{id: string}>) => {
  const scenarios = useContext(ScenariosContext)
  const [gameState, dispatch] = useGameState()
  const {screen} = gameState

  // Ask for confirmation before reloading page
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => window.removeEventListener('beforeunload', alertUser)
  }, [])
  const alertUser = (e: any) => {
    e.preventDefault()
    e.returnValue = ''
  }

  const scenario = scenarios && scenarios.find((scenario) => scenario.meta.id.toString() === id)
  const day =
    scenario && scenario.days.find((day) => day.meta.sortorderInScenario === gameState.day)
  const event = day && day.events.find((event) => event.sortorderInDay === gameState.event)

  if (!(scenario && day && event))
    return (
      <>
        <Trans id="scenario.error.occurred">Es ist ein Fehler aufgetreten!</Trans>
      </>
    )
  const maxResponsibilityScore = ScoreCalculation.calculateMaxResponsibilityScore(
    scenario.days.flatMap((x) => x.events)
  )
  const dayCount = scenario.days.length
  const eventCount = scenario.days.reduce((total, day) => total + day.events.length, 0)

  const gameScreens: GameScreenMap = {
    manual: ManualPresenter,
    event: EventPresenter,
    didntGo: DidntGoPresenter,
    eventDecision: EventDecisionPresenter,
    eventRisks: EventRisksPresenter,
    victory: VictoryPresenter,
    avatars: AvatarPresenter,
    intro: IntroPresenter
  }
  const GameScreen = gameScreens[screen]

  return (
    <CurrentScenarioContext.Provider value={scenario}>
      <GameStatePresenter
        {...{gameState, scenario, day, dayCount, event, eventCount, maxResponsibilityScore}}
      />
      <GameStateContext.Provider
        value={{
          gameState,
          day,
          dayCount,
          event,
          eventCount,
          maxResponsibilityScore,
          dispatch
        }}
      >
        <GameScreen />
      </GameStateContext.Provider>
      {/*
      <Link to="/">End Game</Link>
        */}
    </CurrentScenarioContext.Provider>
  )
}
