export enum ImpactType {
  FUN = 'FUN',
  RESPONSIBILITY = 'RESPONSIBILITY',
  PROBABILITY = 'PROBABILITY'
}

export class Impact {
  private static ALL: {[name: string]: Impact} = {}
  static readonly MOST = new Impact('++', {PROBABILITY: 2, RESPONSIBILITY: 10, FUN: 10})
  static readonly MORE = new Impact('+', {PROBABILITY: 1, RESPONSIBILITY: 5, FUN: 5})
  static readonly LESS = new Impact('-', {PROBABILITY: -1, RESPONSIBILITY: -5, FUN: -5})
  static readonly LEAST = new Impact('--', {PROBABILITY: -2, RESPONSIBILITY: -10, FUN: -10})

  private constructor(public readonly id: string, public values: Record<ImpactType, number>) {
    Impact.ALL[id] = this
  }

  //Use responsabilityImpact to get probability values
  public static getValue(id: string, t: ImpactType): number {
    if (!Impact.ALL[id] || Impact.ALL[id].values[t] === undefined) {
      return 0
    }
    return Impact.ALL[id].values[t]
  }

  public static invertImpact(key: string){
    switch(key){
     case this.MOST.id:
       return this.LEAST.id
     case this.MORE.id:
      return this.LESS.id
     case this.LESS.id:
      return this.MORE.id
     case this.LEAST.id:
      return this.MOST.id
     default:
        return ''
    }
  }

  public static fromString(id: string): Impact {
    return Impact.ALL[id]
  }

  public static handledResponsibly(impact: string) {
    return Impact.ALL[impact].values[ImpactType.RESPONSIBILITY] >= 0
  }
}
