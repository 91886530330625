import React, {useContext, useRef} from 'react'
import styled, {keyframes} from 'styled-components'
import {Trans} from '@lingui/macro'

import {positive, negative} from '../theme'
import {VeryHappy, Happy, Sad, VerySad} from './icon/Emoji'
import {ToolTipContext} from '../contexts/ToolTipContext'
import {ToolTip} from './ToolTip'
import {
  IndicatorAnimationIdl,
  IndicatorAnimationNegative,
  IndicatorAnimationPositive
} from './animations/IndicatorAnimation'

type ScoreChange = 'positive' | 'negative' | 'noChange'

interface WrapperProps {
  scoreChange: ScoreChange
}

interface EmojiWrapProps {
  active?: boolean
}

const Wrapper = styled.div<WrapperProps>`
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  margin-left: 4px;

  border-radius: 15px;
  background-color: #dfddce;
  border: 2px solid #dfddce;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${(props) =>
      props.scoreChange === 'positive'
        ? IndicatorAnimationPositive
        : props.scoreChange === 'negative'
        ? IndicatorAnimationNegative
        : IndicatorAnimationIdl}
    1s cubic-bezier(0.28, 0.84, 0.42, 1);
  animation-iteration-count: 2;
`

const EmojiInner = styled.div`
  width: 22px;
  height: 22px;
`

const Summary = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmojiWrap = styled.div<EmojiWrapProps>`
  display: inline;
  ${(props) =>
    !props.active
      ? `opacity: 0.5; width: 19px;
  height: 19px; `
      : `width: 25px;
  height: 25px;`}
  margin: 0 3px;
`

interface FunScoreIndicatorProps {
  score: number
  label: any
  noWrap?: boolean
  isSummary?: boolean
}
export const FunScoreIndicator = ({score, label, noWrap, isSummary}: FunScoreIndicatorProps) => {
  const scoreChange = useRef('noChange' as ScoreChange)
  const lastScore = useRef(score)

  const {openToolTipKey, setOpenToolTipKey} = useContext(ToolTipContext)

  let emoji = <VerySad height={22} width={22}></VerySad>
  let emojiMsg = (
    <Trans id="func.indicator.tooltip.not_happy_0">
      Dieses Lager läuft gar nicht gut, die Jugendlichen bekommen es mit der Angst zu tun.
    </Trans>
  )

  switch (score) {
    case 4:
      emoji = <VeryHappy height={22} width={22}></VeryHappy>
      emojiMsg = <Trans id="func.indicator.tooltip.happy_4">Best J+S Lager ever!</Trans>
      break
    case 3:
      emoji = <Happy height={22} width={22}></Happy>
      emojiMsg = (
        <Trans id="func.indicator.tooltip.happy_3">
          Die Stimmung im Lager ist gut und alle sind zufrieden.
        </Trans>
      )
      break
    case 2:
      emoji = <Sad height={22} width={22}></Sad>
      emojiMsg = (
        <Trans id="func.indicator.tooltip.happy_2">
          Die Stimmung schwenkt um, es gibt Grund zur Sorge.
        </Trans>
      )
      break
    case 1:
      emoji = <VerySad height={22} width={22}></VerySad>
      emojiMsg = (
        <Trans id="func.indicator.tooltip.neutral_1">
          Dieses Lager läuft gar nicht gut, die Jugendlichen bekommen es mit der Angst zu tun.
        </Trans>
      )
      break
  }

  if (lastScore.current !== score) {
    scoreChange.current =
      lastScore.current < score ? ('positive' as ScoreChange) : ('negative' as ScoreChange)

    lastScore.current = score
  }

  if (isSummary) {
    return (
      <Summary>
        {score === 1 ? (
          <EmojiWrap active>
            <VerySad height={25} width={25} />
          </EmojiWrap>
        ) : (
          <EmojiWrap>
            <VerySad height={19} width={19} />
          </EmojiWrap>
        )}
        {score === 2 ? (
          <EmojiWrap active>
            <Sad height={25} width={25} />
          </EmojiWrap>
        ) : (
          <EmojiWrap>
            <Sad height={19} width={19} />
          </EmojiWrap>
        )}
        {score === 3 ? (
          <EmojiWrap active>
            <Happy height={25} width={25} />
          </EmojiWrap>
        ) : (
          <EmojiWrap>
            <Happy height={19} width={19} />
          </EmojiWrap>
        )}
        {score === 4 ? (
          <EmojiWrap active>
            <VeryHappy height={25} width={25} />
          </EmojiWrap>
        ) : (
          <EmojiWrap>
            <VeryHappy height={19} width={19} />
          </EmojiWrap>
        )}
      </Summary>
    )
  }

  return (
    <>
      {noWrap ? (
        <>{emoji}</>
      ) : (
        <Wrapper key={score} scoreChange={scoreChange.current}>
          <EmojiInner
            role="button"
            onClick={() => setOpenToolTipKey(openToolTipKey === 'funScore' ? '' : 'funScore')}
            onMouseOver={() => setOpenToolTipKey('funScore')}
            onMouseOut={() => openToolTipKey === 'funScore' && setOpenToolTipKey('')}
          >
            {emoji}
          </EmojiInner>
          {openToolTipKey === 'funScore' && <ToolTip>{emojiMsg}</ToolTip>}
        </Wrapper>
      )}
    </>
  )
}
