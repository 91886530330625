import React from 'react'

export const Close = () => {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.434 1.09823L9.33577 0L5.217 4.11877L1.09823 0L0 1.09823L4.11877 5.217L0 9.33577L1.09823 10.434L5.217 6.31523L9.33577 10.434L10.434 9.33577L6.31523 5.217L10.434 1.09823Z"
        fill="#838282"
      />
    </svg>
  )
}
