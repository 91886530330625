import React from 'react'
import styled, {css} from 'styled-components'

const Parent = styled.div<{justifyContent?: string; marginTop?: string}>`
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
`
interface ChildProps {
  flex?: number
}
const Child = styled.div<ChildProps>`
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
`

interface RowProps {
  children: React.ReactNode
  justifyContent?: string
  marginTop?: string
}
export const Row = ({children, justifyContent, marginTop}: RowProps) => (
  <Parent marginTop={marginTop} justifyContent={justifyContent}>
    {children}
  </Parent>
)
Row.Item = Child
