import {t} from '@lingui/macro'
import React from 'react'
import {MessageCatalog} from '../util.ts/i18n'
import {i18n} from '@lingui/core'
import styled from 'styled-components'
import {Row} from './Row'

export const LanguageSelect = () => {
  const switchLang = (locale: string) => {
    i18n.load(locale, MessageCatalog.getCatalog(locale))
    i18n.activate(locale)
  }
  return (
    <>
      <Row justifyContent="end">
        <ChooseLanguage
          className="btn btn-link"
          value={i18n.locale}
          onChange={(e) => {
            switchLang(e.target.value)
          }}
        >
          <option value="de">{t({id: 'language.german', message: 'Deutsch'})}</option>
          <option value="fr">{t({id: 'language.french', message: 'Französisch'})}</option>
          <option value="it">{t({id: 'language.italian', message: 'Italienisch'})}</option>
        </ChooseLanguage>
      </Row>
    </>
  )
}

const ChooseLanguage = styled.select`
  appearance: none;
  border: none;
  outline: none;
  width: 115px;
  background: transparent
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgaWQ9
    IkxheWVyXzEiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzQ1NDU0NSIgeD0iMHB4IiB5PSIwcHgiIHZp
    ZXdCb3g9IjAgMCAxMCAxMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAgMTAiIHhtbDpz
    cGFjZT0icHJlc2VydmUiIGhlaWdodD0iMTBweCIgd2lkdGg9IjEwcHgiPjxnPjxwYXRoIGQ9Ik05
    LjYwNzQ5IDQuMzAzNjhMNS4zMDM3NCA4LjYwNzQyTDAuOTk5OTk2IDQuMzAzNjgiLz48L2c+PC9z
    dmc+Cg==')
    96% / 10% no-repeat;
  z-index: 9999999;
`
