import React from 'react'
import styled from 'styled-components'

import {api} from '../config'
import {textDark} from '../theme'
import {CoachSvg} from './icon/CoachSvg'

const MARGIN_SIZE = 16
interface CardProps {
  children: React.ReactNode
  titleImage?: string
  coachImage?: boolean
  outerRef?: any
  cardHeight?: number
}

const CardWrapper = styled.div<{removeTopSpacing?: boolean; cardHeight?: number}>`
  background-color: #ffffff;
  border-radius: 3px;
  margin: ${MARGIN_SIZE}px 0;
  padding: 16px 0;
  ${(props) => props.removeTopSpacing && 'padding-top: 0px;'}
  @media only screen and (max-width: 600px) {
    margin: 12px 0;
  }
  overflow: auto;
  height: ${(props) => {
    if (props.cardHeight) {
      return props.cardHeight - 2 * MARGIN_SIZE
    }
  }}px;
`
const TitleImage = styled.div<{url?: string}>`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 0 16px 0;
  height: 200px;
  ${(props) => props.url && `background: #eee url(${props.url}) no-repeat center center;`}
  background-color: #ffffff;
  background-size: cover;
  border-radius: 3px 3px 0 0;

  @media only screen and (max-width: 600px) {
    height: 174px;
    ${(props) => props.url && `background: url(${props.url}) no-repeat center 0px;`}

    margin-top: 0;
    margin-led: 0;
    margin-top: 0;
    background-size: cover;
    margin-bottom: 1rem;
  }
`

const CoachWrap = styled.div`
  width: 30%;
  padding-top: 30%;

  border-radius: 50%;
  position: relative;
`

const Coach = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  border-radius: 50%;

  svg {
    width: 100%;
    height: auto;
`

function addCoachImage() {
  return (
    <TitleImage>
      <CoachWrap>
        <Coach>
          <CoachSvg />
        </Coach>
      </CoachWrap>
    </TitleImage>
  )
}

const Card = ({children, titleImage, coachImage, outerRef, cardHeight}: CardProps) => {
  if (titleImage) {
    return (
      <CardWrapper cardHeight={cardHeight} ref={outerRef} removeTopSpacing>
        <TitleImage url={`${api.base}${titleImage}`} />
        {coachImage && addCoachImage()}
        {children}
      </CardWrapper>
    )
  } else {
    return (
      <CardWrapper cardHeight={cardHeight} ref={outerRef} removeTopSpacing>
        {coachImage && addCoachImage()}
        {children}
      </CardWrapper>
    )
  }
}

const DIV = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 0 16px;

  text-align: center;
  line-height: 24px;
  color: ${textDark};

  & + & {
    margin-top: 8px;
  }
`
Card.CardText = ({children}: CardProps) => <DIV>{children}</DIV>

export {Card}
