import React from 'react'

export const Info = () => {
  return (
    <svg width="4" height="12" viewBox="0 0 4 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.20946 2.48C1.65746 2.912 2.42546 2.912 2.85746 2.48C3.30546 2.032 3.30546 1.296 2.85746 0.848C2.40946 0.384 1.67346 0.384 1.22546 0.848C0.777462 1.296 0.777462 2.032 1.20946 2.48ZM1.20946 12H2.87346V3.936H1.20946V12Z"
        fill="#838282"
      />
    </svg>
  )
}
