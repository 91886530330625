import React from 'react'
import styled, {css} from 'styled-components'

export enum DIALOG_TEXT_COLOR {
  BRIGHT = '#FFF',
  DARK = '#000',
  MEDIUM_DARK = '#454545'
}

export enum DIALOG_BG_COLOR {
  BLUE = '#006F9E',
  RED = '#E20033',
  DEFAULT = '#FFF',
  BEIGE = 'rgba(245, 244, 238, 0.93)'
}

const DialogWrapper = styled.div<DialogProps>`
  ${(props) =>
    css`
      display: ${props.show ? 'block' : 'none'};
      width: ${props.maximize ? '100%' : '885px'};
      opacity: ${props.opacity ? props.opacity.toString() : '1'};
    `}
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  text-align: center;
  border-radius: 3px;
  ${(props) =>
    !props.maximize &&
    css`
      @media only screen and (min-width: 600px) {
        margin: 0 auto;
        width: 100%;
      }
      @media only screen and (max-width: 600px) {
        left: 0;
        width: 100%;
      }
    `}
  height: 100%;
  overflow: auto;
`

const DialogContent = styled.div<DialogProps>`
  border-radius: 3px;
  justify-content: center;
  ${(props) =>
    css`
      display: ${props.show ? 'block' : 'none'};
      min-height: ${props.maximize ? '100vh' : ''};
      min-width: ${props.maximize ? '100vw' : ''};
    `}
  ${(props) =>
    css`
      background: ${props.background ? props.background : DIALOG_BG_COLOR.DEFAULT};
      color: ${props.color ? props.color : DIALOG_TEXT_COLOR.DARK};
    `}
button {
    margin-top: 1.5rem;
  }

  display: flex;
  align-items: center;
  text-align: center;
  ${(props) =>
    !props.maximize &&
    css`
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    `}
`
const ContentWrapper = styled.div<DialogProps>`
  ${(props) =>
    css`
      display: ${props.show ? 'block' : 'none'};
    `}
  padding: 16px;
  .mr-1 {
    margin-right: 2rem;
  }

  .mr-1:nth-child(1) {
    margin-left: 2rem;
  }
  max-width: 885px;
`
interface DialogProps {
  children: React.ReactNode
  show: boolean
  background?: DIALOG_BG_COLOR
  color?: DIALOG_TEXT_COLOR
  maximize?: boolean
  opacity?: number
}

export const Dialog = (props: DialogProps) => {
  return (
    <DialogWrapper opacity={props.opacity} maximize={props.maximize} show={props.show}>
      <DialogContent
        maximize={props.maximize}
        show={props.show}
        background={props.background}
        color={props.color}
      >
        <ContentWrapper show={props.show}>{props.children}</ContentWrapper>
      </DialogContent>
    </DialogWrapper>
  )
}
