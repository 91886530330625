import React from 'react'

export const ArrowUp = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="#454545" fill="none" d="M8,14l4.3-4.3l4.3,4.3" />
  </svg>
)

export const ArrowDown = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="#454545" fill="none" d="M16.6,9.7L12.3,14L8,9.7" />
  </svg>
)
