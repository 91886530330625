import React from 'react'

export const FunAndToken = () => {
    return (
<svg width="232" height="45" viewBox="0 0 232 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="53.1818" width="178.364" height="45" rx="22.5" fill="#F5F4EE"/>
<rect width="45" height="45" rx="22.5" fill="#F5F4EE"/>
<path d="M22.9165 39.2726C18.9828 39.2744 15.1801 37.8591 12.2048 35.2859C9.22953 32.7126 7.28077 29.1537 6.7154 25.2609C6.15004 21.368 7.00591 17.4018 9.12629 14.0885C11.2467 10.7752 14.4896 8.33666 18.2613 7.21936C22.033 6.10207 26.0809 6.38083 29.6638 8.00461C33.2467 9.62839 36.1248 12.4885 37.771 16.0612C39.4171 19.6338 39.7212 23.68 38.6276 27.4585C37.5339 31.2371 35.1157 34.4953 31.8157 36.6364C29.1656 38.355 26.0751 39.2706 22.9165 39.2726ZM22.8713 8.43599C19.65 8.44138 16.5224 9.52016 13.9827 11.5018C11.4431 13.4835 9.63632 16.2551 8.84797 19.3784C8.05963 22.5018 8.33467 25.7988 9.62964 28.7483C10.9246 31.6979 13.1656 34.1317 15.9985 35.6652C18.8314 37.1987 22.0945 37.7443 25.2722 37.2159C28.4499 36.6875 31.3608 35.1151 33.5449 32.7473C35.729 30.3795 37.0617 27.3514 37.3324 24.1414C37.6031 20.9315 36.7962 17.723 35.0395 15.0229C33.7273 12.9916 31.9249 11.3235 29.7983 10.1723C27.6717 9.02117 25.2894 8.42403 22.8713 8.43599V8.43599Z" fill="#E84E0F"/>
<path d="M22.3365 32.8285C20.724 32.825 19.1498 32.3374 17.8176 31.4289C16.4854 30.5204 15.4566 29.2329 14.8646 27.733C14.8196 27.6178 14.7977 27.4948 14.8002 27.3711C14.8028 27.2474 14.8296 27.1254 14.8793 27.0121C14.9289 26.8988 15.0004 26.7964 15.0897 26.7107C15.1789 26.6251 15.2841 26.5578 15.3994 26.5128C15.5146 26.4678 15.6376 26.4459 15.7613 26.4484C15.885 26.451 16.0069 26.4778 16.1202 26.5275C16.2336 26.5771 16.336 26.6486 16.4216 26.7379C16.5073 26.8271 16.5746 26.9323 16.6196 27.0476C17.0671 28.1938 17.8494 29.1787 18.8645 29.8741C19.8797 30.5695 21.0807 30.9431 22.3112 30.9464C23.5417 30.9497 24.7446 30.5824 25.7634 29.8923C26.7822 29.2023 27.5697 28.2215 28.0233 27.0777C28.1152 26.8455 28.2955 26.6593 28.5247 26.56C28.7539 26.4608 29.0131 26.4567 29.2454 26.5486C29.4776 26.6405 29.6638 26.8208 29.763 27.05C29.8623 27.2792 29.8664 27.5384 29.7745 27.7707C29.2319 29.1528 28.314 30.3559 27.1242 31.2443C25.9344 32.1326 24.5201 32.6709 23.0408 32.7984C22.8186 32.821 22.57 32.8285 22.3365 32.8285Z" fill="#E84E0F"/>
<path d="M31.0399 22.5324C30.795 22.5316 30.56 22.4357 30.3843 22.265C30.2087 22.0943 30.1062 21.862 30.0984 21.6172C30.0984 20.397 29.4619 19.8547 28.8594 19.8547C28.2832 19.8547 27.7032 20.3707 27.6994 21.5118C27.6974 21.7605 27.5975 21.9985 27.4212 22.174C27.245 22.3496 27.0066 22.4486 26.7579 22.4495C26.6342 22.4491 26.5119 22.4242 26.3979 22.3764C26.2838 22.3287 26.1803 22.2589 26.0932 22.1711C26.0062 22.0833 25.9372 21.9793 25.8904 21.8649C25.8435 21.7504 25.8196 21.6279 25.8201 21.5043C25.8201 19.1994 27.3492 17.9792 28.833 17.9717H28.8443C30.3507 17.9717 31.925 19.207 31.9664 21.5834C31.9694 21.8331 31.8739 22.074 31.7004 22.2538C31.527 22.4336 31.2897 22.5378 31.0399 22.5437V22.5324Z" fill="#E84E0F"/>
<path d="M17.8699 22.1255C17.624 22.1256 17.3879 22.0295 17.212 21.8578C17.036 21.6861 16.9343 21.4524 16.9284 21.2066C16.902 19.9826 16.2618 19.4516 15.6705 19.4516C15.0792 19.4516 14.5331 19.9751 14.5331 21.1125C14.5341 21.2362 14.5106 21.359 14.4639 21.4736C14.4172 21.5883 14.3483 21.6925 14.2611 21.7804C14.174 21.8683 14.0703 21.938 13.956 21.9856C13.8417 22.0332 13.7192 22.0577 13.5954 22.0577V22.0577C13.346 22.0577 13.1067 21.9592 12.9296 21.7835C12.7525 21.6079 12.6521 21.3694 12.6501 21.12C12.6501 18.8114 14.1565 17.5874 15.6629 17.5686C17.1694 17.5497 18.77 18.7775 18.8265 21.1614C18.8295 21.2851 18.8081 21.4081 18.7635 21.5235C18.7189 21.6389 18.6521 21.7443 18.5667 21.8339C18.4814 21.9234 18.3793 21.9952 18.2662 22.0453C18.153 22.0953 18.0312 22.1226 17.9075 22.1255H17.8699Z" fill="#E84E0F"/>
<circle cx="85.6137" cy="22.3409" r="14.1592" stroke="#ED500A" stroke-width="2" stroke-dasharray="5 5"/>
<circle cx="124.159" cy="22.3409" r="14.1592" stroke="#ED500A" stroke-width="2" stroke-dasharray="5 5"/>
<circle cx="162.159" cy="22.3409" r="14.1592" stroke="#ED500A" stroke-width="2" stroke-dasharray="5 5"/>
<circle cx="200.159" cy="22.3409" r="14.1592" stroke="#ED500A" stroke-width="2" stroke-dasharray="5 5"/>
</svg>
    )
}
