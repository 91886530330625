import React, {useContext, useEffect, useRef, useState} from 'react'
import styled, {css, keyframes} from 'styled-components'
import {Trans} from '@lingui/macro'

import {positive, negative} from '../theme'
import {ToolTipContext} from '../contexts/ToolTipContext'
import {ToolTip} from './ToolTip'
import {
  IndicatorAnimationIdl,
  IndicatorAnimationNegative,
  IndicatorAnimationPositive
} from './animations/IndicatorAnimation'

type ScoreChange = 'positive' | 'negative' | 'noChange'

interface WrapperProps {
  noWrap?: boolean
}

interface CircleProps {
  scoreChange: ScoreChange
}

const circle = () => `
  width: 19px;
  height: 19px;
  border: 1px dashed #e84e0f;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: transparent;
  margin: 0 3px;
`

const EmptyCircle = styled.div<CircleProps>`
  ${(props) => circle()}

  animation: ${(props) =>
    props.scoreChange === 'positive'
      ? IndicatorAnimationPositive
      : props.scoreChange === 'negative'
      ? IndicatorAnimationNegative
      : IndicatorAnimationIdl}
  1s cubic-bezier(0.28, 0.84, 0.42, 1);
  animation-iteration-count: 2;
  border-color: #e84e0f;
`

const EmptyCircleGreen = styled.div`
  ${(props) => circle()}
  border-color: #00a19a;
`

const FilledCircle = styled.div<CircleProps>`
  ${(props) => circle()}

  animation: ${(props) =>
    props.scoreChange === 'positive'
      ? IndicatorAnimationPositive
      : props.scoreChange === 'negative'
      ? IndicatorAnimationNegative
      : IndicatorAnimationIdl}
  1s cubic-bezier(0.28, 0.84, 0.42, 1);
  animation-iteration-count: 2;
  background-color: #00a19a;
  border-color: #00a19a;
`

const Wrapper = styled.div<WrapperProps>`
  box-sizing: border-box;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    !props.noWrap &&
    css`
      padding: 0px 9px;
      margin-left: 5px;
      border-radius: 22px;
      background-color: #dfddce;
    `}
`

interface TokenPresenterProps {
  score: number
  label: any
  noWrap?: boolean
  isSummary?: boolean
}
export const TokenPresenter = ({score, label, noWrap, isSummary}: TokenPresenterProps) => {
  const {openToolTipKey, setOpenToolTipKey} = useContext(ToolTipContext)

  return (
    <>
      <Wrapper
        noWrap={noWrap}
        key={score}
        onClick={() => setOpenToolTipKey(openToolTipKey === 'tokens' ? '' : 'tokens')}
        onMouseOver={() => setOpenToolTipKey('tokens')}
        onMouseOut={() => openToolTipKey === 'tokens' && setOpenToolTipKey('')}
      >
        <Tokens score={score} isSummary={isSummary} />
        {openToolTipKey === 'tokens' && (
          <ToolTip>
            <Trans id="func.indicator.tooltip.tokens">Eure Verantwortungspunkte</Trans>
          </ToolTip>
        )}
      </Wrapper>
    </>
  )
}

const Tokens = ({score, isSummary}: {score: number; isSummary?: boolean}) => {
  const [tokens, setTokens] = useState<any>([])
  const [initialAnimation, setInitialAnimation] = useState<boolean>(true)

  const lastScore = useRef(0)

  useEffect(() => {
    let circles = []

    let animation: ScoreChange = 'noChange'
    if (score > lastScore.current) {
      animation = 'positive'
    }
    if (score < lastScore.current) {
      animation = 'negative'
    }

    for (let i = 1; i <= 4; i++) {
      if (i <= score) {
        circles.push({type: 'filled', animation})
      } else if (isSummary) {
        circles.push({type: 'emptyGreen', animation})
      } else if (i === 1 && score === 0 && !initialAnimation) {
        circles.push({type: 'empty', animation: 'negative'})
      } else {
        circles.push({type: 'empty', animation: 'noChange'})
      }

      setTokens([...circles])
      setInitialAnimation(false)
      lastScore.current = score
    }
  }, [score])

  return (
    <>
      {tokens.map((token: any, i: any) => (
        <React.Fragment key={i}>
          {token.type === 'filled' && <FilledCircle scoreChange={token.animation} />}
          {token.type === 'emptyGreen' && <EmptyCircleGreen />}
          {token.type === 'empty' && <EmptyCircle scoreChange={token.animation} />}
        </React.Fragment>
      ))}
    </>
  )
}
