import React from 'react'
import styled from 'styled-components'

import gameOverSuccessHederImage from '../facets/images/gameOverSuccess.jpg'
import {LanguageSelect} from './LanguageSelect'
import {Logo} from './Logo'

interface HeaderImageProps {
  img?: any
  darkGradient?: boolean
}

export const HeaderImage = (props: HeaderImageProps) => {
  return (
    <>
      <Wrap>
        <Header>
          <LanguageSelect />
          <Logo variant="JS" width={45} height={45} />
        </Header>
        <HeaderImg src={props.img ? props.img : gameOverSuccessHederImage} />
        <Gradient darkGradient={props.darkGradient} />
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  position: relative;
  margin: -20px -20px 0px;
  line-height: 0px;
`

const HeaderImg = styled.img`
  width: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
`

const Gradient = styled.div<{darkGradient?: boolean}>`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 436px;
  margin: 0px 0px -416px;
  background: ${(props) =>
    props.darkGradient
      ? `linear-gradient(180.02deg, #b2d6d5 0%, rgba(178, 214, 213, 0) 100%)`
      : `linear-gradient(180.02deg, #e9fffe 0%, rgba(233, 255, 254, 0) 100%)`};
`
