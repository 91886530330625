import React from 'react'

export const Pdf = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8173 32.4688H28.1822C29.0396 32.4688 29.7402 31.7669 29.7402 30.9107V9.08925C29.7402 8.23187 29.0384 7.53125 28.1822 7.53125H11.8173C10.9599 7.53125 10.2593 8.23306 10.2593 9.08925V30.9096C10.2593 31.7669 10.9611 32.4688 11.8173 32.4688ZM10.853 9.08925C10.853 8.55844 11.2853 8.125 11.8173 8.125H28.1822C28.7142 8.125 29.1465 8.55844 29.1465 9.08925V30.9096C29.1465 31.4416 28.713 31.8738 28.1822 31.8738H11.8173C11.2853 31.8738 10.853 31.4404 10.853 30.9096V9.08925Z"
      fill="#454545"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0093 9.08925C10.0093 8.09511 10.8217 7.28125 11.8173 7.28125H28.1822C29.1763 7.28125 29.9902 8.09368 29.9902 9.08925V30.9107C29.9902 31.9049 29.1778 32.7188 28.1822 32.7188H11.8173C10.8229 32.7188 10.0093 31.9049 10.0093 30.9096V9.08925ZM11.8173 7.78125C11.0981 7.78125 10.5093 8.37101 10.5093 9.08925V30.9096C10.5093 31.629 11.0993 32.2188 11.8173 32.2188H28.1822C28.9014 32.2188 29.4902 31.629 29.4902 30.9107V9.08925C29.4902 8.37007 28.9005 7.78125 28.1822 7.78125H11.8173ZM11.8173 8.375C11.4235 8.375 11.103 8.69631 11.103 9.08925V30.9096C11.103 31.3025 11.4235 31.6238 11.8173 31.6238H28.1822C28.5752 31.6238 28.8965 31.3033 28.8965 30.9096V9.08925C28.8965 8.69631 28.5759 8.375 28.1822 8.375H11.8173ZM10.603 9.08925C10.603 8.42057 11.147 7.875 11.8173 7.875H28.1822C28.8525 7.875 29.3965 8.42057 29.3965 9.08925V30.9096C29.3965 31.5798 28.8509 32.1238 28.1822 32.1238H11.8173C11.147 32.1238 10.603 31.5782 10.603 30.9096V9.08925Z"
      fill="#454545"
    />
    <path d="M25.839 27.5293H14.1611V28.123H25.839V27.5293Z" fill="#454545" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9111 27.2793H26.089V28.373H13.9111V27.2793ZM14.4111 27.7793V27.873H25.589V27.7793H14.4111Z"
      fill="#454545"
    />
    <path d="M25.839 23.9883H14.1611V24.582H25.839V23.9883Z" fill="#454545" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9111 23.7383H26.089V24.832H13.9111V23.7383ZM14.4111 24.2383V24.332H25.589V24.2383H14.4111Z"
      fill="#454545"
    />
    <path
      d="M20 39C30.4939 39 39 30.4939 39 20C39 9.50606 30.4939 1 20 1C9.50606 1 1 9.50606 1 20C1 30.4939 9.50606 39 20 39ZM20 1.59375C30.1496 1.59375 38.4062 9.85044 38.4062 20C38.4062 30.1496 30.1496 38.4062 20 38.4062C9.85044 38.4062 1.59375 30.1496 1.59375 20C1.59375 9.85044 9.85044 1.59375 20 1.59375Z"
      fill="#454545"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 20C0.75 9.36799 9.36799 0.75 20 0.75C30.632 0.75 39.25 9.36799 39.25 20C39.25 30.632 30.632 39.25 20 39.25C9.36799 39.25 0.75 30.632 0.75 20ZM20 1.25C9.64413 1.25 1.25 9.64413 1.25 20C1.25 30.3559 9.64413 38.75 20 38.75C30.3559 38.75 38.75 30.3559 38.75 20C38.75 9.64413 30.3559 1.25 20 1.25ZM20 1.84375C9.98851 1.84375 1.84375 9.98851 1.84375 20C1.84375 30.0115 9.98851 38.1562 20 38.1562C30.0115 38.1562 38.1562 30.0115 38.1562 20C38.1562 9.98851 30.0115 1.84375 20 1.84375ZM1.34375 20C1.34375 9.71237 9.71237 1.34375 20 1.34375C30.2876 1.34375 38.6562 9.71237 38.6562 20C38.6562 30.2876 30.2876 38.6562 20 38.6562C9.71237 38.6562 1.34375 30.2876 1.34375 20Z"
      fill="#454545"
    />
    <path
      d="M15.7299 16.5492H16.2441C16.6003 16.5492 16.8735 16.5302 17.0611 16.4934C17.2 16.4637 17.3354 16.4008 17.4696 16.3081C17.6038 16.2155 17.7142 16.0861 17.8009 15.9234C17.8876 15.7607 17.9315 15.5576 17.9315 15.3189C17.9315 15.0078 17.8567 14.7537 17.7059 14.5578C17.5551 14.3606 17.3675 14.2347 17.1442 14.1754C16.9982 14.1362 16.6858 14.1172 16.2061 14.1172H14.9414V18.0217H15.7299V16.5492ZM15.7299 14.7786H16.1111C16.3949 14.7786 16.5837 14.7869 16.6787 14.8036C16.8058 14.8273 16.9115 14.8843 16.9958 14.9769C17.0801 15.0696 17.1205 15.1859 17.1205 15.3284C17.1205 15.4436 17.0908 15.5457 17.0314 15.6312C16.972 15.7191 16.8901 15.7833 16.7844 15.8236C16.6799 15.864 16.4721 15.8854 16.161 15.8854H15.7299V14.7786Z"
      fill="#454545"
    />
    <path
      d="M20.7591 17.9405C20.9919 17.8657 21.1771 17.7624 21.3125 17.6282C21.493 17.4525 21.6331 17.2221 21.7317 16.9383C21.8113 16.7067 21.8516 16.4288 21.8516 16.1082C21.8516 15.7413 21.8089 15.4349 21.7246 15.1855C21.6391 14.935 21.5144 14.7236 21.3517 14.5526C21.189 14.3804 20.9931 14.2605 20.7639 14.1928C20.5929 14.1429 20.3459 14.1191 20.0205 14.1191H18.5801V18.0236H20.0645C20.353 18.0225 20.5858 17.9951 20.7591 17.9405ZM19.3662 17.3646V14.7782H19.7201C20.0419 14.7782 20.2568 14.7901 20.3673 14.815C20.5145 14.8459 20.6368 14.9076 20.7318 14.9979C20.828 15.0881 20.9028 15.2152 20.9551 15.3755C21.0085 15.5382 21.0346 15.7686 21.0346 16.0714C21.0346 16.373 21.0085 16.6117 20.9551 16.7863C20.9016 16.9608 20.8328 17.0879 20.7485 17.1627C20.6641 17.2387 20.5585 17.2933 20.4302 17.3266C20.3328 17.3515 20.1737 17.3634 19.954 17.3634H19.3662V17.3646Z"
      fill="#454545"
    />
    <path
      d="M23.3129 16.3627H24.9434V15.7013H23.3129V14.7786H25.201V14.1172H22.5244V18.0217H23.3129V16.3627Z"
      fill="#454545"
    />
  </svg>
)
