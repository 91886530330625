import React, {useState} from 'react'

import {Scenario, Day, Event} from '../contexts'
import {GameState} from '../hooks'
import {Row, ProgressIndicator, FunScoreIndicator} from '../facets'
import {Trans} from '@lingui/macro'
import {ManualDialog} from '../facets/ManualDialog'
import {ScoreCalculation} from '../util.ts/score'
import {TokenPresenter} from '../facets/TokenPresenter'
import {ManualDialogHandle} from './ManualDialogHandle'

interface GameStatePresenterProps {
  gameState: GameState
  scenario: Scenario
  day: Day
  dayCount: number
  event: Event
  eventCount: number
  maxResponsibilityScore: number
}

export const GameStatePresenter = ({
  gameState,
  scenario,
  day,
  dayCount,
  event,
  maxResponsibilityScore,
  eventCount
}: GameStatePresenterProps) => {
  const [showManual, setShowManual] = useState(false)
  let pastEventCount = 0
  scenario.days.forEach((loopedDay) => {
    if (loopedDay.meta.sortorderInScenario < day.meta.sortorderInScenario) {
      pastEventCount = pastEventCount + loopedDay.events.length
    } else if (loopedDay.meta.sortorderInScenario === day.meta.sortorderInScenario) {
      day.events.forEach((loopedEvent) => {
        if (loopedEvent.sortorderInDay <= event.sortorderInDay) pastEventCount++
      })
    }
  })

  if (
    gameState.screen !== 'victory' &&
    gameState.screen !== 'manual' &&
    gameState.screen !== 'avatars' &&
    gameState.screen !== 'intro'
  ) {
    return (
      <>
        <Row>
          <Row.Item>
            <ManualDialogHandle open={showManual} onClick={() => setShowManual(true)} />
            <ManualDialog closed={() => setShowManual(false)} show={showManual}></ManualDialog>
          </Row.Item>
          <Row.Item flex={1} />
          <Row.Item>
            <FunScoreIndicator
              label={<Trans id="fun">Stimmung</Trans>}
              score={ScoreCalculation.calculateFunScore(pastEventCount, gameState.funScore)}
            />
          </Row.Item>
          <Row.Item>
            <TokenPresenter
              label=""
              score={ScoreCalculation.calculateAmountofResponsibilityTokens(
                maxResponsibilityScore,
                gameState.responsibilityScore
              )}
            />
          </Row.Item>
        </Row>
        <ProgressIndicator
          day={gameState.day}
          dayCount={dayCount}
          value={pastEventCount / eventCount}
        />
      </>
    )
  } else {
    return <></>
  }
}
