import React, {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'
import {Icon, Surface} from '.'
import {api} from '../config'

export interface ItemProps {
  isActive: boolean
  name: string
  id: number
  description?: string
  imagePath?: string
  colored?: 'green' | 'red'
  activeChanged: (id: number) => void
}

export interface ItemWrapperProps {
  isActive: boolean
  colored?: string
}

const ItemWrapper = styled.div<ItemWrapperProps>`
  flex-grow: 1;
  box-sizing: border-box;
  margin-top: 0.25rem;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #454545;
  cursor: pointer;
  display: flex;
  border-radius: 4px;
  padding: 16px;

  ${(props) =>
    css`
      color: ${props.colored === 'green' ? 'white' : props.colored === 'red' ? 'white' : '#454545'};
      background-color: ${props.colored === 'green'
        ? '#00A19A'
        : props.colored === 'red'
        ? '#E20033'
        : props.isActive
        ? '#DDF6DD'
        : 'rgba(240, 238, 233, 0.2)'};
      border: ${props.isActive
        ? '2px solid #54B156'
        : props.colored === 'green'
        ? '2px solid rgba(0, 109, 101, 0.2)'
        : props.colored === 'red'
        ? '2px solid rgba(191, 18, 43, 0.2)'
        : '2px solid rgba(213, 205, 198, 0.5)'};
    `}

  .avatar {
    padding-right: 16px;
    display: flex;
    justify-content: start;
    align-items: center;

    img {
      max-height: 48px;
      border-radius: 100%;
    }
  }

  .textWrapper {
    flex-grow: 4;
  }

  .flexTextWrapper {
    flex-grow: 4;
    display: flex;
    align-items: center;
    text-align: left;
  }

  .textWrapper > div {
    justify-content: start;
    display: flex;
    text-align: left;
  }

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 0.5rem;
  }

  .name {
    ${(props) =>
      css`
        font-weight: ${props.colored && (props.colored === 'green' || props.colored === 'red')
          ? 'normal'
          : 'bold'};
      `}
    flex-grow: 1;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    > div {
      height: 35px;
      width: 35px;
      ${(props) =>
        css`
          border: ${props.colored === 'green'
            ? '4px solid rgba(255, 255, 255, 0.2)'
            : '4px solid rgba(191, 18, 43, 0.5)'};
        `}
    }
  }
`

export const Item = (props: ItemProps) => {
  const [active, setActive] = useState(props.isActive)

  useEffect(() => {
    setActive(props.isActive)
  }, [props.isActive])

  const toggleActive = () => {
    if (props.activeChanged) {
      props.activeChanged(props.id)
    }
  }

  return (
    <ItemWrapper onClick={toggleActive} isActive={active} colored={props.colored}>
      {props.imagePath || props.colored ? (
        <div className="avatar">
          {props.imagePath ? (
            <img src={api.base + '/static/' + props.imagePath} alt="avatar"></img>
          ) : (
            <div className="icon">
              <Surface variant={props.colored === 'green' ? 'go' : 'dontGo'}>
                <Icon
                  width={15}
                  height={15}
                  variant={props.colored === 'green' ? 'go' : 'dontGo'}
                />
              </Surface>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
      <div className={props.description ? 'textWrapper' : 'flexTextWrapper'}>
        <div className="name">
          {' '}
          <p>{props.name}</p>
        </div>
        {props.description ? <div className="description">{props.description}</div> : ''}
      </div>
    </ItemWrapper>
  )
}
