import React from 'react'

export const GSLogo = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.2581 17.126C33.2468 17.1373 33.2392 17.1599 33.2204 17.1523C33.1865 17.141 33.1865 17.0883 33.2204 17.0732C33.2317 17.0883 33.2732 17.0883 33.2581 17.126ZM17.4876 5.30468C18.0981 6.67636 18.4636 8.13094 18.3505 9.6232L15.7994 9.61943V16.2705H9.17464V21.7987H15.7994V28.3933H18.3769L18.3732 37.9234L8.43982 37.9309C5.72661 37.7199 3.20183 36.2239 1.61159 33.9064C0.266296 31.9845 0.0326589 29.618 0.0100489 27.2854C-0.0125611 24.8322 0.0100489 14.522 0.0100489 14.522C0.0213539 12.3025 -0.0125611 10.9007 0.0364272 8.5869C0.243686 5.10496 2.81369 1.61925 6.37854 0.46614C8.74882 -0.351589 11.6128 -0.0049019 13.7079 1.21227C15.415 2.14305 16.6322 3.66923 17.4876 5.30468ZM35.9562 4.8148C36.8041 6.40127 37.26 8.10079 37.147 9.8606C37.1508 10.4975 37.147 16.2668 37.147 16.2668H31.7093C33.4126 17.1222 34.9124 18.4524 35.8507 20.1331C36.7702 21.6856 37.1319 23.4002 37.1282 25.2806C37.1206 27.6999 37.4372 30.0626 36.431 32.2634C35.391 34.9577 32.8285 37.0529 30.0964 37.735C27.1006 38.4849 23.939 37.6106 21.7232 35.5267C19.7825 33.6765 18.6784 31.0989 18.7538 28.382L21.3614 28.3895V21.7949H27.892C27.9108 19.8429 27.892 16.2668 27.892 16.2668H21.3577V9.61566L18.7048 9.61943C18.6633 7.46017 19.4434 5.0296 20.8565 3.36399C23.0647 0.590495 26.9951 -0.690739 30.4959 0.371932C32.4818 0.959792 34.4074 2.35031 35.504 4.10258L35.9562 4.8148Z"
      fill="#E30613"
    />
  </svg>
)
