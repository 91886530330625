import {Person} from '../contexts'
import {i18n} from '@lingui/core'

export const replaceByLeaderName = (toReplace: string, leaders: Person[]) => {
  const numberRegex = new RegExp('[0-9]')
  const regex = new RegExp('\\$LEITER[0-9]')
  const personStrings = regex.exec(toReplace)
  let result = toReplace
  personStrings?.forEach((str) => {
    for (let i: number = 0; i <= countInstances(result, str); i++) {
      const stringToParse = str ? numberRegex.exec(str.toString()) : '-1'
      const n = stringToParse ? stringToParse.toString() : '-1'
      const no: number = str ? parseInt(n) - 1 : -1
      if (no >= 0) {
        result = result.replace(regex, leaders[no].name[i18n.locale])
      }
    }
  })
  return result
}

const countInstances = (string: string, word: string) => string.split(word).length - 1
