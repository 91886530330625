import React from 'react'
import {Router} from '@reach/router'

import {Scenario, ScenariosPage} from './pages'
import {ScenariosContext, Scenarios} from './contexts'
import {useScenarios} from './hooks'
import {AppLayout} from './facets'
import {i18n} from '@lingui/core'
import {I18nProvider} from '@lingui/react'
import {Summary} from './pages/Summary'
import {Trans} from '@lingui/macro'
import {createGlobalStyle} from 'styled-components'
import {MessageCatalog} from './util.ts/i18n'
import ToolTipProvider from './contexts/ToolTipContext'

// Fallback language
const navLang = navigator.language
let initialLang =
  ['fr', 'fr-be', 'fr-ca', 'fr-fr', 'fr-lu', 'fr-mc', 'fr-ch'].indexOf(navLang) !== -1
    ? 'fr'
    : ['it', 'it-ch'].indexOf(navLang) !== -1
    ? 'it'
    : 'de'

// Language by domain name
const domain = window.location.hostname
if (domain == (process.env.REACT_APP_DOMAIN_GERMAN || "safecampcheck.jugendundsport.ch")) {
  initialLang = 'de'
} else if (domain == (process.env.REACT_APP_DOMAIN_FRENCH || "safecampcheck.jeunesseetsport.ch")) {
  initialLang = 'fr'
} else if (domain == (process.env.REACT_APP_DOMAIN_ITALIAN || "safecampcheck.gioventuesport.ch")) {
  initialLang = 'it'
}

i18n.load(initialLang, MessageCatalog.getCatalog(initialLang))
i18n.activate(initialLang)

export interface GlobalBodyStyleProps {
  light: boolean
}
export const GlobalBodyStyle = createGlobalStyle<GlobalBodyStyleProps>`
  body {
    background-color: ${(props) => (props.light ? '#fff' : '#f5f4ee')} !important;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 16px;
  }
`

export const GlobalNoPaddingBodyStyle = createGlobalStyle<GlobalBodyStyleProps>`
  body {
    background-color: ${(props) => (props.light ? '#fff' : '#f5f4ee')} !important;
    padding: unset !important;
  }
`

export default function App() {
  const scenarios = useScenarios()
  const isNotSummaryPage = !window.location.pathname.startsWith('/summary')

  if (!scenarios && isNotSummaryPage) {
    return (
      <I18nProvider i18n={i18n}>
        <AppLayout>
          <p>
            <Trans id="app.loading">Loading…</Trans>
          </p>
        </AppLayout>
      </I18nProvider>
    )
  } else {
    return (
      <I18nProvider i18n={i18n}>
        <AppLayout>
          <ScenariosContext.Provider value={scenarios as Scenarios}>
            <ToolTipProvider>
              <Router>
                <ScenariosPage path="/" />
                <Scenario path="scenario/:id" />
                <Summary path="summary/:id" />
              </Router>
            </ToolTipProvider>
          </ScenariosContext.Provider>
        </AppLayout>
      </I18nProvider>
    )
  }
}
