import {Trans} from '@lingui/macro'
import React, {useContext, useEffect, useState} from 'react'
import styled from 'styled-components'
import {t} from '@lingui/macro'
import {
  GameStateContext,
  CurrentScenarioContext,
  Scenario,
  EventAction,
  Event,
  AdditionalInfo
} from '../contexts'
import {FunScoreIndicator, Row, Pdf, Link} from '../facets'
import {Accordion} from '../facets/Accordion'
import {ActionDecision} from '../facets/Decision'
import {GameState} from '../hooks'
import {replaceByLeaderName} from '../util.ts/name'
import {i18n} from '@lingui/core'
import {ModalButton, SnowSafetyPresenter} from './SnowSafetyPresenter'

import {TokenPresenter} from '../facets/TokenPresenter'
import {Impact} from '../util.ts/impact'
import {ScoreCalculation} from '../util.ts/score'
export interface EventSummaryPresenterProps {
  gameState?: GameState
  additionalInfo: AdditionalInfo[]
}

const LinkList = styled.div`
  margin: 12px 0 8px;
  .text-left {
    text-align: left;
  }
  a {
    font-size: 16px;
    line-height: 20px;
    text-decoration: underline;
    color: #454545;
    display: flex;
    align-items: center;
    margin: 4px 0px;
    svg {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
`
export const LinkIcon = styled.div`
  svg {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
`
export const LinkTitle = styled.div``

const Sort = styled.div`
  margin-bottom: 25px;
  label,
  select {
    font-size: 14px;
    line-height: 24px;
  }
  select {
    font-weight: 600;
  }
`
const SortSelect = styled.select`
  display: inline;
  background-color: transparent;
  border-width: 0;
  outline: 0;
  margin-left: 5px;
`

export const EventSummaryPresenter = (
  props: EventSummaryPresenterProps
) => {
  const scenario: Scenario = useContext(CurrentScenarioContext)
  const inGameGameState = useContext(GameStateContext).gameState
  const gameState = props.gameState ? props.gameState : inGameGameState

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalSrc, setModalSrc] = useState('');

  const allEvents = scenario.days.flatMap((x) => {
    return x.events.sort((a: Event, b: Event) => a.sortorderInDay - b.sortorderInDay)
  })
  const allActions = allEvents.flatMap((x) => x.meta.actions)

  const [cards, setCards] = useState<any>([])

  // Aggregate data for table rows
  useEffect(() => {
    let rows = []
    let counter = 0
    let eventHistory = []

    for (const event of allEvents) {
      eventHistory.push(event)
      const action = allActions.find((a) => a.id === Number(gameState.decisions[counter]))
      const row = {
        day: counter,
        title: event.meta.shorthand[i18n.locale],
        decision: gameState.decisions[counter],
        imagePath: event.meta.imagePath,
        summaryInfoText: event.meta.summaryInfoText[i18n.locale],
        additionalInfo: props.additionalInfo.filter((a) => a.eventId === event.meta.id),
        action,
        eventFunScore: ScoreCalculation.calculateFunScore(
          eventHistory.length,
          gameState.funScoreHistory[counter]
        ),
        eventResponsibilityScore: ScoreCalculation.calculateAmountofResponsibilityTokens(
          ScoreCalculation.calculateMaxResponsibilityScore(allEvents),
          gameState.responsibilityScoreHistory[counter]
        )
      }
      rows.push(row)
      counter++
    }

    setCards(rows)
  }, [])

  const displayFunAndResponsibilitySummary = (
    eventFunScore: number,
    eventResponsibilityScore: number
  ) => {
    return (
      <>
        <TokenPresenter noWrap isSummary score={eventResponsibilityScore} label={''} />
        <FunScoreIndicator noWrap isSummary score={eventFunScore} label={''} />
      </>
    )
  }

  const displayActionDecision = (action: EventAction, recommendation: string) => {
    return (
      <ActionDecision
        funImpact={action.funImpact}
        responsibilityImpact={action.responsibilityImpact}
        text={<Trans id="summary.your.decision">Richtige Entscheidung</Trans>}
        recommendation={recommendation}
        decision={replaceByLeaderName(action.name[i18n.locale], gameState.leaders)}
      ></ActionDecision>
    )
  }

  const getLinks = (additionalInfo: AdditionalInfo[]) => {
    return (
      <>
        <LinkList>
          <h4 className="text-left"><Trans id="summary.additional.information">Weiterführende Inputs &amp; Ideen</Trans></h4>
          {additionalInfo.map((info: AdditionalInfo) => (
            <Row justifyContent="start" marginTop=".25rem">
              {info.url.indexOf('snowsafety.ch') == -1 ? getWeblink(info) : getSnowSafetyLink(info)}
            </Row>
          ))}
        </LinkList>
      </>
    )
  }

  const getWeblink = (info: AdditionalInfo) => {
    return (
      <a target="_blank" href={info.url} rel="noopener noreferrer">
        <LinkIcon>{info.url.indexOf('.pdf') != -1 ? <Pdf /> : <Link />}</LinkIcon>
        <LinkTitle>{info.name[i18n.locale]}</LinkTitle>
      </a>
    )
  }

  const getSnowSafetyLink = (info: AdditionalInfo) => {
    return (
      <ModalButton
        setModalIsOpen={setModalIsOpen}
        setModalSrc={setModalSrc}
        info={info}
      />
    )
  }

  const sortCards = (sortBy: string) => {
    const sortByDay = (a: any, b: any) => {
      if (a.day < b.day) {
        return -1
      }
      if (a.day > b.day) {
        return 1
      }
      return 0
    }
    const sortByFails = (a: any, b: any) => {
      const negativeFunImpactA =
        a.action?.funImpact === Impact.LESS.id || a.action?.funImpact === Impact.LEAST.id
      const negativeResponsibilityImpactA =
        a.action?.responsibilityImpact === Impact.LESS.id ||
        a.action?.responsibilityImpact === Impact.LEAST.id

      let winsA = 0
      if (negativeFunImpactA) winsA += 1
      if (negativeResponsibilityImpactA) winsA += 1

      const negativeFunImpactB =
        b.action?.funImpact === Impact.LESS.id || b.action?.funImpact === Impact.LEAST.id
      const negativeResponsibilityImpactB =
        b.action?.responsibilityImpact === Impact.LESS.id ||
        b.action?.responsibilityImpact === Impact.LEAST.id

      let winsB = 0
      if (negativeFunImpactB) winsB += 1
      if (negativeResponsibilityImpactB) winsB += 1

      if (winsA > winsB) {
        return -1
      }
      if (winsA < winsB) {
        return 1
      }
      return 0
    }

    if (sortBy === 'wins') {
      setCards([...cards.sort(sortByFails).reverse()])
    } else if (sortBy === 'fails') {
      setCards([...cards.sort(sortByFails)])
    } else {
      setCards([...cards.sort(sortByDay)])
    }
  }

  return (
    <>
      <Sort>
        <label htmlFor="sort">{t({id: 'summary.sort.label', message: 'Sortieren nach: '})}</label>
        <SortSelect
          id="sort"
          onChange={(e) => {
            sortCards(e.target.value)
          }}
        >
          <option value="days">{t({id: 'summary.sort.days', message: 'Spielablauf'})}</option>
          <option value="wins">{t({id: 'summary.sort.wins', message: 'Erfolge'})}</option>
          <option value="fails">{t({id: 'summary.sort.fails', message: 'Misserfolge'})}</option>
        </SortSelect>
      </Sort>

      {cards.map((row: any) => {
        return (
          <>
            <Row marginTop=".5rem">
              <Accordion
                title={row.title.toString()}
                image={row.imagePath}
                funImpact={row.action?.funImpact}
                responsibilityImpact={row.action?.responsibilityImpact}
                footer={displayFunAndResponsibilitySummary(
                  row.eventFunScore,
                  row.eventResponsibilityScore
                )}
              >
                {row.action && displayActionDecision(row.action, row.summaryInfoText)}
                {row.additionalInfo &&
                  row.additionalInfo.length > 0 &&
                  Array.isArray(row.additionalInfo)
                  ? getLinks(row.additionalInfo)
                  : ''}
              </Accordion>
            </Row>
          </>
        )
      })}
      <SnowSafetyPresenter modalIsOpen={modalIsOpen} modalSrc={modalSrc} setModalIsOpen={setModalIsOpen}></SnowSafetyPresenter>
    </>
  )
}
