import React, {Dispatch} from 'react'

import {GameState, GameStateActionI} from '../hooks'
import {Day, Event} from '../contexts'

/*
  GameStateContext provides the state of an ongoing game and the means to
  change it via the `dispatch` function to all game components.
*/

interface GameStateContextI {
  gameState: GameState
  day: Day
  dayCount: number
  event: Event
  eventCount: number
  maxResponsibilityScore: number
  dispatch: Dispatch<GameStateActionI>
}
export const GameStateContext = React.createContext<GameStateContextI>({} as GameStateContextI)
