import React from 'react'

export const ManualTitleVector = () => (
  <svg
    id="bb93bde8-8864-40d7-9956-51c85650ff29"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="282.4px"
    height="205.1px"
    viewBox="0 0 800.4 581.3"
  >
    <defs>
      <linearGradient
        id="bd79b8df-c4c6-4e51-995b-6e5e0ae98a90"
        x1="422.76"
        y1="512.47"
        x2="422.76"
        y2="21.07"
        gradientTransform="matrix(1, 0, 0, -1, 0, 581.89)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#73bdb9" />
        <stop offset="1" stop-color="#73bdb9" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="a32dc93f-4ee8-4e5a-ab44-0a58e4a235ea"
        x1="274.6"
        y1="81.42"
        x2="300.79"
        y2="81.42"
        gradientTransform="matrix(1, 0, 0, -1, 0, 581.89)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#006d65" />
        <stop offset="1" stop-color="#00a098" />
      </linearGradient>
      <clipPath id="b528e013-2588-476d-a00c-9786b74526da">
        <ellipse cx="404.3" cy="136.3" rx="132" ry="110.5" fill="none" />
      </clipPath>
      <linearGradient
        id="a68cda88-6683-4088-9587-a455ed97a859"
        x1="404.26"
        y1="525.5"
        x2="404.26"
        y2="358.73"
        gradientTransform="matrix(1, 0, 0, -1, 0, 581.89)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#00a3a3" />
        <stop offset="1" stop-color="#93d0d2" />
      </linearGradient>
      <linearGradient
        id="a04e19e0-ca0b-494a-a304-cb7389d8208d"
        x1="410.71"
        y1="425.35"
        x2="410.71"
        y2="264.53"
        gradientTransform="matrix(1, 0, 0, -1, 0, 581.89)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#93d0d2" />
      </linearGradient>
      <linearGradient
        id="a4d7b3bc-45a3-410a-8536-6fb5ea360685"
        x1="389.92"
        y1="385.22"
        x2="389.92"
        y2="319.43"
        xlinkHref="#a04e19e0-ca0b-494a-a304-cb7389d8208d"
      />
      <linearGradient
        id="afcee383-7544-4dc4-b334-400048996618"
        x1="278.85"
        y1="557.97"
        x2="330.73"
        y2="487.01"
        xlinkHref="#a04e19e0-ca0b-494a-a304-cb7389d8208d"
      />
    </defs>
    <path
      d="M92.2,320.3c-58.6,82,45,110.1,104.1,113.9,24.4,55.3-63.1,34.8,283,109.1S816.1,434.2,692.4,408.2c-39-11.8-21.6-36.4-8.1-47.2,25.3-17,68.3-60.3,27.7-120.5C655.3,156.6,476.1,92.4,367.1,81c-229-40.3-238.8,31-219.8,117.4C150.4,264.7,122.4,278,92.2,320.3Z"
      fill="url(#bd79b8df-c4c6-4e51-995b-6e5e0ae98a90)"
    />
    <path
      d="M333.8,495.3c2.3,13.3,13.4,25.1,20,36.3,1.8,3.3,3.2,6.4,5.1,9.5-6.7,1.4-14.7.8-21.3,3.1a189.4,189.4,0,0,1-11.7-38.9"
      fill="#ce661b"
    />
    <path
      d="M321.4,356.6c22.6,23.7,7.2,63.8-16.5,80.1-14,9.7-37.5,14.2-52,2.7s-12.1-38.5,2.2-49.2"
      fill="#be172c"
    />
    <path
      d="M318.1,451.4c5.2-7.6,2.8-22.9-5.1-27.9-6.1-3.8-15.9-4.8-22.9-6a79.8,79.8,0,0,0-25.8-.6c-16.5,2.3-22.1,12.2-19.1,29.5,1.8,10.5,13.8,14.2,22.8,19.1s16.4,9,26,10.6c2.7.5,8.8,2.8,10.5,1.4"
      fill="#f7b032"
    />
    <path
      d="M318.1,451.4c5.2-7.6,2.8-22.9-5.1-27.9-6.1-3.8-15.9-4.8-22.9-6a79.8,79.8,0,0,0-25.8-.6c-16.5,2.3-22.1,12.2-19.1,29.5,1.8,10.5,13.8,14.2,22.8,19.1s16.4,9,26,10.6c2.7.5,8.8,2.8,10.5,1.4"
      fill="#f7b032"
    />
    <path
      d="M332.6,530.8c4-11.6-8-14.9-2.1-26.8,4.2-8.5,9.6-24,8.3-29.5l-8.2-21c.8-1.9,1.4-12-7.7-14.1l-25-8.3c-1.8,0-3.6-.2-5.4-.1l-46.2,3c-9.2.4-6,7-6.5,14.5,0,0,5.8,52.7,6.6,79.8.1,3.2-1.5,5.1-2.6,8.1-4,10.7.5,3.1-1.5,15.5l91.6-1.4s2.5-3.4-1.4-12A10.8,10.8,0,0,1,332.6,530.8Z"
      fill="#f7b032"
    />
    <path
      d="M307.1,441.2h0a16.1,16.1,0,0,1-25.7.7h0a6.7,6.7,0,0,1-1.5-3.8l-2.7-50.7,28.8-1.6,2.7,49.8A9.2,9.2,0,0,1,307.1,441.2Z"
      fill="#ef8848"
    />
    <path
      d="M306.1,423.3h0c-6.4,6.1-19.1,6.5-25.7,1h0a4.5,4.5,0,0,1-1.4-2.6l-1.8-34.3,28.8-1.6,1.8,33.7A4.6,4.6,0,0,1,306.1,423.3Z"
      fill="#e7683b"
    />
    <path
      d="M323.8,363.6a38.3,38.3,0,1,1-73.9,19.9c-5.5-20.4,7.1-39.7,27-46.9C291.1,331.6,319.2,347,323.8,363.6Z"
      fill="#ef8848"
    />
    <path
      d="M313.5,369.7h0c-1.6.4-3.6,0-4.4-.9l-10.5-11.7c-.9-.9-.2-2,1.4-2.4h0c1.7-.4,3.6-.1,4.5.8L315,367.2C315.8,368.1,315.1,369.2,313.5,369.7Z"
      fill="#f4ac79"
    />
    <path
      d="M315.6,374l-7,1.9a1.6,1.6,0,0,1-2-1.2l-.2-.7a1.5,1.5,0,0,1,1.1-2l7.1-1.9a1.6,1.6,0,0,1,2,1.1l.2.8A1.6,1.6,0,0,1,315.6,374Z"
      fill="#e7683b"
    />
    <path
      d="M286.6,371.8a3.2,3.2,0,0,1,3.9,2.3,3.1,3.1,0,0,1-2.2,3.9,3.2,3.2,0,0,1-4-2.2A3.3,3.3,0,0,1,286.6,371.8Z"
      fill="#5d1211"
    />
    <path
      d="M319.4,360.3c1.4-.4,2.9.7,3.4,2.4s-.3,3.4-1.7,3.8a2.8,2.8,0,0,1-3.3-2.4C317.3,362.4,318,360.7,319.4,360.3Z"
      fill="#5d1211"
    />
    <path d="M276.7,361.5s-.8,13.5-5.9,20.1-12,17.5-12,17.5l-2.4-3L267.5,378Z" fill="#cb5e16" />
    <path
      d="M281.7,386.8c-2.4-.9-6-.8-8.5-.4s-4.1,2.2-1.8,4.1,8.8,3.1,11.3.6a2.7,2.7,0,0,0-1.3-4.5"
      fill="#f18f4e;opacity:0.5199999809265137;isolation:isolate"
    />
    <path
      d="M280.7,438.2c-2-4.7-1.3-12.3-2.4-17.3-4.7,1.6-22.1-.5-17.1,9.8,1.2,2.3,5.9,4.7,8.1,5.8,9,4.9,17.9,12.6,26.5,18.5l4.7-.7-1.3-5.3C295.6,444.7,282.9,443.3,280.7,438.2Z"
      fill="#ce661b"
    />
    <path
      d="M299.6,454.1c4-1.4,11.8-12.4,13.2-17a8.2,8.2,0,0,0-5.5-10.6c1.9,8-5.1,16-9.3,22.1"
      fill="#ce661b"
    />
    <path
      d="M298,454.5a230.1,230.1,0,0,1,6.6,24.2c1.3,6.2,3.1,14.8,2.5,21.1-1.2,10.8-11.2,12.9-15.6,21.8-3,6.1-2.2,16.1-1.9,22.7"
      fill="none;stroke:#fff;stroke-miterlimit:10;stroke-width:4.148200035095215px"
    />
    <path
      d="M258.3,341.5c9.6,14.4,24.6,21,42.7,20.4,6-.2,17.3-1.6,22.2-5.3-.4-7.4-11.4-18.4-17.2-22.7s-18.2-8.2-26.4-7.7-23.1,8-21.7,18.6a6.3,6.3,0,0,0,3.8-.3"
      fill="#e20335"
    />
    <path
      d="M267.5,341.4c4.6,12.6,12.2,25.4,12.3,39.4a62.4,62.4,0,0,1-6.4,27.1c-2.7,5.5-1.6,9.5-1.2,15.7.5,9.8-2.5,18.7-11.3,24.4-12.5,8.3-27.1,5.2-36.4-6-7.7-9.2-8.9-21.5-.5-30.3,5.7-5.9,14.5-8.3,19.3-14.5s.7-18.9.2-27.7c-.9-19.3,9.6-34.4,28.7-37.5"
      fill="#e20335"
    />
    <path d="M266.4,475.9c5.4,17.3,8.3,36.8-6.4,50.5-1.6-18.1-4.2-37.1,4.8-53.8" fill="#ce661b" />
    <path
      d="M239.2,445.4c-.5,5.3-3.2,10.7-3.9,16.1-1.6,12.2-2.3,24.7-3.5,37s-2.2,24.8.4,36.6,17.7,7,27.7,7.3c11.5.4,22.9-1.1,34.2-1.3,3.2,0,12.4,2.6,15.3-.5,4.4-4.7,1.3-18.3-1.2-23.8-10.3-.6-20.8-1.6-31.1-1.7-4.5-.1-14.7.7-18.6-1.6s-1.2-17.4-.7-23.2c.8-8.6,3.9-17.2,3.8-25.9.9-1.2.5,0,1,0"
      fill="#f7b032"
    />
    <path
      d="M314.7,541c-.3-4.8,1.7-9.1,2.8-13.5.4-1.8.4-5.6,2-6.7,4.6-3.1,22.7,4.9,22.4,10.5-3.3.5-5.4-.1-8.6-.9a13.8,13.8,0,0,1,7.3,5.7c-2.2.1-4.3.1-6.5,0,2.1.7,3.1,2.9,3.9,4.4"
      fill="#ef8848"
    />
    <path
      d="M317.5,382.8c-2.2,1.7-7.1,1.5-9.6,3.4"
      fill="none;stroke:#b55217;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.691100001335144px"
    />
    <path
      d="M501.9,379.1c-9.8,9.1-50.9,92.4-48.8,98.7,1.2,3.7,18.3,9,22.8,11.6s18.8,3.8,22.8,6.8c1.9-5.6,6.5-16.9,8.9-22.3"
      fill="#be172c"
    />
    <path
      d="M512.7,551.5H660.6c4.1,0,7.5-2.9,7.6-6.7l-.5-30.3,6.7-121.8c0-10.1-10.2-18.7-24.1-20.1l-48-8.6a45,45,0,0,0-8.2.1l-77.5,8.4c-13.7,1.5-23.9,10-23.9,20.1l10.7,129,1.7,23.4C505.3,548.7,508.7,551.5,512.7,551.5Z"
      fill="#e20335"
    />
    <path
      d="M632.9,349.9c7.5,3.2,9.8,15.8,12.4,22.7-23.9,16.8-52.2,40.1-84,33.1-13.8-3.1-23.9-14.5-34.2-23.9-8-7.3-10.4-12.8-5.3-22.8"
      fill="#e20335"
    />
    <path
      d="M562.1,389.7c15.4,2.3,40.2-8.8,53.8-17.5,8-5.1,12.7-10.9,16.9-18.8a3.5,3.5,0,0,0-1.6-4.9c-29-13.1-69.2-15-97,1.4-2.2,1.2-11.4,6.7-11.9,9.2-1.2,5.5,11,14.2,14.4,17.1C544.9,383.4,552.8,386.5,562.1,389.7Z"
      fill="#bf172e"
    />
    <path
      d="M550.6,383.5h0c11.8,6.5,34.4,6.2,45.5-.5h0c1.4-.9,2.2-1.9,2.2-3V319.6a4.6,4.6,0,0,0-4.6-4.5h-42a4.5,4.5,0,0,0-4.5,4.5v59.7C547.2,380.8,548.4,382.3,550.6,383.5Z"
      fill="#bf172e"
    />
    <path
      d="M550.6,357.7h0c11.8,6.5,34.4,6.3,45.5-.5h0a3.7,3.7,0,0,0,2.2-3V293.8a4.6,4.6,0,0,0-4.6-4.5h-42a4.5,4.5,0,0,0-4.5,4.5v59.7C547.2,355,548.4,356.5,550.6,357.7Z"
      fill="#e7683b"
    />
    <ellipse cx="573.7" cy="296.7" rx="56.1" ry="54.8" fill="#ef8848" />
    <path
      d="M546.3,304.4h0c3.8,0,7.5-2.6,8.3-6l9.7-42.7c.7-3.4-1.7-6.1-5.5-6.2h0c-3.8,0-7.5,2.7-8.2,6l-9.7,42.8C540.1,301.6,542.6,304.4,546.3,304.4Z"
      fill="#f4ac79"
    />
    <path
      d="M543.2,308.3H563a2.1,2.1,0,0,0,2.1-2.1h0a2.1,2.1,0,0,0-2.1-2.1H543.2a2.1,2.1,0,0,0-2.1,2.1h0A2.1,2.1,0,0,0,543.2,308.3Z"
      fill="#e7683b"
    />
    <path
      d="M531.3,259.2c8.5,2.9,22.3,1.1,31.4,1.1,6.5-.1,12.8-1.1,19.3-1.1s6.5-1.2,13.3-.6c10,.9,8.4,8.1,8.9,17.7s-3.5,24.3,8.9,23.6c0-3.9,7.3-8.2,8.5-11.8,3.6,0-6.9,14.9,7.6,18.4,2.4-14.1-.9-37.5-5.3-47.4s-15.7-15.3-27.2-18.6c-16.2-4.7-33.9-5.2-49.3,1.9-8.5,4-12.2,9.8-16.1,17.2"
      fill="#5d1211"
    />
    <path
      d="M611.9,290.7c.2,5.8-.3,11.6-.6,17.3-2.9.4-5.8,1-8.8.6-.2-9.7,1.1-19.5,1.1-29.3"
      fill="#781c1d"
    />
    <path d="M613.2,284.5c9.6-.6,13.3,7.3,12,15.6s-5.7,9.8-13.6,11" fill="#e7683b" />
    <path
      d="M611.5,248.9c-3-4.5-7.7-9.4-8.9-14.8,8.8-1.3,12.3,9.5,13.7,15.8-.1-3,1.7-6.1,1.9-9.3,6.2.3,5.6,15.1,2.1,17.7"
      fill="#5d1211"
    />
    <path
      d="M430.5,397.2l-27.9,19,38.5,68.4s2.3,11.2,20.4,19.5,33.8-10.6,33.8-10.6Z"
      fill="#ef8848"
    />
    <path
      d="M655.7,472.6c7-6.3,10.8-16,15.1-23.9a109.9,109.9,0,0,0,8.6-18.8,60.4,60.4,0,0,0,1.7-30.7c-1-4.8-2.5-10.9-5.5-15-4.5-6.1-14.5-5.7-21.6-4.3-21,4.3-33.7,22.4-43.3,38.1"
      fill="#e20335"
    />
    <path
      d="M597.1,381.8c5.2-4.5,5.8-11,5.5-17.7-.1-3.4-.6-10-4-11.9-.3,3.8-1.1,5.4-2.6,8.7"
      fill="#bf172e"
    />
    <path
      d="M549.3,381.8c-6.4-3-7.7-8.9-7.4-15.4s.7-12.1,5.5-14.9c-.3,2.1,0,9.4-.7,11.5"
      fill="#bf172e"
    />
    <path d="M629.2,274.7l-99.4-12.8s4.1-15.5,5.3-15.5,90.5,11.8,90.5,11.8Z" fill="#e20335" />
    <polygon points="627 263.9 532.6 251.2 531.3 257.1 628.3 270.5 627 263.9" fill="#ecc391" />
    <path
      d="M479.5,457.4c5.1,15.1-10.7,24.6-23.9,26.1-6.9.7-12.3-2.1-17.8-5.4a9,9,0,0,0-1.7,8.9c.6,2.1,3.5,3.6,4.3,6.2s-.4,3.8.1,5.8c2.4,9.1,11.2,12,20.3,12.6s42.7-11.9,37.9-26.8"
      fill="#be172c"
    />
    <path
      d="M563.1,469.5c15.6,8.8,25.5-9.8,32.3-19.5,2.6-3.5,7.2-12.7,10.7-14.9s.9-1.3,4.1.1c8.8,3.8,8.6,16.3,7.1,23.6-3.4,16.8-16.9,31.6-32.6,39-5.2,2.4-14.5,5.8-20.5,4.4-2.5-.6-8-2.4-8.2-4.7"
      fill="#e20335"
    />
    <line
      x1="582.4"
      y1="516.7"
      x2="476.1"
      y2="525.9"
      fill="none;stroke:#ef8848;stroke-miterlimit:10;stroke-width:38.157798767089844px"
    />
    <path
      d="M572.1,494.4c4.9,13.7-8.6,31.4-5.4,45.3,1.8,7.6,6.5,4.5,13,4.7,1.8.1,2.5-.7,4.1-.4s1.7,2.7,3,2.8c3.3.2,5.5-2.5,8-4.9,4.1,3.4,10.7.6,12.6-3.7,2.9-6.6-2-21.3-4-28.1-1-3.6-2.3-17.4-5.2-19.5s-6.2,2.7-9.1,4.6c-.9-1.6-2.9-3-3.8-4.5-1,1.1-3.2,1.7-4.3,2.9-2.3-1.9-5.7-2.4-6.8.1"
      fill="#be172c"
    />
    <path
      d="M576.6,282.9c-.6,3.7-5.1,14.6,2.2,13,3.7-.8,6.3-9.2,6.9-9.1"
      fill="none;stroke:#5d1211;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.3849000930786133px"
    />
    <path
      d="M533.8,276.3c-1.4,2.5-5.8,11.3-1.4,12.5s5.5-5.3,7-7.5"
      fill="none;stroke:#5d1211;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.3849000930786133px"
    />
    <path
      d="M578.2,316.5a5.4,5.4,0,0,1,1.6,1.4c-.4-1.4-.9-1.7-2-2.6.5.5,3,3.7,2,2.7"
      fill="#e7683b"
    />
    <path
      d="M576.7,319l.6.5-.6-.5.9.8-.5-.6v.2a2,2,0,0,0,.8.8,2.1,2.1,0,0,0,1,.6h.8a2,2,0,0,0,1.1-.3,1,1,0,0,0,.7-.4,2,2,0,0,0,.8-.8l.3-.7a4.3,4.3,0,0,0,0-1.6,11.1,11.1,0,0,0-.7-1.6l-.8-1.2-1.3-1.1a2.1,2.1,0,0,0-1-.6,2,2,0,0,0-1.1-.2,1.7,1.7,0,0,0-1.2.2,1.8,1.8,0,0,0-.9.6l-.5.6a2.8,2.8,0,0,0-.4,1.5c0,.3.1.6.1.8a2.9,2.9,0,0,0,.8,1.4l.6.6.8,1-.5-.6.5.7c.1.2.3.3.3.5l-.3-.7h0a2.2,2.2,0,0,0-.1-.8h0a1.9,1.9,0,0,0,.1-.8h0l.3-.7h0l.5-.6h0l.6-.5h0l.7-.3h1.6l.7.3h-.1l.6.5c0-.1-.1-.1-.2-.2a1.8,1.8,0,0,0-.9-.6,1.7,1.7,0,0,0-1.2-.2,1.4,1.4,0,0,0-1.1.2,2.1,2.1,0,0,0-1,.6l-.5.6a4.2,4.2,0,0,0-.4,1.5,2.5,2.5,0,0,1,.1.8,2.9,2.9,0,0,0,.8,1.3l.8.7.8.3a2.9,2.9,0,0,0,1.6,0,2.6,2.6,0,0,0,1.3-.8s.4-.6.5-.6a3.7,3.7,0,0,0,.4-1.5,3.4,3.4,0,0,0-.7-1.9c-.5-.8-1.2-1.6-1.8-2.4l-.6-.6-4.2,4.2a7.1,7.1,0,0,1,1.3,1.2l-.4-.6.4.7-.3-.7c.1.2.1.4.2.6l5.4-2.3a7.2,7.2,0,0,0-2.7-2.5,2,2,0,0,0-1.1-.3,2.4,2.4,0,0,0-1.2,0,3.1,3.1,0,0,0-1,.5c-.3.3-.6.5-.7.9l-.3.7a2.2,2.2,0,0,0,0,1.6l.3.7a2.2,2.2,0,0,0,1.1,1Z"
      fill="#e7683b"
    />
    <path
      d="M431.2,403.4c1.2-7.1,4.8-14.3,3.6-21.8-2-12.1-8-.8-10.6,4.5-6.1-3.7-12.5-5.8-19.7-7.7-5-1.4-17-5.9-22.3-3-7.2,3.9,15.2,11,18.4,12.5-3.4,0-28.5-7.7-28.1-1.1.2,3.8,18,6.6,21.5,8-4,.2-24.6-3.9-26,1.2s20.4,7.5,23.8,8.8c-4.3-.5-11.5-1.9-15.2.9s1.9,4.8,6.3,5.8c6.9,1.6,15.6,5,21.4,6.2"
      fill="#ef8848"
    />
    <path
      d="M562.9,321.3c-7.5,1.4-19.9.6-26.6-3.8l2.4,2.6c4.3,6.4,13.6,11.1,21.3,9.8s13.4-6.8,17.3-13.1C573.1,318.2,567.5,320.4,562.9,321.3Z"
      fill="#fff"
    />
    <path
      d="M603.8,304.1c-.7,6.5-4.9,16.9-9.2,21.9-6.8,7.8-18.7,9.1-28.7,9.4-14.1.5-30.7-3-40.6-12.9a19.6,19.6,0,0,1-5.7-10.9c-7.3,26.2,17.3,42.3,40.9,42.7,8.7.1,28.9-4.9,36.1-9.5s10.6-13.6,12.7-21.6c1-3.8,2.1-7.4,2.1-11.3s-1.5-6.7-.4-8.6"
      fill="#781c1d"
    />
    <path
      d="M554.6,308.3c4-2,12.4.7,17,1s10.5.4,14.6,3.5a11.1,11.1,0,0,1,4.6,10c-.2,2.8-1.8,4.6-4,3s-4-6.7-6.9-8.8-7.8-1.9-11.3-2.1c-8.3-.5-16.9-.8-25.1-.8-3.6,0-6.6-.3-9.2,2.4s1,6.6,1,10.8c-4.5-.1-7.3-6.3-7.5-10s2.7-6.9,6.6-8c7.1-1.9,14.6-1.6,21.8-1.3"
      fill="#781c1d"
    />
    <path
      d="M599.5,270.7A45.9,45.9,0,0,0,593,281c-1.7,3.7-3.4,11-.3,14.4.8-2.9,4.3-7.1,6.5-9-.9,4.9-2.2,9.4.4,14.3a27.5,27.5,0,0,0,4.6,5.6c-1-8.3-.1-17.1.2-25.5.1-2-.8-7.4,1.1-8.5"
      fill="#781c1d"
    />
    <path
      d="M620.7,253.2c-3.1-.1-6.4-3-8.8-4.7-4.8-3.5-8.7-8.2-13.1-12.1s-9.6-6.7-16.4-7.9c-3.2-.6-12.1-3.6-15.1-1.2,2.4,1.7,5.7,3.5,7.3,5.8-1.8,0-3.5-.8-5.2-1.3a45.8,45.8,0,0,0-13.3-2.9c-4.8-.4-19.4-1.3-21.5,4.7a40.1,40.1,0,0,0,7.7,1.4c-4.1,1.8-14.5,4.7-15.8,9.5,3.7,0,7.4,1.7,11,2.4s10,1.3,14.9,2.2a189.3,189.3,0,0,0,30.9,3.1"
      fill="#5d1211"
    />
    <path
      d="M627.7,274.7c4.9,1.5,10.4,9.1,13,13.2,4.1,6.4,4.6,16,1,22.8-.9-2.1-1.6-3.4-3.9-4.5-.3,6.7-6.4,14.5-11.2,18.8-2.6,2.4-6,3.4-9.4,4.5,5.1-3.9,6.9-12.1,8.3-17.8s.7-8.4.6-12.9"
      fill="#5d1211"
    />
    <path
      d="M598.2,269.4c2,4.3-20.6,9.4-22.7,9.9-7.2,1.8-14.5,2.2-21.6.1,3.6-2,7.3-6.8,9.4-10.4.7-1.2.7-2.5,1.7-3.6-2.1,3-8.1,3.4-11.5,3.8s-6.3,1.6-8.5.7c1.2-1.8,2.9-5.2,5-5.8"
      fill="#781c1d"
    />
    <path
      d="M530.8,262.2c-5,.7-9.1,5.6-11.9,9.3a120.7,120.7,0,0,0,21.6-2.1c4.7-.9,8.3-4.4,13-4.8"
      fill="#781c1d"
    />
    <path
      d="M582.5,318.3c3.1,3.5,2.8,9.8,6,13.3s8.3.4,10.7-2.9c3.8-5.1,4.9-12.4,5.3-18.6.1-2.3,1.2-5.9.2-8.1.2,4.8-6,22.3-13.5,16.5-1-.7-1.5-3.1-2.5-4.1s-3.1-1.3-4.6-1.6"
      fill="#5d1211"
    />
    <path
      d="M533.7,321.8c.4,2.5,2.6,6.5,1.6,9-2.1,5.3-9.2-3.5-10.6-5.7-2.6-4-5.9-9.2-5.3-14.1.8,3.2,5,12,8.9,12.3.1-2.5,0-5.1,0-7.6"
      fill="#5d1211"
    />
    <path
      d="M564.6,337.7c-2.8-.6-5.4-1.8-4.9-5a61.3,61.3,0,0,1-8.6-1.1c.7,4.6-7.9,2.8-10.5,1.9,5.5,5.1,19.4,8.1,26.2,4.4"
      fill="#781c1d"
    />
    <path
      d="M602.3,337.4c-9.6,5.6-15.5,10.6-27.1,11.5s-19.9-.7-30-2.6c-13.1-2.5-17-11.7-25.7-20.3-.3,10.6,14.7,24.2,23.4,26.9,10.9,3.4,21.6,3.8,32.8,2.9,7.2-.5,15.7-3.3,21.1-8.2,3.8-3.4,4.7-6.7,5.8-10.7Z"
      fill="#5d1211"
    />
    <path
      d="M533.3,322.2c0-3.8.8-6.1,5.2-6.1s11.6,2.5,15.6-2.3c3.8,3.8,12.8,4,17.8,3.7s10.1-1.2,12.7,3.4c-2.2-2.7-2.6-5.8-5.9-7.8s-9.5-3.2-14.7-3.1c-8.7,0-17.2-.1-25.7.9l-6.7,3.3"
      fill="#781c1d"
    />
    <path
      d="M474.2,507.5c-3,.4-9,1.4-12.3,4-7.6,5.8-12.4,18.4-16.7,26.8,7.2,1.8,15.5-.1,23,.5a104.7,104.7,0,0,0,14.8.6c.6-7.6-5.4-21.4-1.3-27.6"
      fill="#ef8848"
    />
    <path d="M166.3,538.8H675.1a4.1,4.1,0,0,1,4.2,4.1v10.9h-513v-15Z" fill="#781c1d" />
    <path d="M166.3,537.8h509a4,4,0,0,1,4,4h-513v-4Z" fill="#af6734" />
    <path
      d="M264.5,458.5c-1.4-2.5,4.9-7.5,7.6-8.1,5.2-1.3,11.7,4.5,15.3,8,6.3,6.2,7.2,10.5.1,16.4"
      fill="#dc6c1f"
    />
    <path
      d="M260.7,346.6A40,40,0,1,1,183,365.4c-5.3-21.4,8.4-41.4,29.4-48.3C227.4,312.2,256.4,329.1,260.7,346.6Z"
      fill="#ed7b2f"
    />
    <path
      d="M214.1,385.5c6.5,3.9,9.2,7.3,16.1,10.3,1.1-8,2.3-16.7,1.3-24.5,0,.6.3.7.5,1.2"
      fill="#c45a35"
    />
    <path d="M238.5,354.5c-6.6-5-18.3,1.8-13.6,10.6,2.1,4,14.2,7.3,14.5,3.3" fill="#912b1e" />
    <path
      d="M259.9,348.6c3.3,1.7,6.4,7.9,8.2,11.2.6,1.2,1.8,3.3,1.1,4.7s-5.9,2.6-7.8,3.1-4.2.8-4.1,1.2"
      fill="#ed7b2f"
    />
    <path
      d="M257,346.4c.9-.2,2,1.1,2.4,2.9s.1,3.4-.8,3.7-1.9-1.1-2.4-2.9S256.1,346.6,257,346.4Z"
      fill="#5d1211"
    />
    <path
      d="M258.2,339.2c.3,1.1,1.9,3.5,1.1,4.4s-8.5-2.5-9.8-2.8-4.8-.4-5.6-1.1-.7-2.8-1.3-3.9c5.2,0,11.6,2.6,16.7,4.2-.8.2-.4-.2-.7-.5"
      fill="#b54a18"
    />
    <path
      d="M241.3,553.8c4.7-29,11.4-44.5,12.7-74.1.6-14.4.9-28.4,1.1-42.8.1-7.4-1.2-14.8-1.1-22.2.2-11-6.2-9.7-16.3-11.4-14.8-2.7-31.4-3.2-46.4-3.9a162.9,162.9,0,0,0-28.8,1c-3.5.4-3.1-.1-5.3,1.8-7.4,6.5-10.7,24.2-14.5,33.2s-8.8,19-11.4,29.4-4.3,23.1-7.7,34.4c-4,13.3-5.2,25.3-5.7,39.1-.4,8-2.5,8.5-.2,16.3"
      fill="#00a098"
    />
    <path
      d="M294,540.5c5.4-12,5.4-27.4,6.5-40.2.9-10.4-.4-20.3-2.4-30.5-1.2-6-4.3-7.7-10.8-8.3s-7.4-1.5-12.5-.9c-.6,7.4.2,15.7,3.2,22.5"
      fill="url(#a32dc93f-4ee8-4e5a-ab44-0a58e4a235ea)"
    />
    <path
      d="M224,418.1c-.3,8.1,2.2,16.7,4.4,24.3,5.1,17.7,11.5,34.9,15.9,52.8,2,8.1,3.1,17.6,7.3,25,1.5,2.7,3.7,4.9,5.2,7.8,2.6,5.3,5.4,9.7,8.1,14.6,3.6-1.4,7.1.6,10.8,1.1a26.5,26.5,0,0,0,12.8-1.6c2.6-1.1,6.5-2.2,7.7-4.6s-1.1-11.2-1.6-13.7c-2-9.5-2.4-19.2-4.8-28.6-6.4-24.4-16.4-47.6-26.2-70.6-1.2-3-9.7-19.5-12-17.5"
      fill="#00a098"
    />
    <path
      d="M215.1,314.7c1.8-4.8,22.5-2.2,27.9-1.1,10.8,2.3,21.8,8.9,26.4,19.2-4.7-1.8-10.7-2.2-15.7-3.9,6.4,1.1,14.8,7.6,15.9,14.3-5.7,0-13-4-19-5.4a198.4,198.4,0,0,0-31.5-4.9c-5-.3-18.2.6-21.9-3.9,4.9-7.9,12.6-14.4,21.5-16.9"
      fill="#5d1211"
    />
    <path
      d="M242.8,335.5c2.1,4.5,9.3,34.5-3.5,34.8-1-4-1.4-8.3-2.5-12.3a33.4,33.4,0,0,1-37.6,31.6c-.4-2.8,1.9-4.5,3.8-6.6-4.2,9.6-18,2.6-8.9-2.3-1.5,1.7-8.4,7.5-10.6,5.6s.2-7.3-.1-8.9c-1.1-5.7-4.2-11-5.4-16.8s-.4-10.6,0-16.3c1-10.9,6.5-19.2,15.8-25.1,5.4-3.4,10.5-4.6,16.8-5.7,10.8-1.9,18.3,6,29.1,5.2"
      fill="#5d1211"
    />
    <path
      d="M240.5,407.1c-9.7-11.5-24.3-21.4-38.9-26.5-7.5-2.6-31-5-39.3-4.6-6.5.2-6.4,3.8-10.7,9.2-8.2,10.2-10.5,20.2-13.5,32.7-2.6,10.6-4.8,18.2,3.6,26.4,11.1,10.9,23.9,12.6,39,12.7,11.2.1,22.4-.5,31.9-6.5,12.6-7.9,16-20.2,20.7-33.7"
      fill="#00a098"
    />
    <path
      d="M206.6,315.6c7.6-1.2,13-7.6,15.7-14.8-9.4-3.3-14.2,9.5-17.8,15.1,4.9-8.8-19.1-18.3-8.4,3.4"
      fill="#5d1211"
    />
    <path
      d="M206.2,312.8c11.3-2.2,19.9-2.9,31-.6,6.4,1.3,16.5,2.2,20.4,8.1a41.8,41.8,0,0,0-15.5-4.4c-6-.4-13.7.8-19.3-.8,4.8.4,9.8,1.9,13.4,5.2a135.6,135.6,0,0,0-35.2-5.2c-.6-.4.1-.1.2-.3"
      fill="#781c1d"
    />
    <path
      d="M137.9,419.5c.2,5.3-.9,9.4,1.1,14.5s3.9,10.5,6.8,14.9,6.6,7.7,8.2,12c-7,.7-14.1-11.2-16.8-16.6-3.5-7-2.4-11.7-.4-19.6"
      fill="#00a098"
    />
    <path
      d="M151.8,460.6c5.7,0,9.2-1.7,14.3-4,3.5-1.6,6.1-3.8,9.1-5.5-8.2,3.4-18.7,4.2-27.4,3.3"
      fill="#00a098"
    />
    <path
      d="M236.6,357.5c.9-3.7-.9-9.3-1.9-13.2-7.7,1.1-7.3,4.6-7.3,11.3,0,5.1,3.9,11.4,10.9,9.3"
      fill="#c45a35"
    />
    <path
      d="M230.8,406.3s-27.4,32.7-51.3,40.6c-9.1,3.1-15,4.9-18.7,5.9a16.7,16.7,0,0,1-12.2-1.2l-10.4-5.3s9.4,15.7,16.5,16.6,24.8-1.8,34.5-10.6,31.8-23.9,38-34.5,9.7-14.4,9.7-14.4l-7,2.9"
      fill="#006d65"
    />
    <path
      d="M249.4,516.7c-3.4-6.6-6.2-14.5-11.7-19.7-.6,18.9-9.4,39.2-8.4,58.1,3,.2,9.8.8,12.4-.3"
      fill="#006d65"
    />
    <path
      d="M249.3,405.4a57.3,57.3,0,0,0-12.1,13.3c-2.6,4-5.8,9.3-7.1,14.1-4.5,16.5-3.7,36.6.6,53,1,3.9,1.7,7.1,4.5,9.7s4.1,1.8,2.9,4.4c-4.7-1.6-9.1-8.4-11-13-3.1-7.9-3-15.9-4-24s-1.3-13.7-.3-20.4c1.4-10.2,4.6-16.6,9.6-25.7,3-5.4,5.1-6.8,9-11.4.6.1,4.4-1.3,4.4-.9"
      fill="#006d65"
    />
    <path
      d="M257.2,373.8a44.4,44.4,0,0,0-5.2,1.5"
      fill="none;stroke:#b55217;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.691100001335144px"
    />
    <g clip-path="url(#b528e013-2588-476d-a00c-9786b74526da)">
      <ellipse
        cx="404.3"
        cy="136.3"
        rx="132"
        ry="110.5"
        fill="url(#a68cda88-6683-4088-9587-a455ed97a859)"
      />
      <polygon
        points="409.6 122.3 399.5 122.3 399.5 106.6 409.6 109.5 409.6 122.3"
        fill="#e20335"
      />
      <rect x="406.7" y="122.3" width="2.9" height="14.33" fill="#5d1211" />
      <path
        d="M406.7,137.4l14.4-.2a4.3,4.3,0,0,0,2.8-1.4"
        fill="none;stroke:#e20335;stroke-miterlimit:10;stroke-width:1.432800054550171px"
      />
      <path
        d="M408,122.7c-3.1-1-6-3.1-9.2-3.6s-2.1-.3-2.4,1.7-.6,5-.1,6.1,2.1.9,2.9,1.9c0-2.1.4-4.1.3-6.1,2.3.1,5.4,2.3,7.4,3.5"
        fill="#5d1211"
      />
      <path
        d="M478.3,235.1c.3-10.1-14.6-15-25.1-12.1-3.2.9-6.2,2.1-7.9,4.8s-.6,2-5.9,1.7c-7.4-.5-9.7,5.2-11.8,9.6-.7,1.5-.4,1.1-2,2.5s-1.4,1.9-2.2,3.7c-1.7,3.7-5.4,2.9-7.8-.1s-7.8-6.9-13.3-4.4c0-6.7-9.4-8.6-15.8-6-2.4,1-2.6,2-5.1,2.1a39,39,0,0,1-5-.4c-1.6-.5-1.7-.6-2-2.3-.7-4-.4-7.5-2.4-11.3s-5.2-9.2-10.3-11.8-11.6-4.2-16.3-1.5c-2.5,1.4-3.4,2.3-5.8,1s-1.3-1.6-2.5-2.1c-2.7-1.2-6.1-.7-8.8,0s-7,1.9-9.2,4.2-1.3,3.7-5,2.7-10.4-6.9-12.7-2.2c-.5,1.1.9,2.7,1.4,3.5a56.8,56.8,0,0,0,15.6,15.7c4.7,3.1,9.5,7,14.9,9.3s11.7,3.2,16.8,6.1a29.7,29.7,0,0,0,12.8,4.1c9.9,1.1,19.8,2.5,29.6,3.9s18.8,2.8,28.2,3.7c11,1.1,22.8-.5,33.6-2.2,6.3-.9,10.6-3.9,16.2-6.1s7-5.7,11.8-8.8"
        fill="#fff;opacity:0.4000000059604645;isolation:isolate"
      />
      <path
        d="M486.2,319.5,435.7,179.2a1.9,1.9,0,0,0-1.4-1.2l-10.8-2.4a2.3,2.3,0,0,1-1.4-1.3l-12.4-37.1a1.9,1.9,0,0,0-.5-.9l-9.9-8.8a2,2,0,0,0-3.3,1.1l-16.3,82.6a1.8,1.8,0,0,1-1.6,1.6l-5.9,1a1.9,1.9,0,0,0-1.6,1.5L335.1,332.2a2,2,0,0,0,1.8,2.4c10,1.1,60.1,5.5,72.7,5.5s64.4-14.8,75.3-18A2,2,0,0,0,486.2,319.5Z"
        fill="url(#a04e19e0-ca0b-494a-a304-cb7389d8208d)"
      />
      <path
        d="M416.4,271.8c.1-4-2.3-8.5-3.2-12.3-2.3-9.6-4.2-18.1-7.9-27.4s-7.3-18-11.7-26.8c-3-6.1-7.3-14-8.3-20.7-2.2,7.4-2.9,15.9-6,23.5-1.8,4.4-1.9,9.2-3.7,13.5s-2.3,8.9-3.4,13.9c-1.8,8-5.3,15.4-6.1,23.5-.2,2.1-1.7,9-2.7,9.4"
        fill="url(#a4d7b3bc-45a3-410a-8536-6fb5ea360685)"
      />
      <path
        d="M400.5,107.3c-1.9-1.1-4.7-1.7-5.8-3.9s-.6-5.9-.6-8a6.3,6.3,0,0,0-1.9-.3c-.1,3.3-.8,7.2.6,10.3s4.5,4,6.6,5.1"
        fill="#e20335"
      />
      <path
        d="M408.9,109.7c1.9.1,3.3,1.1,4.9-.4s1.6-5,2-7.1a2.7,2.7,0,0,1,1.5.5c.8,2.9.1,7.1-2,9.4s-6.5,2.2-5.9,1.3"
        fill="#e20335"
      />
      <path
        d="M408.7,109.9c.4-.3.3-2.8.4-3.6a11.5,11.5,0,0,0,0-3.7,4.1,4.1,0,0,0-3.9-3c-1.7,0-3.1.2-3.9,1.8s-.7,4.1-.8,6.1h.3"
        fill="#e20335"
      />
      <path d="M404.7,106.8c6.1.2,4-8.6-.9-6s-.3,6.6,3.4,5.8" fill="#ef8848" />
      <path
        d="M403.7,100.9c.4.9,1.2,2.3,1.5.5.4.2.8.7,1.2.9a2.1,2.1,0,0,0,0-.8c.4.6,1,1.4,1.9,1.3-.2-2-3.1-3.2-4.7-2"
        fill="#5d1211"
      />
      <path
        d="M402.2,104.8c1.7-.6,4,.2,5.6.7-.1.6-.9,2.3-1.6,1.6s-1.1.5-1.2-.2a.7.7,0,0,1-1.3-.3c-.9.4-1.8-1.2-1.8-2"
        fill="#5d1211"
      />
      <path
        d="M404.2,105.4a2.8,2.8,0,0,0,2,.2"
        fill="none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:0.7164000272750854px"
      />
      <path
        d="M400.5,107.3c.1-1.5-1.9-1.7-2.9-1.7.5-1.1-.8-2.1-1.8-2s.1-2.1-1.2-2.7c1.7-.5,1-1.9.3-2.8a1.6,1.6,0,0,0-.1-2.3,4.1,4.1,0,0,0-2.2-.3c-1.7.2-1.9,1.8-.7,2.8-1.3.3-1.1,2.4,0,2.9-.9.9-.8,3,.7,3.4-1.4,1.4.6,4.2,2.3,3.5a1.7,1.7,0,0,0,2.7,1.5,1.9,1.9,0,0,0,2.7,1.7"
        fill="#e20335"
      />
      <path
        d="M408.4,110.2c1-1.1,2-1.2,3-.2-.2-1.2,1.5-1.3,2.3-.8a1.5,1.5,0,0,1,1.6-2.2c-.9-.7-1.5-2.7.1-2.8-.7-1.4-.3-2.3,1.6-1.7a2.8,2.8,0,0,1,1.6,1.4c.3,1-.2,1-.9,1.5a1.5,1.5,0,0,1-.4,2.7c.8.9.4,2.7-.9,2.8s-.3,3.1-2.4,2.6c0,1.1-2,1.2-2.6.4-.2,1.7-2.2.8-2.8-.1"
        fill="#e20335"
      />
      <path d="M409.2,122.3c2.4.5.9-2.6,0-3.4,2-1.7.6-2.8-.6-4.2" fill="#e20335" />
      <path d="M399.7,118.9c-1.7-.2-.9-2.7,0-3.4-1.3-1.3-2-3.3.3-4.2" fill="#e20335" />
      <path
        d="M406,131.7l-17.9-9.3a5.2,5.2,0,0,1-1.5-2.9"
        fill="none;stroke:#e20335;stroke-miterlimit:10;stroke-width:1.432800054550171px"
      />
      <path d="M393.9,95.7c2.2.2,1.8-3.1-.6-2.9s-2.6,2.7-.6,3" fill="#5d1211" />
      <path
        d="M415.7,102.3c-.7-.4-.5-1.8.3-1.2.1-1.3,2.4-2.1,3.1-.9s-.1,3.5-.9,3.4"
        fill="#5d1211"
      />
      <line
        x1="417.4"
        y1="101.6"
        x2="428.9"
        y2="128.8"
        fill="none;stroke:#5d1211;stroke-miterlimit:10;stroke-width:0.7164000272750854px"
      />
      <path
        d="M427.1,127.7c.8.1,2.4-.3,2.4-1.2s-3.7.6-2.3,1.5.5-.2.7-.4"
        fill="none;stroke:#5d1211;stroke-miterlimit:10;stroke-width:0.7164000272750854px"
      />
      <line
        x1="393.1"
        y1="94.4"
        x2="380.2"
        y2="118.8"
        fill="none;stroke:#5d1211;stroke-miterlimit:10;stroke-width:0.7164000272750854px"
      />
      <path
        d="M379.8,116.8c.5.5,1.8,2,2.6,1.1s-2.9-2.9-2.8-1"
        fill="none;stroke:#5d1211;stroke-miterlimit:10;stroke-width:0.7164000272750854px"
      />
      <path
        d="M422.1,56.5c-.4-2.2-.5-4.9-1.6-6.9-1.3,2.5-5.1,12.4-6.5,13.6-.9-3.1-6.3-13.2-7.5-14.8-.6,2.8-5.3,12.8-5.5,14.7-1.5-2.3-5.4-12.6-6.8-14l-3.3,21.7c4.1,1,19,1.4,22.3,1.4s7.6.6,11.1-.1C424.1,71.8,422.8,60.5,422.1,56.5Z"
        fill="#f7b032"
      />
      <path d="M436.5,75.8a18.7,18.7,0,0,0,4.7,3.7" fill="#fff" />
      <path
        d="M435.6,76.7a21.1,21.1,0,0,0,5,3.9,1.3,1.3,0,0,0,1.3-2.2l-2.5-1.6h-.2l-.2-.2-.6-.5-1.1-1.1a1.2,1.2,0,0,0-1.7,0,1.3,1.3,0,0,0,0,1.8Z"
        fill="#fff"
      />
      <path d="M440.9,63.3a7.3,7.3,0,0,1,2.9.4" fill="#fff" />
      <path
        d="M440.9,64.6h1.2l1.4.3a1.3,1.3,0,0,0,.9-.1,1.6,1.6,0,0,0,.6-.8,1,1,0,0,0-.1-.9,1.6,1.6,0,0,0-.8-.6,7.1,7.1,0,0,0-3.2-.4,1,1,0,0,0-.9.4,1.2,1.2,0,0,0-.4.8,1,1,0,0,0,.4.9,1.3,1.3,0,0,0,.9.4Z"
        fill="#fff"
      />
      <path d="M436.5,50.5c1.4-.7,2.5-1.9,4-2.6" fill="#fff" />
      <path
        d="M437.1,51.5c1.4-.6,2.6-1.8,4-2.5a1.3,1.3,0,0,0-1.2-2.2c-1.5.7-2.6,1.9-4.1,2.6a2,2,0,0,0-.6.7,1.8,1.8,0,0,0,.2,1,1.3,1.3,0,0,0,.7.6,1.2,1.2,0,0,0,1-.2Z"
        fill="#fff"
      />
      <path d="M376.6,80.7a12.4,12.4,0,0,1-5.3,3.7" fill="#fff" />
      <path
        d="M375.7,79.8l-1.1,1.1-.5.5-.3.3h-.2a5.7,5.7,0,0,1-1.3.8l-.4.2a.1.1,0,0,1-.1.1h-.1c.1,0-.1,0-.1.1h-.2l-.4.2a1.3,1.3,0,0,0-.9,1.5,1.4,1.4,0,0,0,1.6.9,13.8,13.8,0,0,0,5.8-4,1.4,1.4,0,0,0,0-1.8,1.3,1.3,0,0,0-1.8,0Z"
        fill="#fff"
      />
      <path d="M375.1,66.3a22,22,0,0,0-4.8-.4h1.5" fill="#fff" />
      <path
        d="M375.5,65.1l-2.9-.4h-2.3a2,2,0,0,0-.8.4,1.1,1.1,0,0,0-.4.9,1.2,1.2,0,0,0,1.2,1.2h1.5a1.2,1.2,0,0,0,1.3-1.2,1.4,1.4,0,0,0-1.3-1.3h-1.5v2.5h3.6c.3.1-.2,0,0,0h.9a1.5,1.5,0,0,0,.9-.2,1.1,1.1,0,0,0,.6-.7,1.2,1.2,0,0,0-.8-1.5Z"
        fill="#fff"
      />
      <path d="M377,50.5a19,19,0,0,1-3.9-2.3,1.2,1.2,0,0,1,1.3.8" fill="#fff" />
      <path
        d="M377.3,49.3h.2a10.1,10.1,0,0,1-3.2-1.8c.1.1.2.1.2.2l-.5-.4-.9,2.1h0c.1,0,.2,0,.2.1l-.3-.2.3.2-.3-.2.3.2c-.1,0-.1-.1-.2-.2l.2.3a1.3,1.3,0,0,0,2.2-1.2,2.5,2.5,0,0,0-2.4-1.5,1.3,1.3,0,0,0-1.1.8,1.3,1.3,0,0,0,.3,1.4l2,1.5,2.3,1.1a1.7,1.7,0,0,0,1-.1,2.4,2.4,0,0,0,.6-.8,1.3,1.3,0,0,0-.2-.9,1,1,0,0,0-.7-.6Z"
        fill="#fff"
      />
      <g opacity="0.41999998688697815">
        <path
          d="M394.6,67c.7-2.1.3-4.6.4-6.9a13.6,13.6,0,0,0-.1,7.2c.3-2.5-.9-5.4,0-7.6"
          fill="#fff"
        />
        <path
          d="M395.8,67.3c.8-2.3.4-4.8.4-7.2a1.2,1.2,0,0,0-2.4-.3,13.8,13.8,0,0,0-.1,7.8,1.1,1.1,0,0,0,1.3.9,1.3,1.3,0,0,0,1.1-1.2,16.2,16.2,0,0,0-.2-3.8,10.9,10.9,0,0,1-.1-1.8V61c0,.1,0,.1.1-.1a.8.8,0,0,1,.1-.4s.1-.5,0-.2a1.3,1.3,0,0,0-.4-1.7c-.6-.3-1.5-.2-1.7.4a7.7,7.7,0,0,0-.5,4.2,18.8,18.8,0,0,1,.2,4.1l2.5-.4a1.9,1.9,0,0,1-.2-.7c0-.2-.1-.3-.1-.5v-.2a10.9,10.9,0,0,1-.1-1.8,2.8,2.8,0,0,1,.1-.9v-.7h.1v-.3a.8.8,0,0,1,.1-.4,1.9,1.9,0,0,1,.2-.8l-2.5-.4c0,1.2.1,2.3.1,3.4a9.1,9.1,0,0,1-.1,1.7v.6c-.1.3-.1.6-.2.9a1.2,1.2,0,0,0,.8,1.5,1.3,1.3,0,0,0,1.6-.9Z"
          fill="#fff"
        />
      </g>
    </g>
    <path
      d="M425.3,245.4c8.3,21.9,33,40.4,50.2,51.6-10.1-18.9-14.9-39.4-21.8-58.6"
      fill="url(#afcee383-7544-4dc4-b334-400048996618)"
    />
    <path
      d="M681.6,406.4c-3.9,14.5,1.7,31.9,2,47,.4,16.8.3,35.1-2.8,51.7-2,10.7-3.7,22.1-7.3,31.8-8.6,0-16.7,1.6-25.4,1s-18.8-4.7-28.5-5.7c-.3-13.4,2-22.5,9.6-33.3,9.5-13.5,20.6-21.8,27.6-37.8A187.7,187.7,0,0,1,676,427.2"
      fill="#e20335"
    />
    <path
      d="M563.4,495.5c0-9.9.9-19.8,1.5-29.7.3-4.9.6-9.8.7-14.7s.2-9.7.6-14.5.5-9,.7-13.5.3-7.7.1-11.6-.4-6.8-2.1-9.6a24.7,24.7,0,0,0-5.3-5.9c-1.1-1.1-2.3-2.1-3.4-3.3s-1.1-1.1-1.6-1.6l-.7-.8c-.3-.4.2.2-.1-.1l-.3-.5a9.5,9.5,0,0,1-1-1.7c.2.5-.1-.4-.1-.5s-.1-.2-.1-.3,0,.3,0-.1v-.9c-.1.5,0-.2.1-.4a3.7,3.7,0,0,1,.7-1.2,2.5,2.5,0,0,0-3.6-3.6c-1.8,2.4-2.8,5.2-1.8,8.2s2.7,4.8,4.6,6.8,4.2,3.8,6.1,5.8l1.3,1.4c.1.1.5.7.3.3l.5.8.4.7c.2.4,0,0,0-.1s.1.3.1.4a10.2,10.2,0,0,1,.6,2.5,53.5,53.5,0,0,1,.4,11.3c-.1,4.2-.1,8.4-.4,12.7s-.9,9.8-.9,14.7-.2,10.1-.5,15.2c-.7,11.2-1.7,22.5-1.8,33.8,0,3.2,5,3.2,5,0Z"
      fill="#bf172e"
    />
    <path
      d="M636.7,381.5c-7.5,3-12.9,9.1-19.5,13.6s-15.2,7.7-23.5,9.6c-3.2.7-1.8,5.6,1.3,4.8,8.5-1.9,16.8-4.8,24.1-9.6s11.7-10.7,19-13.6a2.6,2.6,0,0,0,1.7-3.1,2.5,2.5,0,0,0-3.1-1.7Z"
      fill="#bf172e"
    />
    <path
      d="M602.8,493.5c3.5-.2,6.6-1.9,9.6-3.4a28.3,28.3,0,0,1,4-1.7l1-.3c.1,0,.5-.1.1,0h.7c.3,0,.2.1,0,0l.4.2c2.1,1.6,4.2,4.2,6.8,5.7s7.6,3.5,11.3,5.3,8.4,4.1,13.1,4.3a2.5,2.5,0,0,0,2.4-1.8c.9-5.2-2.2-9.9-5.9-13.3a40,40,0,0,0-14.5-8.5,18.8,18.8,0,0,0-7.4-1.1c-1.7.2-3.4,2.1-2.2,3.8,2.8,4.1,9,4.8,13.5,5.1s11.1-.2,15.8-2.5c1.9-.9,3.7-2.4,4.1-4.5a6.5,6.5,0,0,0-2.7-6.5c-4.2-3.1-10.8-2-15.6-1.1a61.3,61.3,0,0,0-15.1,4.6l3.6,2.8a68.9,68.9,0,0,0,2.1-13.5c.2-4.4.3-8.9.1-13.2l-.8,1.8h-3.5c-.5-.6-.1,0,0,.1l.6.8a2.5,2.5,0,0,0,3.5-3.5c.6.6-.5-.9-.5-.9a2.5,2.5,0,0,0-3.6,0c-.9.9-.7,2.2-.7,3.4v3.3c0,2.3,0,4.6-.1,6.9a61,61,0,0,1-1.9,13.5c-.6,2.3,1.8,3.7,3.7,2.9l.3-.2h-.2l1.1-.4a12.5,12.5,0,0,1,2.5-.9c2.3-.8,4.6-1.4,6.9-2l3.7-.7a29.8,29.8,0,0,1,7-.6h2.5l.7.2.3.2h-.2l.6.3c.2.1.5.4.1,0l.4.4c.5.4-.1-.3.1.1v.2c-.1-.3.1.4.2.5s0-.5,0,0,.1.7,0-.1a.6.6,0,0,1-.1.4c-.1.2.2-.3.1-.3a.4.4,0,0,1-.1.3c-.4.2.3-.3,0-.1l-.3.4h0l-.6.4-1,.6c-.4.2.3-.2-.2,0l-.9.4-3.1.7-.8.2h-.6l-1.6.2h-3.8l-4-.2h-.5c-.5,0,.1.1-.2,0h-.9l-1.7-.4-1.7-.5h-.3l-.7-.3-.6-.4c-.2-.1-.5-.4-.1,0l-.5-.5h-.1c-.4-.3.3.4,0,.1l-2.1,3.7h2.9a26.2,26.2,0,0,1,4,1.1,33.1,33.1,0,0,1,7.9,4.1,23.3,23.3,0,0,1,3.5,2.7l1.5,1.4,1.1,1.4a18.8,18.8,0,0,1,1.2,1.8l.4.8c.1.3.1.3,0,.1s.1.3.2.4a2.9,2.9,0,0,1,.2.9.8.8,0,0,1,.1.4h0a2.8,2.8,0,0,1,.1.9.9.9,0,0,1-.1.5h0l2.4-1.9c-3.7-.2-7.2-2.1-10.5-3.6s-7.8-3.2-11.4-5.3l-1.2-.8c-.2-.1,0,0,.1.1l-.4-.4-1.6-1.5a21.1,21.1,0,0,0-3.6-3.1c-2.8-1.8-6.1-.6-8.8.6l-5,2.5a16.6,16.6,0,0,1-4.5,1.4c-3.2.1-3.3,5.1-.1,5Z"
      fill="#bf172e"
    />
    <path d="M577.3,399.3c3.2,0,3.2-5,0-5s-3.3,5,0,5Z" fill="#e20335" />
    <path
      d="M581.8,399.8v-1a1.7,1.7,0,0,0-.3-1,1.4,1.4,0,0,0-.5-.8l-.8-.5a1.5,1.5,0,0,0-.9-.2h-.7a2.4,2.4,0,0,0-1.1.6l-.4.5a2.8,2.8,0,0,0-.3,1.3v1a1.5,1.5,0,0,0,.2.9,2.2,2.2,0,0,0,.5.8,1.6,1.6,0,0,0,.8.6l1,.2h.6a1.9,1.9,0,0,0,1.1-.7l.4-.5a1.9,1.9,0,0,0,.4-1.2Z"
      fill="#bf172e"
    />
    <path
      d="M535.3,388.3h1a1.6,1.6,0,0,0,.9-.3,2.1,2.1,0,0,0,1.3-1.3,1.6,1.6,0,0,0,.3-.9v-.7a4.1,4.1,0,0,0-.7-1.1l-.5-.4a2.7,2.7,0,0,0-1.2-.3h-1a1.6,1.6,0,0,0-1,.2l-.8.5a3.5,3.5,0,0,0-.5.8,1.2,1.2,0,0,0-.2,1v.6a3,3,0,0,0,.7,1.1l.5.4a2,2,0,0,0,1.3.4Z"
      fill="#bf172e"
    />
    <path
      d="M638.2,420.5c10.6,2.2,21.9,3.2,32.4,6.4.2,3.9.1,7.9,0,11.9-11.1,1.4-21.8-6.4-32.8-5.7.2-3.6,1.4-8.2.9-11.4"
      fill="#edc391"
    />
    <path
      d="M637.8,421.8c10.8,2.3,21.8,3.3,32.4,6.5l-1-1.4c.1,3.9.1,7.9,0,11.9l1.4-1.5c-11.4,1.4-21.5-6.3-32.8-5.7l1.4,1.5c.3-3.9,1.4-7.8.8-11.7-.2-1.9-3-1.1-2.7.7.5,3.6-.7,7.4-1,11a1.5,1.5,0,0,0,1.4,1.4c11.4-.6,21.5,7.1,32.9,5.7a1.4,1.4,0,0,0,1.4-1.4,118.1,118.1,0,0,0,0-11.9,1.4,1.4,0,0,0-1-1.4c-10.6-3.2-21.7-4.2-32.4-6.4-1.8-.4-2.6,2.4-.8,2.7Z"
      fill="#edc391"
    />
  </svg>
)
