import {Trans} from '@lingui/macro'
import {navigate, RouteComponentProps} from '@reach/router'
import React, {useContext} from 'react'
import styled from 'styled-components'
import {GlobalBodyStyle} from '../App'
import {CurrentScenarioContext, Scenario, ScenariosContext} from '../contexts'
import {AppLayout, Button, FunScoreIndicator, Row} from '../facets'
import {TokenPresenter} from '../facets/TokenPresenter'
import {GameState} from '../hooks'
import {useStoredSummary} from '../hooks/useStoredSummary'
import {FunScoreOverviewPresenter, EventSummaryPresenter} from '../presenters'
import {LOCAL_GAME_STATE, LOCAL_SCENARIO} from '../storage'
import {ScoreCalculation} from '../util.ts/score'
import gameOverHederImage from '../facets/images/gameOver.jpg'
import {Logo} from '../facets/Logo'
import {HeaderImage} from '../facets/HeaderImage'
import {Footer} from '../facets/Footer'

const SummaryPresenterWrapper = styled.div`
  .text-center {
    text-align: center;
  }

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  .totalPoints {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #00a19a;
  }
`

const GameOverCard = styled.div`
  background: rgba(249, 163, 180, 0.3);
  border: 1px solid rgba(191, 18, 43, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px 20px;
  text-align: left;
  h1 {
    color: #e20033;
    text-align: left;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 13px;
  }
`

const GameOverSuccessCard = styled.div`
  margin: 25px 0;
  text-align: left;

  background: rgba(240, 238, 233, 0.2);
  border: 1px solid #f5f4ee;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px 20px;

  h1 {
    text-align: left;
  }
`

const OverviewHeader = styled.div`
  margin-top: 25px;
  h1 {
    text-align: left;
  }
`

const ScoreSummary = styled.div`
  border-top: 1px solid rgba(205, 202, 185, 0.5);
  padding-top: 10px;
  margin-top: 16px;
`

export const Summary = ({id}: RouteComponentProps<{id: string}>) => {
  const gameStateFromStorage = localStorage.getItem(LOCAL_GAME_STATE)
  const scenarioFromStorage = localStorage.getItem(LOCAL_SCENARIO)
  const localGameState: GameState = gameStateFromStorage
    ? JSON.parse(gameStateFromStorage)
    : undefined
  const localScenario: Scenario = scenarioFromStorage ? JSON.parse(scenarioFromStorage) : undefined
  const scenarios = useContext(ScenariosContext)
  const result = useStoredSummary({hashId: id})
  const gameState = id && id !== 'undefined' ? result?.summary?.data : localGameState

  const scenario =
    id && id !== 'undefined' && scenarios
      ? scenarios.find(
          (scenario) => scenario.meta.id.toString() === result?.summary?.scenarioId.toString()
        )
      : localScenario

  if (!result?.summary || !gameState || !result?.additionalInfo) {
    return (
      <AppLayout>
        <p>
          <Trans id="summary.loading">Loading…</Trans>
        </p>
      </AppLayout>
    )
  } else {
    if (!scenario)
      return (
        <>
          <Trans id="scenario.error.occurred">Es ist ein Fehler aufgetreten!</Trans>
        </>
      )
    const allEvents = scenario.days.flatMap((x) => x.events)
    return (
      <>
        <HeaderImage
          img={gameState.gameOver ? gameOverHederImage : undefined}
          darkGradient={gameState.gameOver}
        />
        <GlobalBodyStyle light={true} />
        <CurrentScenarioContext.Provider value={scenario}>
          <SummaryPresenterWrapper>
            <div className="text-center">
              {!gameState.gameOver ? (
                <>
                  <GameOverSuccessCard>
                    <h1>
                      <Trans id="summary.well.done">Bravo!</Trans>
                    </h1>
                    <Row marginTop="1.5rem">
                      <Trans id="summary.responsibility.and.fun.score">
                        Du hast das Lager erfolgreich abgeschlossen. Wie erfolgreich waren die
                        anderen Kursteilnehmenden?
                      </Trans>
                      <br />
                    </Row>
                    <ScoreSummary>
                      <h4>Eure Gesamtauswertung</h4>
                    </ScoreSummary>
                    <Row marginTop="5px" justifyContent="space-between">
                      <TokenPresenter
                        noWrap
                        isSummary
                        score={ScoreCalculation.calculateAmountofResponsibilityTokens(
                          ScoreCalculation.calculateMaxResponsibilityScore(allEvents),
                          gameState.responsibilityScore
                        )}
                        label={''}
                      ></TokenPresenter>
                      <FunScoreIndicator
                        noWrap
                        isSummary
                        score={ScoreCalculation.calculateFunScore(
                          allEvents.length,
                          gameState.funScore
                        )}
                        label={''}
                      ></FunScoreIndicator>
                    </Row>
                    <Row marginTop="1.5rem">
                      <FunScoreOverviewPresenter gameState={gameState} />
                    </Row>
                  </GameOverSuccessCard>
                </>
              ) : (
                <>
                  <GameOverCard>
                    <h1>
                      <Trans id="summary.game.over">Game Over</Trans>
                    </h1>
                    {gameState.minorAccidents >= 3 ? (
                      <p>
                        <Trans id="event.risk.to.many.minor.accidents">
                          Leider muss das Lager aufgrund zu vieler Unfälle abgebrochen werden.
                        </Trans>
                      </p>
                    ) : (
                      <p>
                        <Trans id="event.risk.game.over">
                          Leider muss das Lager aufgrund des schlimmen Unfalls abgebrochen werden.
                        </Trans>
                      </p>
                    )}
                  </GameOverCard>
                </>
              )}
            </div>
            <OverviewHeader>
              <h1>
                <Trans id="review">Rückblick auf euer Lager</Trans>
              </h1>
            </OverviewHeader>
            <EventSummaryPresenter
              additionalInfo={result?.additionalInfo}
              gameState={gameState}
            />
            <Row marginTop="3rem" justifyContent="center">
              <h2 className="text-center">
                <Trans id="summary.reflection.question.1">
                  Welches sind die zwei für euch wesentlichsten Erkenntnisse aus dem Spiel, die ihr
                  für eure zukünftige Leitertätigkeit berücksichtigt?
                </Trans>
              </h2>
            </Row>
            <Row marginTop="1.5rem" justifyContent="center">
              <h2 className="text-center">
                <Trans id="summary.reflection.question.2">
                  Wie setzt ihr diese konkret während eurer nächsten J+S-Aktivität oder im nächsten
                  Schneesportlager um?
                </Trans>
              </h2>
            </Row>
            <Row marginTop="1.5rem" justifyContent="center">
              <h2 className="text-center">
                <Trans id="summary.sharing.tip">
                  Tipp: Kopiere einfach die aktuelle URL, um sie mit deinen Freund*innen zu teilen!
                </Trans>
              </h2>
            </Row>
            <Row marginTop="3rem" justifyContent="center">
              <Button size="small" onClick={() => navigate(`/`)}>
                <Trans id="summary.done">Wagt ihr noch einen Versuch?</Trans>
              </Button>
            </Row>
            <Footer />
          </SummaryPresenterWrapper>
        </CurrentScenarioContext.Provider>
      </>
    )
  }
}
