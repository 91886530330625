import React from 'react'

export const GoDontGo = () => {
    return (
<svg width="160" height="72" viewBox="0 0 160 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle r="30.9532" transform="matrix(1 0 0.0296933 0.999559 35.1196 36.4195)" fill="#E20033"/>
<circle r="32.9532" transform="matrix(1 0 0.0296933 0.999559 35.1196 36.4195)" stroke="#BF122B" stroke-opacity="0.2" stroke-width="4"/>
<path d="M46.0984 28.9297L42.2643 24.98L24.7899 42.9812L28.624 46.9309L46.0984 28.9297Z" fill="white"/>
<path d="M28.1146 25.1104L24.2805 29.0601L41.7549 47.0613L45.589 43.1116L28.1146 25.1104Z" fill="white"/>
<ellipse rx="30.3901" ry="31.2806" transform="matrix(1 0 0.00693779 0.999976 125.434 36.2574)" fill="#00A19A"/>
<path d="M125.665 69.5372C143.608 69.5372 157.951 54.5825 157.824 36.2574C157.697 17.9323 143.146 2.97759 125.203 2.97759C107.261 2.97759 92.9169 17.9323 93.044 36.2574C93.1712 54.5825 107.722 69.5372 125.665 69.5372Z" stroke="#006D65" stroke-opacity="0.2" stroke-width="4"/>
<path d="M136.844 28.2043L132.56 25.0756L121.607 40.6373L116.251 36.6298L113.19 40.9738L122.947 48.2828L136.844 28.2043Z" fill="white"/>
</svg>)
}